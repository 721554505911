.ept-title{
    color: var(--terra-cotta);
    font-size: x-large;
    overflow-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ept-description{
    height: 7rem;
}

.ept-description-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1rem;
    line-height: 1rem;
    height: 2.03rem;
}

.ept-tooltip.p-tooltip .p-tooltip-text {
    width: 30rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 0.2s ease-in-out;
}

.ept-button{
    flex-direction: column;
    border: black 1px solid;
    border-radius: 10px;
    width: -webkit-fill-available;
}

.ept-button>div:first-child{
    color : var(--resolution-blue);
    font-size : 0.9rem;
}

.ept-compteur{
    color: var(--terra-cotta);
    font-size: 2rem;
}

@media screen and (max-width:992px) {
    .ept-tooltip.p-tooltip{
        left: 10rem;
    }
    .ept-tooltip.p-tooltip .p-tooltip-text {
        width: 15rem;
    }
}