:root {
    --indigo-dye: #04486c;
    --white: #fff;
    --whisper: #FAF8FB;
    --alabaster: #e6ebe0;
    --terra-cotta: #ed6a5a;
    --iris-100: #e34c37;
    --iris-60: #FCECCD;
    --indigo-dye-accessible: #033651;
    --midnight-express: #010035;
    --opal: #9bc1bc;
    --opal-light: #c0d8d5;
    --gray: #818181;
    --eerie-black: #1c1c1c;
    --alto: #E0E0E0;
    --carousel-pink: #F8E0F7;
    --grey-cards: #f4f4f4;
    --double-pearl-lusta: #FCEDCD;
    --jaffa: #F09451;
    --maize: #F6CCA5;
    --burnt-sienna:##EF805D;
    --linkedin-blue: #1c78bd;
    --opal-50: #cde0dd;
    --last-default: #e2e2e2;
    --indigo_bis:#87abb5;
    --periwinkle-blue:#CCD7F5;
    --indigo-blue:#517793;
    --link-water:#F5F8FD;
    --pastel-blue:#EDF1FD;
    --resolution-blue:#00237C;
    --perano: #A5BCF6;
    --royal-blue: #3A37E3;
    --kashmir-blue:#5064a4;
    --green-success:#689F38;
    --swiss-cheese:#fff4b9;
    --rum-swizzle:#F7F8E0;
    --selective-yellow:#ffc735;
    --grey-disabled:lightgray;
    --p-warning:#FBC02D;
    --p-warning-hover:#FAB710;
    --p-success:#689F38;
    --p-success-hover:#5E8F32;
    --p-info:#0288D1;
    --p-info-hover:#027ABC;
    --p-help:#523557;
    --p-help-hover:#8C239E;
    --p-danger:#D32F2F;
    --p-danger-hover:#C02929;
  }