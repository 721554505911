.float-form-button{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0rem;
    background-color: white;
    padding: 0.7rem;
}

.form-entreprise-theme div>label,
.form-entreprise-theme h1,
.form-entreprise-theme h2,
.form-entreprise-theme h3{
    font-weight: 700;
}

.form-entreprise-theme h1{
    font-size: 24px;
}

.form-entreprise-theme h1,
.form-entreprise-theme h2
{
    color: var(--resolution-blue);
}

.form-entreprise-theme div>label:nth-child(1){
    color:var(--resolution-blue);
}

.form-entreprise-theme div>label:nth-child(2){
    color:var(--terra-cotta);
}

.form-entreprise-theme input,
.form-entreprise-theme .p-inputtextarea,
.form-entreprise-theme .p-dropdown,
.form-entreprise-theme .p-autocomplete,
.form-entreprise-theme .p-multiselect{
    border-radius:5px;
    background-color: white;
}

.form-entreprise-theme input::placeholder,
.form-entreprise-theme textarea::placeholder{
    font-style: italic;
}

.form-entreprise-theme.with-shadows input,
.form-entreprise-theme.with-shadows .p-inputtextarea,
.form-entreprise-theme.with-shadows .p-dropdown{
    border: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}


.form-entreprise-theme.with-blue-borders input,
.form-entreprise-theme.with-blue-borders .no-borders{
    border: 1px solid var(--pastel-blue);
}

.form-entreprise-theme.with-blue-borders .p-inputtextarea:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-dropdown:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-autocomplete:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-multiselect:not(.no-borders):not(.p-invalid){
    border: 1px solid var(--resolution-blue);
}

@media screen and (max-width:992px){
    .float-form-button{
        background-color: white;
    }
}