.formation-list-item-main-container{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.formation-list-item-main-container-vertical{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.formation-list-item-main-container>div{
    padding: 0.5rem;
}

.formation-list-item-main-container>div:first-child{
    display: flex;
    width: 10rem;
    height: auto;
    align-items: center;
    justify-content: center;
}

.formation-list-item-main-container-vertical>div:first-child{
    display: flex;
    align-self: center;
    width: auto;
    height: 5.6rem;
    align-items: center;
    justify-content: center;
}

.formation-list-item-info{
    width: -webkit-fill-available;
}

.formation-list-item-main-container>div:first-child img{
    display: flex;
    max-width: 7rem;
    height: auto;
    max-height: 4.6rem;
}

.formation-list-item-main-container-vertical>div:first-child img{
    display: flex;
    max-width: 7rem;
    height: auto;
    max-height: 4.6rem;
    width: auto;
}

.formation-list-item-composante{
    font-weight: 500;
}


.formation-list-item-obtention{
    font-style: italic;
}

.formation-list-item-a-venir{
    color:var(--terra-cotta);
}

@media screen and (max-width:992px) {
    .formation-list-item-main-container{
        flex-direction: column;
    }
    .formation-list-item-main-container>div:first-child{
        align-self: center;
        width: auto;
        height: 5.6rem;
    }
    .formation-list-item-main-container>div:first-child img{
        max-height: 4.6rem;
        width: auto;
    }
}