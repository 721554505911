
.chat-loading{
    text-align:center;
    margin-top: 10rem;
}

.chat-hidden{
    display:none;
}

.chat-box-msg{
    flex : auto;
}