.container-stepper {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
}

.p-dialog-header-icons {
  z-index: 999;
}

.stepper-active-1 li:nth-child(1)::before,
.stepper-active-2 li:nth-child(1)::before,
.stepper-active-2 li:nth-child(2)::before,
.stepper-active-2 li:nth-child(2)::after,
.stepper-active-3 li:nth-child(1)::before,
.stepper-active-3 li:nth-child(2)::before,
.stepper-active-3 li:nth-child(3)::before,
.stepper-active-3 li:nth-child(2)::after,
.stepper-active-3 li:nth-child(3)::after {
  border-color: var(--iris-100);
  background: var(--iris-100);
  color: white
}

.progressbar li {
  width: 30%;
  position: relative;
  text-align: center;
  list-style: none;
}

.progressbar {
  counter-reset: step;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li.active+li:before {
  border-color: var(--iris-100);
  background: var(--iris-100);
  color: white
}

.progressbar li.hide+li:before {
  border-color: #979797;
  background: #979797;
  color: white
}

@media screen and (max-width:992px) {
  .progressbar li {
    font-size: 0.8rem;
  }
}