.required-item {
   color: var(--primary-color)
}

label.label-for-OE {
   font-weight: 500;
   color: var(--indigo-dye)
}

span.span-for-OE {
   color: var(--indigo-dye);
}

.OE-main-container{
   justify-content: center;
}

.OE-card-margin {
   margin: 2%;
}

.OE-plain-button{
   background-color: var(--alabaster);
   color:var(--terra-cotta)
}

.voe-card.p-card .p-card-body {
      padding: 0;
}

.voe-card.p-card .p-card-content {
   padding: 0.5rem 2rem;
}

.voe-blur{
   filter : blur(0.5rem);
   pointer-events: none;
}

.voe-compatible-course-container{
   position: absolute;
   width: 98vw;
   left: 0rem;
}

.voe-compatible-course{
   margin-top: 7rem;
}


.p-chip.required-skill {
   background-color: var(--primary-color);
}

.accordion-for-offer .p-accordion-header.p-highlight {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: #ffffff;

}

.accordion-for-offer .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: #ffffff;
   border-color: transparent;
}

.accordion-for-offer .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid var(--linkedin-blue);
   color: var(--linkedin-blue);
}


.accordion-for-offer .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid var(--linkedin-blue);
   color: var(--linkedin-blue);
}

.accordion-for-offer .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
}


.accordion-for-offer .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.accordion-for-offer .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: var(--opal-light);
}

.OE-custombar {
   width: '100%';
   height: '12em';
}

.p-scrollpanel.OE-custombar {
   width: '100%';
   max-height: '10em';
}

.p-scrollpanel.OE-custombar .p-scrollpanel-bar {
   background-color: var(--opal-light);
   opacity: 1;
   transition: background-color .2s;
}

.published-date-style-offer {
   text-align: right;
}

.published-date-style-offer span {
   font-style: italic;
   margin-right: 1rem;
}

.missing-value {
   font-style: italic;
}

@media screen and (max-width:992px) {
   .voe-card.p-card .p-card-content {
      padding: 0.5rem 1rem;
   }
}