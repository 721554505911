
.datatable-recrutements .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.frs-button.p-button{
    width: auto;
    color: white;
    background-color: var(--iris-100);
}