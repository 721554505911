.ep-confirm-dialog{
    width: 80vw;
}

.ep-confirm-dialog .p-dialog-title{
    color: var(--terra-cotta);
    text-align: center;
}

.ep-confirm-dialog.p-dialog{
    width: 50%;
}