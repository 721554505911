.center {
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    background: var(--primary-color);
}

.fl-form .p-inputtext:enabled:focus {
    box-shadow: none;
}

.p-button-rounded.center {
    color: var(--white);
    border-radius: 10px;
}

.label-margin {
    margin-left: 1rem;
    margin-right: 1rem;
}

.btn-linkedin {
    width: auto;
    background-color: #1c78bd;
}

.logo-linkedin {
    height: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.login-button-icon svg {
    margin-right: 0.5rem;
}