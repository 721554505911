.profile-menu-style.p-menu {
    background: black;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
}

.profile-menu-style.p-menu ul li a span.p-menuitem-text {
    color: white;
}

.profile-header-style {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-header-style .name {
    display: block;
    padding-right: 0.5rem;
}

.profile-menu-style.p-menu ul li a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 84%;
    left: 8%;
    bottom: 0;
}

.profile-header-style img {
    margin: 0.2rem 0;
    height: 2.6rem;
    cursor: pointer;
}

.header-img-profil-etudiant-iris {
    background-color: var(--iris-100);
}

.header-img-profil-etudiant-blue {
    background-color: var(--kashmir-blue);
}

.header-img-profil-etudiant-red {
    background-color: var(--terra-cotta);
}

.header-img-profil-etudiant-iris img,
.header-img-profil-etudiant-blue img,
.header-img-profil-etudiant-red img {
    object-fit: cover;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}

@media screen and (max-width:992px) {
    .profile-header-style .name {
        display: none;
        padding-right: 0;
    }
}