.add-formation-btn{
    display: flex;
    align-items: center;
}

.studies-main-title{
    margin:0;
    margin-bottom:0.5rem;
}

.studies-input-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    max-width: 22rem;
}

.studies-input-container .p-inputtext{
    padding: 1.35rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.studies-formation-desc{
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.125rem;
}

.studies-formation-attention{
    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 1.06rem;
    line-height: 1.25rem;
    color: var(--iris-100)
}