.tab-main-title{
   display: flex;
   justify-content: center;
   font-size: 1.4rem;
   padding-top: 0.3rem;
   padding-bottom: 0.3rem;
   font-weight: 400;
}

.msf-tab-title{
    font-weight: 500;
    display: flex;
}

.msf-accordion .p-accordion-content{
   padding: 0rem;
}

.msf-accordion .p-accordion-content .p-accordion-content{
   padding: 1rem;
}

.msf-otheroptions{
   padding: 1rem 0.5rem;
}

.msf-otheroptions-line{
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   border-bottom: 1px solid #ADADAD;
   background-color: var(--white);
}

.msf-otheroptions-borderless-line{
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   background-color: var(--white);
}

.msf-input-switch.p-inputswitch{
   height: 1rem;
   width: 2.3rem;
}

.msf-input-switch.p-inputswitch .p-inputswitch-slider::before{
   width: 0.9rem;
   height: 0.9rem;
   margin-top: -0.45rem;
   left : 0.1rem
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
   background: var(--terra-cotta);
}

.msf-accordion .p-accordion-header.p-highlight{
   border-radius: 0.5rem;
    background-color: var(--white);
    
 }
 .msf-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border-top-right-radius : 0.5rem;
    border-top-left-radius : 0.5rem;
    background-color: var(--white);
    border-color: transparent;
 }
 .msf-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    /* border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
    border-color: #dee2e6;
    border-bottom:  0.1em solid transparent;
    background-color: var(--white);
 }
 
 .msf-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    /* border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
    /* border-color: #dee2e6; */
    border-bottom:  0.1em solid transparent;
    background-color: var(--white);
 }
 .msf-accordion .p-accordion-tab .p-accordion-content{
    border-bottom-right-radius : 0.5rem;
    border-bottom-left-radius : 0.5rem;
    background-color: var(--white);
 }
 
 .msf-accordion .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border: 1px solid transparent;
    border-top-right-radius : 0.5rem;
    border-top-left-radius : 0.5rem;
    background-color: var(--white);
 }
 .msf-accordion .p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    background-color: var(--white);
 }

 .msf-sub-accordion.p-accordion.p-accordion-tab-active .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
   border-bottom: transparent;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
   border: 1px solid transparent;
   border-bottom: 1px solid #ADADAD;
   border-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content {
   border: 1px solid transparent;
   border-bottom-left-radius: 0rem;
   border-bottom-right-radius:  0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
   padding: 1rem 0.5rem;
}

.msf-sub-accordion .p-accordion-header-text{
   width: -webkit-fill-available;
}

.msf-sub-accordion .p-tag{
   background: var(--indigo-dye);
   color: white;
   border-radius: 1rem;
   font-weight: 500;
}

.msf-selectbutton.p-selectbutton .p-button.p-highlight{
   background: var(--iris-100);
}

.msf-selectbutton.p-buttonset .p-button:first-of-type{
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type){
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-selectbutton.p-buttonset .p-button{
   padding: 0.2rem 0.8rem;
}

.msf-selectbutton .p-button.p-highlight:hover{
   color: white;
   border-color: #ced4da;
}

.msf-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
   background: #e9ecef;
}

.msf-selectbutton.p-buttonset .p-button:last-of-type{
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-input input{
   border-radius: 2rem;
   border: var(--indigo-dye) 2px solid;
   padding: 0.2rem 1rem
}

.msf-tab-header{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.msf-tab-subheader{
   display: flex;
}

.msf-tooltip{
   display: flex;
   align-self: center;
}

@media screen and (max-width:992px) {

    .msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
       background: white;
    }
}