.dip-wysiwyg {
    word-break: break-word;
}

.dip-wysiwyg img {
    max-width: 100%;
    height: auto;
}

.dip-wysiwyg iframe {
    max-width: calc(100vw - 15rem);
    height:auto;
    aspect-ratio: 16/9;
}

@media screen and (min-width:1500px) {
    .dip-wysiwyg iframe {
        max-width: 80rem;
    }
}

@media screen and (max-width:992px) {
    .dip-wysiwyg iframe {
        max-width: calc(100vw - 5rem);
    }
}

