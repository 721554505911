.avatar-renderer-container-2 img {
    object-fit: cover;
    border: 1px solid var(--opal);
}

.avatar-renderer-container img {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--eerie-black);
    height: 3rem;
    width: 3rem;

}

.avatar-renderer-container .avatar-renderer-initials {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--eerie-black);
    height: 3rem;
    width: 3rem;
}
.avatar-renderer-initials{
    font-size: 1.5rem;
    text-align: center;
}
.avatar-renderer-initials>div{
    padding-top: 0.5rem;
}

.avatar-renderer-green{
    background: rgba(34, 197, 94, 0.04);
    color: #22C55E;
}

.avatar-renderer-blue{
    background: rgba(59, 130, 246, 0.04);
    color: #3B82F6;
}

.avatar-renderer-yellow{
    background: rgba(245, 158, 11, 0.04);
    color: #F59E0B;
}

.avatar-renderer-purple{
    background: rgba(168, 85, 247, 0.04);
    color: #A855F7;
}

.avatar-renderer-red{
    background: rgba(239, 68, 68, 0.04);
    color: #EF4444;
}