.disabled-label{
    color:var(--grey-disabled)
}

.year-dropdown-style{
    max-width:16rem
}

.p-formgrid.p-grid .p-col-12.delete-button-container {
    text-align: right;
    padding-top: 1.7rem;
}

.formation-obtention-container{
    display: flex;
    flex-direction: row;
}

.formation-obtention-align{
    padding-top: 1.7rem;
}

.statut-panel-style{
    min-height: 12rem;
}

@media screen and (max-width:992px){
    .p-formgrid.p-grid .p-col-12.delete-button-container{
        padding-top: 1rem;
        text-align: center;
    }
    .formation-obtention-container{
        flex-direction: column;
    }
    .year-dropdown-style{
        max-width:100%
    }

    .formation-obtention-align{
        padding-top: 0rem;
    }
}