.accueil-button-ecole{
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}

.contactez-nous-button-ecole {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
    margin-top: 3rem;
}

.accueil-button-ecole .p-button{
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
    margin-top: 2rem;
}

.contactez-nous-button-ecole .p-button {
    background-color: var(--terra-cotta);
    color: white;
}


.savoirplus-ecole-container{
    margin: 0 10rem;
}

.savoirplus-ecole-head{
    text-align: center;
}

.savoirplus-ecole-head{
    margin: 0;
}

h2.text-huge-ecole{
    font-weight: 400;
}

.savoirplus-ecole-container .p-card span {
    text-align: center;
    vertical-align:text-bottom;
 }

.imagecard-ecole {
   text-align: center;

}

.imagecard-ecole img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

.text-huge-ecole {
color: var(--indigo-dye);
font-size: 2rem;
line-height: 3rem;
}

.text-description-left {
    color: var(--indigo-dye);
    text-align: left;
    font-size: 22px;
}

.text-description-right {
    color: var(--terra-cotta);
    text-align: left;
    font-size: 22px;
}

.text-descriptions {
    width: 100%;
}

@media screen and (max-width:992px) {
    .savoirplus-ecole-container{
        margin: 0;
    }

    .text-huge-ecole {
        line-height: 2.50rem;
        font-size: 1.25rem;
        text-align: center;
        }
     
    .savoirplus-ecole-container{
            margin: 0;            
        }
        
    .savoirplus-ecole-head{
        font-size:0.75rem;
    }   

    .text-description-left {
        font-size: 18px;
        text-align: center;
    }

    .text-description-right {
        font-size: 18px;
        text-align: center;
    }

    .imagecard-ecole {
        width: 100%;
    }

    .imagecard-ecole img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }
}