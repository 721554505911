.chatbox-main .p-dataview-header{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.chatbox-header{
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}


.chatbox-header-info-container{
 display: flex;
 flex-direction: row;
}

.chatbox-header-info{
    padding-left: 1rem;
   }


.chatbox-nom{
    color: var(--indigo-dye);
}

.thread-list-back{
    cursor: pointer;
    display: none;
}

.chatbox-nom-comp{
    
    text-transform: uppercase;
    font-weight: normal;
}

@media screen and (max-width:992px) {
    .thread-list-back{
        display:block;
    }
}


.chatbox-emplois{
font-size: small;
}

.chatbox-conv{
    height: 600px;
    min-height: 600px;

}

.chatbox-conv > div > div 
{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

/* .chatbox-composite{

} */