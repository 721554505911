.domaine-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.domaine-selector-container button {
    width: 20%;
    margin: 0.5rem;
    padding: 0 0.5rem;
    height: 10rem;
    background-color: var(--whisper);
    justify-content: center;
    border-radius: 1rem;
}

.domaine-selector-button>div{
    display: flex;
    flex-direction: column;
}

.domaine-selector-button div:first-child {
    height: 5rem;
}

.domaine-selector-button img{
    max-height: 5rem;
}

@media screen and (max-width:992px) {
    .domaine-selector-container button {
        width: 44%;
    }
}