.lgm-container>div {
    width: 25%;
    padding: 0.5rem;
}

.lgm-container {
    margin-top: 4rem;
}

.lgm-container img {
    max-width: -webkit-fill-available;
}

.lgm-join-container {
    display: flex;
    height: 100%;
    position: relative;
    place-content: center;
}

.lgm-button-container {
    position: absolute;
    place-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 3rem;
}

.lgm-button {
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
}

.lgm-text-container {
    position: relative;
    margin: 0 auto;
    padding: 1rem 1.2rem 0 1.2rem;
    border-radius: 3rem;
    min-height: 7rem;
    min-width: fit-content;
    max-width: 17rem;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.05);
}

.lgm-text-container span {
    color: grey;
    font-weight: 500;
}

.lgm-text-button-container {
    position: relative;
    margin: 0 auto;
    padding: 0.2rem 1rem 0 1rem;
    border-radius: 3rem;
    min-height: 7rem;
    min-width: fit-content;
    max-width: 17rem;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.05);
}

.lgm-text-container h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.lgm-text-button-container h3 {
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.lgm-img-container {
    display: flex;
    margin-left: 4.5rem;
    height: 6.875rem;
    margin-bottom: -0.5rem;
}

.lgm-img-container img {
    max-height: 6.875rem;
}

.lgm-img-container .picto-1{
    padding-left: 3rem;
    transform: rotate(21.38deg);
    z-index: 95;
}

.lgm-img-container .picto-2{
    padding-left: 2rem;
    transform: rotate(-8.51deg);
    z-index: 95;
    height: 75%;
}

.lgm-img-container .picto-3{
    padding-left: 1.5rem;
    z-index: 95;
    height: 83%;
}

.lgm-img-last-container {
    place-content: center;
    margin-left: 0rem;
}


.lgm-img-container :nth-child(2) {
    margin-left: -2rem;
    margin-bottom: 0.5rem;
}

@media screen and (max-width:992px) {
    .lgm-container>div {
        width: 100%;
    }

    .lgm-img-container {
        margin-left: 0rem;
        place-content: center;
    }
    
}