.btn-domaines .p-button {
    border-radius: 1em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    flex: 24% 0;
}

.container-img-domaines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5rem;
    height: auto;
}

.btn-domaines {
    display: flex;
    flex-wrap: wrap;
}

.dom-card-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dom-card-content {
    flex-grow: 1;
    text-align: center;
}

.dom-card-image {
    width: 5rem;
}

.dom-card-image img {
    width: 4rem;
    height: auto;
}


.dom-card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:1160px) {
    .btn-domaines {
        flex-wrap: nowrap;
        flex-direction: column;
        display: inline-flex;
        width: 100%;
    }

    .dom-card-image {
        width: 5rem;
    }

    .dom-card-image img {
        width: 3rem;
    }

    .dom-card-container {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 0.5rem;
    }

    .dom-card-content p {
        font-size: 1.12rem;
    }

    .dom-card-content {
        flex-grow: 0;
        text-align: left;
    }
}