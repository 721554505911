.pop-container{
    color:var(--resolution-blue);
    width: 20rem;
    background: var(--periwinkle-blue);
}

.pop-container::before{
    border-bottom-color: var(--periwinkle-blue);
}

.pop-multiselect{
    width:-webkit-fill-available
}