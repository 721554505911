.hab-pc-container,
.hab-pc-container-blue{
    display: flex;
}

.hab-pc-container a,
.hab-pc-container-blue a{
    color: white;
    cursor: pointer;
}

.hab-pc-container-white a{
    color: var(--resolution-blue);
    cursor: pointer;
}

.hab-pc-container-formations-search a{
    color: var(--white);
    cursor: pointer;
}

.hab-pc-container-formations-search{
    border: 2px solid var(--terra-cotta);
    background: var(--terra-cotta);
    border-radius: 0.3rem;
    padding: 0rem 1rem;
}

.hab-pc-container-entreprise{
    justify-content: space-between;
    width: 100%;
}

.hab-high{
    font-weight: 900;
}

.hab-highlight{
    text-decoration: underline;
    font-weight: 900;
}

.hab-mobile-container{
    display: none;
}

.hab-right{
    margin-left: auto;
}

@media screen and (max-width:1226px) {
    .hab-button-see{
        display: none;
    }
}

@media screen and (max-width:992px) {
    .hab-pc-container{
        display: none;
    }

    .hab-pc-container-blue{
        display: none;
    }

    .hab-pc-container-white{
        display: none;
    }
    
    .hab-mobile-container{
        display: block;
    }

    .hab-mobile-right{
        margin-left: auto;
    }
}