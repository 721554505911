.fcp-tab .p-tabview-title{
    font-size: larger;
}

.fcp-tab .p-tabview-nav li .p-tabview-nav-link{
    color: var(--resolution-blue);
}

.fcp-popup-first-connection.p-dialog .p-dialog-header .p-dialog-title{
    color: var(--resolution-blue);
    font-size: 1.6rem;
    font-weight: 500;
}

.fcp-popup-first-connection.p-dialog .p-dialog-content{
    color: var(--midnight-express);
    font-size: 1.3rem;
    font-weight: 500;
}

.fcp-button-popup-first-connection.p-button{
    width: auto;
    color: white;
    background-color: var(--terra-cotta);
}

.fcp-tab.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-weight: 600;
}

.fcp-tab.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-weight: 500;
}