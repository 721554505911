.fai-selectbutton .p-button {
    border-radius: 5px;
    border: 1px solid var(--terra-cotta);
    color: var(--terra-cotta);
}

.p-fluid .fai-selectbutton .p-button {
    width: auto;
    flex: none;
    padding: 0.5rem 1rem;
}

.fai-selectbutton .p-button.p-highlight:hover {
    background-color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    color: white;
}

.fai-align-self-end {
    align-self: flex-end;
}

.p-field .fai-label-with-tooltip {
    align-items: center;
    display: flex;
}

.fai-loading-container {
    text-align: center;
    height: 100%;
}

.fai-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.fai-infotext {
    margin-left: 1rem;
    color: grey;
}

.fai-title-and-select-container{
    align-items: center;
}

.fai-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}

.fai-competence-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fai-item>span{
    padding-left: 0.5rem;
}

.fai-langue-btn.p-button{
    width: auto;
}

@media screen and (max-width:992px) {
    .fai-infotext {
        margin-left: 0rem;
    }

    .fai-title-and-select-container{
        align-items: normal;
    }
}