.cssb-select-button{
    display: flex;
    justify-content: space-between;
    background-color: var(--pastel-blue);
    border-radius: 5px;
}

.cssb-select-button .p-button-label.p-c>div:first-child{
    font-size: 1.6rem;
    color: var(--terra-cotta);
    font-weight: 700;
}

.cssb-select-button .p-button-label.p-c>div {
    color: var(--resolution-blue);
    font-weight: 500;
    font-size: 0.8rem;
}

.cssb-select-button .p-button{
    background-color: var(--pastel-blue);
    border: none;
    padding: 0.5rem 1rem;
    width:-webkit-fill-available;
}

.cssb-select-button .p-button:focus{
    box-shadow: 0 var(--terra-cotta);
}

.cssb-select-button.p-buttonset .p-button.p-highlight{
    background-color: var(--periwinkle-blue);
    border: 1px solid var(--terra-cotta);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
}

@media screen and (max-width:992px) {
    .cssb-select-button{
        flex-wrap: wrap;
    }

    .cssb-select-button .p-button{
        width:50% ;
    }

    .cssb-select-button .p-button:nth-child(1){
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0;
    }

    .cssb-select-button .p-button:not(:first-of-type):not(:last-of-type):nth-child(2){
        border-top-right-radius: 5px;
    }

    .cssb-select-button .p-button:not(:first-of-type):not(:last-of-type):nth-child(5){
        border-bottom-left-radius: 5px;
    }

    .cssb-select-button .p-button:nth-child(6){
        border-top-right-radius: 0;
        border-bottom-right-radius: 5px;
    }
}