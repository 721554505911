
.datatable-etudiants .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.getu-status-container .p-tag{
    color : white
}

.getu-status-container .status-CreatingAccount{
    background-color : var(--linkedin-blue)
}

.getu-status-container .status-Active{
    background-color : var(--opal)
}
.getu-status-container .status-Disabled{
    background-color : var(--terra-cotta)
}

.getu-status-container .status-Archived{
    background-color : var(--selective-yellow)
}

.getu-status-container .status-Invited{
    background-color : var(--green-success)
}