.vue-candidat-no-result-container{
    color: grey;
    padding: 5rem 0;
    font-size: 2rem;
    text-align: center;
    font-style: italic;
}

.vue-candidat-main-container{
    justify-content: center;
}

.vue-candidat-main-container .p-card-content{
    padding:0 1rem;
}

.vue-candidat-card-title{
    margin: 0;
    padding: 1rem 2rem;
    color : var(--linkedin-blue);
    font-size:1.5rem;
}

.vue-candidat-share-bnt-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

@media screen and (max-width:992px) {
    .vue-candidat-card-title{
        padding: 1rem;
    }
    .vue-candidat-main-container .p-card-content{
        padding:0;
    }

    .vue-candidat-main-container{
        padding: 0.5rem;
    }
}