.fl-form.fl-entreprise >div>label:nth-child(1){
    color:var(--resolution-blue);
}

.fl-form >div>label:nth-child(2){
    color:var(--terra-cotta);
}

.fl-form>div>label{
    font-weight: 700;
}