.dip-btn-p-lateral {
    padding: 0.5rem 2rem !important;
}

.dip-btn-red-outline {
    color: black;
    border: 1px solid var(--terra-cotta);
    background-color: white;
    padding: 0.5rem;
}

.dip-btn-red-outline:not(a):not(.p-disabled):hover {
    color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-red-outline:not(a):not(.p-disabled):active {
    color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-red-outline:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-iris-outline {
    color: var(--iris-100);
    border: 1px solid var(--iris-100);
    background-color: white;
    padding: 0.5rem;
}

.dip-btn-iris-outline:not(a):not(.p-disabled):hover {
    color: var(--iris-100);
    border-color: var(--iris-100);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-iris-outline:not(a):not(.p-disabled):active {
    color: var(--iris-100);
    border-color: var(--iris-100);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-iris-outline:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-red-bg {
    color: white;
    border: 1px solid var(--terra-cotta);
    background-color: var(--terra-cotta);
    padding: 0.5rem 2rem;
}

.dip-btn-kashmir-blue-bg {
    color: var(--terra-cotta);
    border: 1px solid var(--kashmir-blue);
    background-color: var(--kashmir-blue);
    padding: 0.5rem 2rem;
}

.dip-btn-iris-bg {
    color: white;
    border: 1px solid var(--iris-100);
    background-color: var(--iris-100);
    padding: 0.5rem 2rem;
}

.dip-btn-white-bg {
    color: var(--iris-100);
    border: 1px solid white;
    background-color: white;
    padding: 0.5rem 2rem;
}

.dip-btn-id-bg {
    color: white;
    border: 1px solid var(--indigo-dye);
    background-color: var(--indigo-dye);
    padding: 0.5rem 2rem;
}

.dip-btn-bk-bg {
    color: white;
    border: 1px solid black;
    background-color: black;
    padding: 0.5rem 2rem;
}

.dip-btn-ib-bg {
    color: white;
    border: 1px solid var(--indigo-blue);
    background-color: var(--indigo-blue);
    padding: 0.5rem 2rem;
}

.dip-btn-ib-bg:not(a):not(.p-disabled):hover {
    background-color: var(--indigo-dye);
    color: white;
}

.dip-rounded-red-border-white-bg {
    color: black;
    border: 1px solid var(--terra-cotta);
    background-color: white;
    border-radius: 2rem;
    padding: 0.5rem;
}

.dip-rounded-red-border-white-bg:not(a):not(.p-disabled):hover {
    color: white;
    background-color: var(--terra-cotta);
}

.dip-rounded-red-border-white-bg:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-draggable-tag {
    cursor: grab;
    color: var(--terra-cotta);
    border: 1px solid var(--terra-cotta);
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

/* ROUND BUTTON */

.dip-btn-round.p-button {
    width: 7rem;
    height: 7rem;
    margin: 1rem;
    padding: 2rem 1rem;
    border: 1px solid var(--terra-cotta) !important;
    border-radius: 10rem !important;
}

.dip-btn-round.p-button:not(a):not(.p-disabled):active {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-button:not(a):not(.p-disabled):hover {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-round.p-buttonset .p-button {
    width: 7rem;
    height: 7rem;
    margin: 1rem;
    padding: 2rem 1rem;
    border: 1px solid var(--terra-cotta) !important;
    border-radius: 10rem !important;
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):active {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):hover {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}


.dip-slct-btn-spaced.p-buttonset .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.dip-slct-btn-spaced.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border: 1px solid var(--terra-cotta);
    border-radius: 1rem;
}

.dip-slct-btn-spaced.p-buttonset .p-button:first-of-type {
    border-radius: 1rem;
    border: 1px solid var(--terra-cotta);
}

.dip-slct-btn-spaced.p-buttonset .p-button:last-of-type {
    border-radius: 1rem;
    border: 1px solid var(--terra-cotta);
}

.dip-slct-btn-spaced.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: var(--alabaster);
    border: 1px solid var(--terra-cotta);
}

@media screen and (max-width:992px) {
    .dip-btn-round.p-button {
        width: 5rem;
        height: 5rem;
    }
}