.ssb-main {
    background-color: var(--iris-100);
    width: 100%;
}

.ssb-submain {
    display: flex;
    height: 14.87rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 4rem;
}

.ssb-submain-title {
    font-size: 1.5em;
    margin-bottom: 1em;
    text-align: center;
    color: white;
    font-weight: 600;
    margin-top: 0;
}

.ssb-submain-subtitle {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.ssb-inputrounded.p-inputtext {
    border-radius: 2rem;
    width: 100%
}

.ssb-color-advanced {
    background-color: var(--indigo_bis);
    font-weight: 500;
}

.ssb-hr {
    height: 0.1rem;
    color: var(--indigo-dye);
    background-color: var(--indigo-dye);
    border: 0rem;
}

.ssb-fields-wraper {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.ssb-fields-wraper>div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p-input-icon-right.icon-search-position {
    display: flex;
}

.icon-search-position.p-input-icon-right>i:last-of-type {
    z-index: 99;
}

.ssb-fields-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.ssb-field {
    padding: 0.5rem 0;
    flex-basis: 50%;
}

.ssb-autocomplete.p-autocomplete {
    width: 100%;
}

.ssb-search-button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ssb-item {
    max-width: -webkit-fill-available;
    white-space: pre-wrap;
}

.sbb-advanced-search {
    text-decoration: none;
    color: white;
    font-weight: 600;
}

.ssb-search-wrapper .ssb-autocomplete input {
    text-overflow: ellipsis !important;
    padding-right: 1.5rem;
}

.ssb-button-results span{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    pointer-events: none; 
}

@media screen and (max-width:992px) {
    .ssb-advanced-search-container {
        text-align: center;
        margin-right: 0;
    }

    .ssb-fields-container {
        flex-direction: column;
        align-items: stretch;
    }

    .ssb-field {
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-basis: 100%;
    }

    .ssb-search-button {
        text-align: center;
        flex-direction: column-reverse;
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .ssb-search-button>span:first-child {
        margin: 0.5rem 0;
    }

    .ssb-search-button div {
        margin-top: 1rem !important;
    }

    .ssb-fields-wraper {
        width: 100%;
    }

    .ssb-fields-wraper>div:first-child {
        flex-direction: column;
    }

    .ssb-submain {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .container-him-intro h1 {
        font-size: 1.6rem;
        font-weight: 600;
    }
}