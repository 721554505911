.uploader-custom-template .p-fileupload-content{
    display: none;
}

.flavor-picture-style{
    height: 6rem;
}

.flavor-text-style{
    height: 5.6rem;
    width: 7rem;
    display: table-cell;
    border: 1px solid black;
    border-radius: 1rem;
    justify-content: center;
    text-align: center;
    padding: 0.2rem;
    vertical-align:middle;
    margin: 0.2rem 0rem;
}

.flavor-picture-style img{
    height: 5.5rem;
    width: auto;
}

.waiting-for-validation{
    padding:0.1rem;
    color:var(--terra-cotta);
}

.fu-button-cv{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
    border: 1px solid;
}
