.NotACookieOverlay{
    background-color: white;
    text-align: center;  
}

.NotACookieOverlay .ta-left{
    text-align: left;  
}

.cookiesconsent-dialog .p-dialog-header{
    padding: 0;
}

.NotACookieOverlay .p-accordion .p-accordion-header .p-accordion-header-link
{
    padding: 0;
    background-color: white !important;
    border: 0;
}


.NotACookieOverlay .p-accordion .p-accordion-content
{
    border: 0;
    padding: 0;
}

.cookieconsent-header{
    background-color: var(--primary-color);
    text-align: center;
    padding: 1rem;
}

.cookieconsent-header .dialog-title{
    color: white;
}

/* .CookieConsent #rcc-confirm-button{
    color: white;
    }; */

    /* .CookieConsent #rcc-confirm-button{

} */


.NotACookieOverlay .ccb-cancel.p-button{
    color: var(--terra-cotta);
    border:solid 1px var(--terra-cotta);
}


.NotACookieOverlay .ccb-ok.p-button{
    background-color: var(--primary-color);
    width: auto;
    color: white;
}
