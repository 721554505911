.sp-container-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.sp-container-text .sp-text-label {
    font-family: var(--font-family-mulish);
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
}

.sp-container-text .dip-tag {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.08rem;
}

.sp-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sp-selector-container button {
    display: flex;
    align-items: center;
    width: 30%;
    height: 11.68rem;
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sp-container-text div:nth-child(2) {
    text-align: right;
}

.sp-container-image {
    display: flex;
    width: 13rem;
    align-items: center;
}

.sp-selector-button>div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.sp-selector-button img {
    max-height: 7rem;
}

@media screen and (max-width:992px) {
    .sp-selector-container button {
        width: 44%;
    }

}

@media screen and (max-width:740px) {
    .sp-selector-container button {
        width: 100%;
    }
}