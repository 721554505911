.dialog-wrapper-content {
    margin-top: 5rem;
}

.p-dialog .p-dialog-header {
    padding: 1rem !important;
}

.container-dialog {
    width: 75%;
}

.dom-card-list .p-button {
    padding: 0 !important;
}

.dom-card-container p {
    font-weight: 900;
}

.btn-inspire-me.dip-only-pc button {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.btn-inspire-me.dip-only-pc button span {
    font-size: 2rem;
    font-weight: 700;
}

@media screen and (max-width:992px) {
    .dialog-wrapper-content h1 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .btn-inspire-me .p-button:not(a):not(.p-disabled):hover,
    .dialog-wrapper-content .p-button:not(a):not(.p-disabled):hover,
    .dialog-wrapper-content .p-selectbutton .p-button.p-highlight {
        background-color: var(--iris-100);
        color: white;
    }
}