/* theme.css */
.p-tabview.r-title-content .p-tabview-nav {
    border: none;
}

.p-tabview.r-title-content .p-tabview-nav li .p-tabview-nav-link {
    font-size: 20px;
    border-width: 0 0 5px 0;
    font-weight: 700;
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li .p-tabview-nav-link {
    color: var(--periwinkle-blue);
    border-color: var(--periwinkle-blue);
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--resolution-blue);
    border-color: var(--resolution-blue);
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: var(--periwinkle-blue);
    border-color: var(--periwinkle-blue);
}
/* /theme.css */

/* container */
.frs-style .p-fieldset {
    border-radius: 1rem;
}

.container-register h1 {
    font-family: var(--font-family-poppins);
    font-weight: 700;
    font-size: 3rem;
}

.container-register li,
.container-register p {
    font-family: var(--font-family-poppins);
    font-weight: 300;
}

.ss-container-register p {
    font-size: 2rem;
    font-weight: 500;
}

.ss-container-register {
    width: 100%;
}

.container-register img {
    width: 27rem;
    padding-top: 2rem;
}
/* /container */

/* connexion card */
.r-container-card {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    margin-top: -2%;
    padding: 0.5rem 1rem 0rem 1rem;
}

.r-container-card.hal-entreprise{
    background: var(--pastel-blue);
    color: var(--resolution-blue);
}

.r-container-card.hal-etudiant{
    background: var(--link-water);
    color: var(--resolution-blue);
}

.r-form-container .hal-etudiant .p-tabview.r-title-content .p-tabview-nav li .p-tabview-nav-link {
    font-size: 0.9rem ;
}

.r-form-container{
    min-width: 20.4rem;
}
/* /connexion card */

@media screen and (max-width:992px) {

    .container-register {
        display: flex;
        flex-direction: column;
    }
    
    .container-text-register{
        width: 100%;
    }
    
    .frs-style {
        justify-content: center;
    }

    .ss-container-register{
        width: 100%;
    }
    .ss-container-register p{
      font-size: 1.5rem;
    }
    .ss-container-register h1{
      font-size: 2.2rem;
    }

    .container-register div:nth-child(2){
        text-align: center;
        margin-bottom: 2rem;
    }

    .container-register img {
        padding: 0;
        width: 14rem;
    }
    
}