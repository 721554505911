.accueil-button-etudiant {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}


.accueil-button-etudiant .p-button {
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
}

.plain-button-trouver-formation {
    text-align: center;
    margin-top: 3rem;
}

.plain-button {
    text-align: center;

}

.plain-button .p-button {
    background-color: var(--terra-cotta);
    color: white;

}

.plain-button-trouver-formation .p-button {
    background-color: var(--terra-cotta);
    color: white;

}

.savoirplus-etudiant-container {
    margin: 0 10rem;
}

.savoirplus-etudiant-block{
    font-weight: 500;
}

.savoirplus-etudiant-container ul li::marker {
    /* content: "\2022";  Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--terra-cotta);
    /* Change the color */
    /* font-weight: bold; If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    /* margin-left: -1em; Also needed for space (tweak if needed) */
}

.savoirplus-etudiant-head {
    text-align: center;
}

.text-huge-etudiant {
    font-size: 2rem;
}

.text-big-etudiant {
    font-size: 1.70rem;
}

.text-big-comment-ca-marche {
    font-size: 1.75rem;
    font-weight: 500;
}

.savoirplus-etudiant-container .p-card span {
    text-align: center;
    vertical-align: text-bottom;
}

.imagecard-etudiant {
    text-align: center;
}

.imagecard-etudiant img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

.divider-mini.p-divider.p-divider-horizontal {
    border-top: 1px rgb(228, 228, 228);
}

.divider-mini.p-divider.p-divider-horizontal::before {
    border-top: 1px rgb(228, 228, 228);
    border-top-style: solid;
}

.title-en-savoir-plus-etudiant{
    font-size: 1.25rem;
}

.title-en-savoir-plus-etudiant h1{
    margin: 0rem;
}


.comment-ca-marche-savoir-plus-etudiant h3{
    margin: 0;
    font-size: 1.375rem;
}

.text-big-etudiant h3
{
    font-size: 1.7rem;
}

@media screen and (max-width:992px) {
    .savoirplus-etudiant-container {
        margin: 0;
    }

    .title-en-savoir-plus-etudiant {
        font-size: 0.75rem;
    }

    .text-huge-etudiant {
        margin-left: 0;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }

    .text-big-etudiant {
        text-align: center;
        font-size: 1.25rem;
    }

    .imagecard-etudiant {
        width: 100%;
    }

    .imagecard-etudiant img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }

    .comment-ca-marche-savoir-plus-etudiant li {
        font-size: 1.25rem;
    }

    .text-big-comment-ca-marche {
        font-size: 1.50rem;
        font-weight: 500;
        text-align: center;
    }
}