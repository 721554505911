@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.App_loader {
  text-align: center;
}

body {
  font-family: var(--font-family);
}

a {
  text-decoration: none;
}

iframe.kw-iframe-dropdown:not(.active) {
  width: 0 !important;
  height: 0 !important;
}

.main-red {
  min-height: 100vh;
  padding-top: 4rem;
}

.main-iris {
  min-height: 100vh;
  padding-top: 4rem;
  background-color: var(--whisper);
}

.main-blue {
  min-height: 100vh;
}

.main-white {
  min-height: 100vh;
  padding-top: 4rem;
}

.main-with-dashboard {
  margin-left: 15rem;
  background-color: var(--whisper);
  min-height: 100vh;
}

.alabaster-background {
  background-color: var(--alabaster);
  min-height: 100vh;
}

.whisper-background {
  background-color: var(--whisper);
  min-height: 100vh;
}

.complete-profile-header-text {
  color: white
}

.global-top-sidebar-info.p-sidebar {
  z-index: 2;
}

.global-top-sidebar-info.p-sidebar-top {
  color: white;
  top: 4rem;
  height: auto;
  text-align: center;
}

.global-top-sidebar-info.p-sidebar .p-sidebar-header {
  background-color: var(--linkedin-blue);
}

.global-top-sidebar-info.p-sidebar .p-sidebar-content {
  margin-top: -3rem;
  padding: 1rem 4rem;
  background-color: var(--linkedin-blue);
}

.global-top-sidebar-info.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  z-index: 1;
  color: var(--white);
}

main {
  padding: 2rem;
}

.app-width-limit {
  margin: 0 auto;
  max-width: 1500px;
}

h1,
p {
  margin-bottom: 1rem;
}

.global-top-sidebar-button.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  color: var(--terra-cotta);
  border-color: var(--terra-cotta);
  background-color: white;
}

.center-bloc {
  align-self: center;
  text-align: center;
}

@media screen and (max-width:992px) {
  .main-with-dashboard {
    margin-left: 0rem;
  }

  main {
    padding: 0.5rem;
    margin-bottom: 3.3rem;
  }
}
@charset "UTF-8";

:root {
    --surface-a: #ffffff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #495057;
    --text-color-secondary: #6c757d;
    --primary-color: #ed6a5a;
    --primary-color-text: #212529;
    --opal-light :#c0d8d5;
    --font-family: Work Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; 
    --font-family-poppins: 'Poppins', sans-serif; 
    --font-family-mulish: 'Mulish', sans-serif; 
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --content-padding: 1rem;
    --inline-spacing: 0.5rem;
}

* {
    box-sizing: border-box;
}

b{
    font-weight: 500;
}

strong{
    font-weight: 600;
}

.title {
    color: #04486c;
    font-size : 1.5rem;
    margin:0;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.p-component {
    font-family: Work Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
    opacity: 0.6;
}

.p-error {
    color: #f44336;
}

.p-text-secondary {
    color: #6c757d;
}
.h1{
    color:#04486c;
}

.pi {
    font-size: 1rem;
}

.p-link {
    font-size: 1rem;
    font-family: WorkSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 3px;
}

    .p-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

:root {
    --blue-50: #f4fafe;
    --blue-100: #cae6fc;
    --blue-200: #a0d2fa;
    --blue-300: #75bef8;
    --blue-400: #4baaf5;
    --blue-500: #2196f3;
    --blue-600: #1c80cf;
    --blue-700: #1769aa;
    --blue-800: #125386;
    --blue-900: #0d3c61;
    --green-50: #f6fbf6;
    --green-100: #d4ecd5;
    --green-200: #b2ddb4;
    --green-300: #90cd93;
    --green-400: #6ebe71;
    --green-500: #4caf50;
    --green-600: #419544;
    --green-700: #357b38;
    --green-800: #2a602c;
    --green-900: #1e4620;
    --yellow-50: #fffcf5;
    --yellow-100: #fef0cd;
    --yellow-200: #fde4a5;
    --yellow-300: #fdd87d;
    --yellow-400: #fccc55;
    --yellow-500: #fbc02d;
    --yellow-600: #d5a326;
    --yellow-700: #b08620;
    --yellow-800: #8a6a19;
    --yellow-900: #644d12;
    --cyan-50: #f2fcfd;
    --cyan-100: #c2eff5;
    --cyan-200: #91e2ed;
    --cyan-300: #61d5e4;
    --cyan-400: #30c9dc;
    --cyan-500: #00bcd4;
    --cyan-600: #00a0b4;
    --cyan-700: #008494;
    --cyan-800: #006775;
    --cyan-900: #004b55;
    --pink-50: #fef4f7;
    --pink-100: #fac9da;
    --pink-200: #f69ebc;
    --pink-300: #f1749e;
    --pink-400: #ed4981;
    --pink-500: #e91e63;
    --pink-600: #c61a54;
    --pink-700: #a31545;
    --pink-800: #801136;
    --pink-900: #5d0c28;
    --indigo-50: #f5f6fb;
    --indigo-100: #d1d5ed;
    --indigo-200: #acb4df;
    --indigo-300: #8893d1;
    --indigo-400: #6372c3;
    --indigo-500: #3f51b5;
    --indigo-600: #36459a;
    --indigo-700: #2c397f;
    --indigo-800: #232d64;
    --indigo-900: #192048;
    --teal-50: #f2faf9;
    --teal-100: #c2e6e2;
    --teal-200: #91d2cc;
    --teal-300: #61beb5;
    --teal-400: #30aa9f;
    --teal-500: #009688;
    --teal-600: #008074;
    --teal-700: #00695f;
    --teal-800: #00534b;
    --teal-900: #003c36;
    --orange-50: #fff8f2;
    --orange-100: #fde0c2;
    --orange-200: #fbc791;
    --orange-300: #f9ae61;
    --orange-400: #f79530;
    --orange-500: #f57c00;
    --orange-600: #d06900;
    --orange-700: #ac5700;
    --orange-800: #874400;
    --orange-900: #623200;
    --bluegray-50: #f7f9f9;
    --bluegray-100: #d9e0e3;
    --bluegray-200: #bbc7cd;
    --bluegray-300: #9caeb7;
    --bluegray-400: #7e96a1;
    --bluegray-500: #607d8b;
    --bluegray-600: #526a76;
    --bluegray-700: #435861;
    --bluegray-800: #35454c;
    --bluegray-900: #263238;
    --purple-50: #faf4fb;
    --purple-100: #e7cbec;
    --purple-200: #d4a2dd;
    --purple-300: #c279ce;
    --purple-400: #af50bf;
    --purple-500: #523557;
    --purple-600: #852196;
    --purple-700: #6d1b7b;
    --purple-800: #561561;
    --purple-900: #3e1046;
}

.p-autocomplete .p-autocomplete-loader {
    right: 0.5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
}

    .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
        padding: 0.25rem 0;
    }

        .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
            font-family: WorkSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 1rem;
            color: #495057;
            padding: 0;
            margin: 0;
        }

    .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background: #FFF3E0;
        color: #495057;
        border-radius: 3px;
    }

        .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
            margin-left: 0.5rem;
        }

.p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-autocomplete-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

    .p-autocomplete-panel .p-autocomplete-items {
        padding: 0.5rem 0;
    }

        .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
            margin: 0;
            padding: 0.5rem 1rem;
            border: 0 none;
            color: #495057;
            background: transparent;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

            .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
                color: #495057;
                background: #e9ecef;
            }

            .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

        .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
            margin: 0;
            padding: 0.75rem 1rem;
            color: #495057;
            background: #ffffff;
            font-weight: 600;
        }

.p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

    .p-datepicker:not(.p-datepicker-inline) {
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

        .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
            background: #ffffff;
        }

    .p-datepicker .p-datepicker-header {
        padding: 0.5rem;
        color: #495057;
        background: #ffffff;
        font-weight: 600;
        margin: 0;
        border-bottom: 1px solid #dee2e6;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-datepicker .p-datepicker-header .p-datepicker-prev,
        .p-datepicker .p-datepicker-header .p-datepicker-next {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
            .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
            .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-datepicker .p-datepicker-header .p-datepicker-title {
            line-height: 2rem;
        }

            .p-datepicker .p-datepicker-header .p-datepicker-title select {
                transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
            }

                .p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: 0 0 0 0.2rem #9bc1bc;
                    border-color: #ed6a5a;
                }

            .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
                margin-right: 0.5rem;
            }

    .p-datepicker table {
        font-size: 1rem;
        margin: 0.5rem 0;
    }

        .p-datepicker table th {
            padding: 0.5rem;
        }

            .p-datepicker table th > span {
                width: 2.5rem;
                height: 2.5rem;
            }

        .p-datepicker table td {
            padding: 0.5rem;
        }

            .p-datepicker table td > span {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                transition: box-shadow 0.2s;
                border: 1px solid transparent;
            }

                .p-datepicker table td > span.p-highlight {
                    color: #495057;
                    background: #FFF3E0;
                }

                .p-datepicker table td > span:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: 0 0 0 0.2rem #9bc1bc;
                }

            .p-datepicker table td.p-datepicker-today > span {
                background: #ced4da;
                color: #495057;
                border-color: transparent;
            }

                .p-datepicker table td.p-datepicker-today > span.p-highlight {
                    color: #495057;
                    background: #FFF3E0;
                }

    .p-datepicker .p-datepicker-buttonbar {
        padding: 1rem 0;
        border-top: 1px solid #dee2e6;
    }

        .p-datepicker .p-datepicker-buttonbar .p-button {
            width: auto;
        }

    .p-datepicker .p-timepicker {
        border-top: 1px solid #dee2e6;
        padding: 0.5rem;
    }

        .p-datepicker .p-timepicker button {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-datepicker .p-timepicker button:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-datepicker .p-timepicker button:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

            .p-datepicker .p-timepicker button:last-child {
                margin-top: .2em;
            }

        .p-datepicker .p-timepicker span {
            font-size: 1.25rem;
        }

        .p-datepicker .p-timepicker > div {
            padding: 0 0.5rem;
        }

    .p-datepicker.p-datepicker-timeonly .p-timepicker {
        border-top: 0 none;
    }

    .p-datepicker .p-monthpicker {
        margin: 0.5rem 0;
    }

        .p-datepicker .p-monthpicker .p-monthpicker-month {
            padding: 0.5rem;
            transition: box-shadow 0.2s;
            border-radius: 3px;
        }

            .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

    .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
        border-right: 1px solid #dee2e6;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }

        .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
            padding-left: 0;
        }

        .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
            padding-right: 0;
            border-right: 0 none;
        }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        background: #e9ecef;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
        background: #e9ecef;
    }

    .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

.p-datepicker-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
        padding: 0;
    }
}

.p-cascadeselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

    .p-cascadeselect:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-cascadeselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-cascadeselect .p-cascadeselect-label {
        background: transparent;
        border: 0 none;
        padding: 0.5rem 0.5rem;
    }

        .p-cascadeselect .p-cascadeselect-label.p-placeholder {
            color: #6c757d;
        }

        .p-cascadeselect .p-cascadeselect-label:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }

    .p-cascadeselect .p-cascadeselect-trigger {
        background: transparent;
        color: #6c757d;
        width: 2.357rem;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .p-cascadeselect.p-invalid.p-component {
        border-color: #f44336;
    }

.p-cascadeselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

    .p-cascadeselect-panel .p-cascadeselect-items {
        padding: 0.5rem 0;
    }

        .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
            margin: 0;
            border: 0 none;
            color: #495057;
            background: transparent;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

            .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
                padding: 0.5rem 1rem;
            }

                .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: inset 0 0 0 0.15rem #9bc1bc;
                }

            .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

            .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
                color: #495057;
                background: #e9ecef;
            }

            .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
                font-size: 0.875rem;
            }

.p-input-filled .p-cascadeselect {
    background: #f8f9fa;
}

    .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

.p-checkbox {
    width: 20px;
    height: 20px;
}

    .p-checkbox .p-checkbox-box {
        border: 2px solid #ced4da;
        background: #ffffff;
        width: 20px;
        height: 20px;
        color: #495057;
        border-radius: 3px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            transition-duration: 0.2s;
            color: #212529;
            font-size: 14px;
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #9bc1bc;
            background: #9bc1bc;
        }

            .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
                border-color: #9bc1bc;
                background: #9bc1bc;
                color: #212529;
            }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #9bc1bc;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #e6ebe0;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        border-color: #e6ebe0;
        background: #e6ebe0;
        color: #212529;
    }

    .p-checkbox.p-invalid > .p-checkbox-box {
        border-color: #f44336;
    }

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
}

    .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
        background: #e6ebe0;
    }

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #fff4b9;
}

.p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
}

    .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-chips .p-chips-multiple-container .p-chips-token {
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background: #FFF3E0;
        color: #495057;
        border-radius: 3px;
    }

        .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
            margin-left: 0.5rem;
        }

    .p-chips .p-chips-multiple-container .p-chips-input-token {
        padding: 0.25rem 0;
    }

        .p-chips .p-chips-multiple-container .p-chips-input-token input {
            font-family: WorkSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 1rem;
            color: #495057;
            padding: 0;
            margin: 0;
        }

.p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
}

.p-colorpicker-panel {
    background: #323232;
    border-color: #191919;
}

    .p-colorpicker-panel .p-colorpicker-color-handle,
    .p-colorpicker-panel .p-colorpicker-hue-handle {
        border-color: #ffffff;
    }

.p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

    .p-dropdown:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
        padding-right: 1.5rem;
    }

    .p-dropdown .p-dropdown-label {
        background: transparent;
        border: 0 none;
    }

        .p-dropdown .p-dropdown-label.p-placeholder {
            color: #6c757d;
        }

        .p-dropdown .p-dropdown-label:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }

    .p-dropdown .p-dropdown-trigger {
        background: transparent;
        color: #6c757d;
        width: 2.357rem;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .p-dropdown .p-dropdown-clear-icon {
        color: #6c757d;
        right: 2.357rem;
    }

    .p-dropdown.p-invalid.p-component {
        border-color: #f44336;
    }

.p-dropdown-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

    .p-dropdown-panel .p-dropdown-header {
        padding: 0.5rem 1rem;
        border-bottom: 0 none;
        color: #495057;
        background: #f8f9fa;
        margin: 0;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
            padding-right: 1.5rem;
        }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
            right: 0.5rem;
            color: #6c757d;
        }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
            padding-right: 3rem;
        }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
            right: 2rem;
        }

    .p-dropdown-panel .p-dropdown-items {
        padding: 0.5rem 0;
    }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
            margin: 0;
            padding: 0.5rem 1rem;
            border: 0 none;
            color: #495057;
            background: transparent;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

            .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

            .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
                color: #495057;
                background: #e9ecef;
            }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
            margin: 0;
            padding: 0.75rem 1rem;
            color: #495057;
            background: #ffffff;
            font-weight: 600;
        }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
            padding: 0.5rem 1rem;
            color: #495057;
            background: transparent;
        }

.p-input-filled .p-dropdown {
    background: #f8f9fa;
}

    .p-input-filled .p-dropdown:not(.p-disabled):hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

.p-editor-container .p-editor-toolbar {
    background: #f8f9fa;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

    .p-editor-container .p-editor-toolbar.ql-snow {
        border: 1px solid #dee2e6;
    }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
            stroke: #6c757d;
        }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
            fill: #6c757d;
        }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
            border: 0 none;
            color: #6c757d;
        }

            .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
                color: #495057;
            }

                .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
                    stroke: #495057;
                }

                .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
                    fill: #495057;
                }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
            color: #495057;
        }

            .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
                stroke: #495057;
            }

            .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
                fill: #495057;
            }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
            background: #ffffff;
            border: 0 none;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            border-radius: 3px;
            padding: 0.5rem 0;
        }

            .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
                color: #495057;
            }

                .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
                    color: #495057;
                    background: #e9ecef;
                }

        .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
            padding: 0.5rem 1rem;
        }

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

    .p-editor-container .p-editor-content.ql-snow {
        border: 1px solid #dee2e6;
    }

    .p-editor-container .p-editor-content .ql-editor {
        background: #ffffff;
        color: #495057;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #495057;
}

    .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
    .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
        stroke: #495057;
    }

    .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
    .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
        fill: #495057;
    }

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #ed6a5a;
}

    .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
        stroke: #ed6a5a;
    }

    .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
        fill: #ed6a5a;
    }

    .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
    .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
        color: #ed6a5a;
    }

.p-inputgroup-addon {
    background: #e9ecef;
    color: #6c757d;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
}

    .p-inputgroup-addon:last-child {
        border-right: 1px solid #ced4da;
    }

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
}

    .p-inputgroup > .p-component + .p-inputgroup-addon,
    .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
    .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
        border-left: 0 none;
    }

    .p-inputgroup > .p-component:focus,
    .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
    .p-inputgroup > .p-float-label > .p-component:focus {
        z-index: 1;
    }

        .p-inputgroup > .p-component:focus ~ label,
        .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
        .p-inputgroup > .p-float-label > .p-component:focus ~ label {
            z-index: 1;
        }

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}

    .p-fluid .p-inputgroup .p-button.p-button-icon-only {
        width: 2.357rem;
    }

.p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem;
}

    .p-inputswitch .p-inputswitch-slider {
        background: #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 30px;
    }

        .p-inputswitch .p-inputswitch-slider:before {
            background: #ffffff;
            width: 1.25rem;
            height: 1.25rem;
            left: 0.25rem;
            margin-top: -0.625rem;
            border-radius: 50%;
            transition-duration: 0.2s;
        }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        -webkit-transform: translateX(1.25rem);
                transform: translateX(1.25rem);
    }

    .p-inputswitch.p-focus .p-inputswitch-slider {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

    .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
        background: #b6bfc8;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #ed6a5a;
    }

        .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
            background: #ffffff;
        }

    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #e6ebe0;
    }

    .p-inputswitch.p-invalid {
        border-color: #f44336;
    }

.p-inputtext {
    font-family: WorkSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

    .p-inputtext:enabled:hover {
        border-color: #ed6a5a;
    }

    .p-inputtext:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-inputtext.p-invalid.p-component {
        border-color: #f44336;
    }

    .p-inputtext.p-inputtext-sm {
        font-size: 0.875rem;
        padding: 0.4375rem 0.4375rem;
    }

    .p-inputtext.p-inputtext-lg {
        font-size: 1.25rem;
        padding: 0.625rem 0.625rem;
    }

.p-float-label > label {
    left: 0.5rem;
    color: #6c757d;
    transition-duration: 0.2s;
}

    .p-float-label > label.p-error {
        color: #f44336;
    }

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
    left: 0.5rem;
    color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2rem;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
    right: 0.5rem;
    color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
}

::-webkit-input-placeholder {
    color: #6c757d;
}

:-moz-placeholder {
    color: #6c757d;
}

::-moz-placeholder {
    color: #6c757d;
}

:-ms-input-placeholder {
    color: #6c757d;
}

.p-input-filled .p-inputtext {
    background-color: #f8f9fa;
}

    .p-input-filled .p-inputtext:enabled:hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-inputtext:enabled:focus {
        background-color: #ffffff;
    }

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
}

.p-listbox {
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

    .p-listbox .p-listbox-header {
        padding: 0.5rem 1rem;
        border-bottom: 0 none;
        color: #495057;
        background: #f8f9fa;
        margin: 0;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-listbox .p-listbox-header .p-listbox-filter {
            padding-right: 1.5rem;
        }

        .p-listbox .p-listbox-header .p-listbox-filter-icon {
            right: 0.5rem;
            color: #6c757d;
        }

    .p-listbox .p-listbox-list {
        padding: 0.5rem 0;
    }

        .p-listbox .p-listbox-list .p-listbox-item {
            margin: 0;
            padding: 0.5rem 1rem;
            border: 0 none;
            color: #495057;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

            .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

            .p-listbox .p-listbox-list .p-listbox-item:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem #9bc1bc;
            }

        .p-listbox .p-listbox-list .p-listbox-item-group {
            margin: 0;
            padding: 0.75rem 1rem;
            color: #495057;
            background: #ffffff;
            font-weight: 600;
        }

        .p-listbox .p-listbox-list .p-listbox-empty-message {
            padding: 0.5rem 1rem;
            color: #495057;
            background: transparent;
        }

    .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #e9ecef;
    }

    .p-listbox.p-invalid {
        border-color: #f44336;
    }

.p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

    .p-multiselect:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-multiselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-multiselect.p-multiselect-clearable .p-multiselect-label {
        padding-right: 1.5rem;
    }

    .p-multiselect .p-multiselect-label {
        padding: 0.5rem 0.5rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

        .p-multiselect .p-multiselect-label.p-placeholder {
            color: #6c757d;
        }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background: #FFF3E0;
        color: #495057;
        border-radius: 3px;
    }

        .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
            margin-left: 0.5rem;
        }

    .p-multiselect .p-multiselect-trigger {
        background: transparent;
        color: #6c757d;
        width: 2.357rem;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .p-multiselect .p-multiselect-clear-icon {
        color: #6c757d;
        right: 2.357rem;
    }

    .p-multiselect.p-invalid.p-component {
        border-color: #f44336;
    }

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
}

    .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
        padding: 0.5rem 0.5rem;
    }

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.5rem;
}

.p-multiselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

    .p-multiselect-panel .p-multiselect-header {
        padding: 0.5rem 1rem;
        border-bottom: 0 none;
        color: #495057;
        background: #f8f9fa;
        margin: 0;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
            margin: 0 0.5rem;
        }

            .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
                padding-right: 1.5rem;
            }

            .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
                right: 0.5rem;
                color: #6c757d;
            }

        .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

    .p-multiselect-panel .p-multiselect-items {
        padding: 0.5rem 0;
    }

        .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
            margin: 0;
            padding: 0.5rem 1rem;
            border: 0 none;
            color: #495057;
            background: transparent;
            transition: box-shadow 0.2s;
            border-radius: 0;
        }

            .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
                color: #495057;
                background: #FFF3E0;
            }

            .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
                color: #495057;
                background: #e9ecef;
            }

            .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem #9bc1bc;
            }

            .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
                margin-right: 0.5rem;
            }

        .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
            margin: 0;
            padding: 0.75rem 1rem;
            color: #495057;
            background: #ffffff;
            font-weight: 600;
        }

        .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
            padding: 0.5rem 1rem;
            color: #495057;
            background: transparent;
        }

.p-input-filled .p-multiselect {
    background: #f8f9fa;
}

    .p-input-filled .p-multiselect:not(.p-disabled):hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

.p-password.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-password-panel {
    padding: 1rem;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

    .p-password-panel .p-password-meter {
        margin-bottom: 0.5rem;
        background: #dee2e6;
    }

        .p-password-panel .p-password-meter .p-password-strength.weak {
            background: #D32F2F;
        }

        .p-password-panel .p-password-meter .p-password-strength.medium {
            background: #FBC02D;
        }

        .p-password-panel .p-password-meter .p-password-strength.strong {
            background: #689F38;
        }

.p-radiobutton {
    width: 20px;
    height: 20px;
}

    .p-radiobutton .p-radiobutton-box {
        border: 2px solid #ced4da;
        background: #ffffff;
        width: 20px;
        height: 20px;
        color: #495057;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
            border-color: #ed6a5a;
        }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
            border-color: #ed6a5a;
        }

        .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
            width: 12px;
            height: 12px;
            transition-duration: 0.2s;
            background-color: #212529;
        }

        .p-radiobutton .p-radiobutton-box.p-highlight {
            border-color: #ed6a5a;
            background: #ed6a5a;
        }

            .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
                border-color: #9bc1bc;
                background: #9bc1bc;
                color: #212529;
            }

    .p-radiobutton.p-invalid > .p-radiobutton-box {
        border-color: #f44336;
    }

    .p-radiobutton:focus {
        outline: 0 none;
    }

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f8f9fa;
}

    .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #ed6a5a;
    }

        .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
            background: #9bc1bc;
        }

.p-rating .p-rating-icon {
    color: #495057;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;
}

    .p-rating .p-rating-icon.p-rating-cancel {
        color: #e74c3c;
    }

    .p-rating .p-rating-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

    .p-rating .p-rating-icon:first-child {
        margin-left: 0;
    }

    .p-rating .p-rating-icon.pi-star {
        color: #ed6a5a;
    }

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: #ed6a5a;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #c0392b;
}

.p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

    .p-selectbutton .p-button .p-button-icon-left,
    .p-selectbutton .p-button .p-button-icon-right {
        color: #6c757d;
    }

    .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
        background: #e9ecef;
        border-color: #ced4da;
        color: #495057;
    }

        .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
        .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
            color: #6c757d;
        }

    .p-selectbutton .p-button.p-highlight {
        background: #ed6a5a;
        color: #212529;
    }

        .p-selectbutton .p-button.p-highlight .p-button-icon-left,
        .p-selectbutton .p-button.p-highlight .p-button-icon-right {
            color: #212529;
        }

        .p-selectbutton .p-button.p-highlight:hover {
            background: #e6ebe0;
            border-color: #e6ebe0;
            color: #212529;
        }

            .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
            .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
                color: #212529;
            }

.p-selectbutton.p-invalid > .p-button {
    border-color: #f44336;
}

.p-slider {
    background: #dee2e6;
    border: 0 none;
    border-radius: 3px;
}

    .p-slider.p-slider-horizontal {
        height: 0.286rem;
    }

        .p-slider.p-slider-horizontal .p-slider-handle {
            margin-top: -0.5715rem;
            margin-left: -0.5715rem;
        }

    .p-slider.p-slider-vertical {
        width: 0.286rem;
    }

        .p-slider.p-slider-vertical .p-slider-handle {
            margin-left: -0.5715rem;
            margin-bottom: -0.5715rem;
        }

    .p-slider .p-slider-handle {
        height: 1.143rem;
        width: 1.143rem;
        background: #ffffff;
        border: 2px solid #ed6a5a;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

        .p-slider .p-slider-handle:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

    .p-slider .p-slider-range {
        background: #ed6a5a;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #ed6a5a;
        border-color: #ed6a5a;
    }

.p-treeselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

    .p-treeselect:not(.p-disabled):hover {
        border-color: #ed6a5a;
    }

    .p-treeselect:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
        border-color: #ed6a5a;
    }

    .p-treeselect .p-treeselect-label {
        padding: 0.5rem 0.5rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

        .p-treeselect .p-treeselect-label.p-placeholder {
            color: #6c757d;
        }

    .p-treeselect.p-treeselect-chip .p-treeselect-token {
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        background: #dee2e6;
        color: #495057;
        border-radius: 16px;
    }

    .p-treeselect .p-treeselect-trigger {
        background: transparent;
        color: #6c757d;
        width: 2.357rem;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .p-treeselect.p-invalid.p-component {
        border-color: #f44336;
    }

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.5rem;
}

.p-treeselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

    .p-treeselect-panel .p-treeselect-header {
        padding: 0.5rem 1rem;
        border-bottom: 0 none;
        color: #495057;
        background: #f8f9fa;
        margin: 0;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
            margin-right: 0.5rem;
        }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
                padding-right: 1.5rem;
            }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
                right: 0.5rem;
                color: #6c757d;
            }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
                padding-right: 3rem;
            }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
                right: 2rem;
            }

        .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
        border: 0 none;
    }

    .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
        padding: 0.5rem 1rem;
        color: #495057;
        background: transparent;
    }

.p-input-filled .p-treeselect {
    background: #f8f9fa;
}

    .p-input-filled .p-treeselect:not(.p-disabled):hover {
        background-color: #f8f9fa;
    }

    .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
        background-color: #ffffff;
    }

.p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

    .p-togglebutton.p-button .p-button-icon-left,
    .p-togglebutton.p-button .p-button-icon-right {
        color: #6c757d;
    }

    .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
        background: #e9ecef;
        border-color: #ced4da;
        color: #495057;
    }

        .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
        .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
            color: #6c757d;
        }

    .p-togglebutton.p-button.p-highlight {
        background: #ed6a5a;
        border-color: #ed6a5a;
        color: #212529;
    }

        .p-togglebutton.p-button.p-highlight .p-button-icon-left,
        .p-togglebutton.p-button.p-highlight .p-button-icon-right {
            color: #212529;
        }

        .p-togglebutton.p-button.p-highlight:hover {
            background: #e6ebe0;
            border-color: #e6ebe0;
            color: #212529;
        }

            .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
            .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
                color: #212529;
            }

    .p-togglebutton.p-button.p-invalid > .p-button {
        border-color: #f44336;
    }

.p-button {
    color: #212529;
    background: white;
    border: none;
    font-weight: 500;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-buttonbutton:enabled:hover {
    background: #e6ebe0;
    color: #212529;
    border-color: #e6ebe0;
}

.p-buttonbutton:enabled:active {
    background: #9bc1bc;
    color: #212529;
    border-color: #9bc1bc;
}

.p-button:not(button):not(.p-disabled):hover {
    background: #e6ebe0;
    color: #212529;
    border-color: #e6ebe0;
}

.p-button:not(button):not(.p-disabled):active {
    background: #9bc1bc;
    color: #212529;
    border-color: #9bc1bc;
}

.p-buttona:enabled:hover {
    background: #e6ebe0;
    color: #212529;
    border-color: #e6ebe0;
}

.p-buttona:enabled:active {
    background: #9bc1bc;
    color: #212529;
    border-color: #9bc1bc;
}

.p-button:not(a):not(.p-disabled):hover {
    background: #e6ebe0;
    color: #212529;
    border-color: #e6ebe0;
}

.p-button:not(a):not(.p-disabled):active {
    background: #9bc1bc;
    color: #212529;
    border-color: #9bc1bc;
}

.p-button.p-button-outlined {
    background-color: transparent;
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlinedbutton:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlinedbutton:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlineda:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlineda:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
    color: #6c757d;
    border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plainbutton:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plainbutton:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:not(button):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plaina:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plaina:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-text {
    background-color: transparent;
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-textbutton:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-textbutton:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-texta:enabled:hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-texta:enabled:active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(255, 193, 7, 0.04);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(255, 193, 7, 0.16);
    color: #ed6a5a;
    border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
    color: #6c757d;
}

.p-button.p-button-text.p-button-plainbutton:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plainbutton:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:not(button):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plaina:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plaina:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: #6c757d;
}

.p-button.p-button-text.p-button-plain:not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #6c757d;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #9bc1bc;
}

.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}

.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #ed6a5a;
    background-color: #212529;
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
    border-radius: 2rem;
}

.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
}

    .p-button.p-button-icon-only .p-button-icon-left,
    .p-button.p-button-icon-only .p-button-icon-right {
        margin: 0;
    }

    .p-button.p-button-icon-only.p-button-rounded {
        border-radius: 50%;
        height: 2.357rem;
    }

.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

    .p-button.p-button-sm .p-button-icon {
        font-size: 0.875rem;
    }

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
}

    .p-button.p-button-lg .p-button-icon {
        font-size: 1.25rem;
    }

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0;
}

.p-fluid .p-button {
    width: 100%;
}

.p-fluid .p-button-icon-only {
    width: 2.357rem;
}

.p-fluid .p-buttonset {
    display: flex;
}

    .p-fluid .p-buttonset .p-button {
        flex: 1 1;
    }

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: #ffffff;
    background: #607D8B;
    border: 1px solid #607D8B;
}

.p-button.p-button-secondarybutton:enabled:hover, .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-secondarybutton:enabled:hover {
    background: #56717d;
    color: #ffffff;
    border-color: #56717d;
}

.p-button.p-button-secondarybutton:enabled:focus, .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-secondarybutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondarybutton:enabled:active, .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-secondarybutton:enabled:active {
    background: #4d646f;
    color: #ffffff;
    border-color: #4d646f;
}

.p-button.p-button-secondary:not(button):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):hover {
    background: #56717d;
    color: #ffffff;
    border-color: #56717d;
}

.p-button.p-button-secondary:not(button):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondary:not(button):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):active {
    background: #4d646f;
    color: #ffffff;
    border-color: #4d646f;
}

.p-button.p-button-secondarya:enabled:hover, .p-buttonset.p-button-secondary > .p-buttona:enabled:hover, .p-splitbutton.p-button-secondary > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-secondarya:enabled:hover {
    background: #56717d;
    color: #ffffff;
    border-color: #56717d;
}

.p-button.p-button-secondarya:enabled:focus, .p-buttonset.p-button-secondary > .p-buttona:enabled:focus, .p-splitbutton.p-button-secondary > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-secondarya:enabled:focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondarya:enabled:active, .p-buttonset.p-button-secondary > .p-buttona:enabled:active, .p-splitbutton.p-button-secondary > .p-buttona:enabled:active, .p-fileupload-choose.p-button-secondarya:enabled:active {
    background: #4d646f;
    color: #ffffff;
    border-color: #4d646f;
}

.p-button.p-button-secondary:not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
    background: #56717d;
    color: #ffffff;
    border-color: #56717d;
}

.p-button.p-button-secondary:not(a):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondary:not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):active {
    background: #4d646f;
    color: #ffffff;
    border-color: #4d646f;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: transparent;
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlineda:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlineda:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
    background-color: transparent;
    color: #607D8B;
    border-color: transparent;
}

.p-button.p-button-secondary.p-button-textbutton:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-textbutton:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-texta:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-texta:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-texta:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-texta:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: #ffffff;
    background: #0288D1;
    border: 1px solid #0288D1;
}

.p-button.p-button-infobutton:enabled:hover, .p-buttonset.p-button-info > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-info > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-infobutton:enabled:hover {
    background: #027abc;
    color: #ffffff;
    border-color: #027abc;
}

.p-button.p-button-infobutton:enabled:focus, .p-buttonset.p-button-info > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-info > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-infobutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
}

.p-button.p-button-infobutton:enabled:active, .p-buttonset.p-button-info > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-info > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-infobutton:enabled:active {
    background: #026da7;
    color: #ffffff;
    border-color: #026da7;
}

.p-button.p-button-info:not(button):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:not(button):not(.p-disabled):hover {
    background: #027abc;
    color: #ffffff;
    border-color: #027abc;
}

.p-button.p-button-info:not(button):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
}

.p-button.p-button-info:not(button):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-info:not(button):not(.p-disabled):active {
    background: #026da7;
    color: #ffffff;
    border-color: #026da7;
}

.p-button.p-button-infoa:enabled:hover, .p-buttonset.p-button-info > .p-buttona:enabled:hover, .p-splitbutton.p-button-info > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-infoa:enabled:hover {
    background: #027abc;
    color: #ffffff;
    border-color: #027abc;
}

.p-button.p-button-infoa:enabled:focus, .p-buttonset.p-button-info > .p-buttona:enabled:focus, .p-splitbutton.p-button-info > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-infoa:enabled:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
}

.p-button.p-button-infoa:enabled:active, .p-buttonset.p-button-info > .p-buttona:enabled:active, .p-splitbutton.p-button-info > .p-buttona:enabled:active, .p-fileupload-choose.p-button-infoa:enabled:active {
    background: #026da7;
    color: #ffffff;
    border-color: #026da7;
}

.p-button.p-button-info:not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:not(a):not(.p-disabled):hover {
    background: #027abc;
    color: #ffffff;
    border-color: #027abc;
}

.p-button.p-button-info:not(a):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
}

.p-button.p-button-info:not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info:not(a):not(.p-disabled):active {
    background: #026da7;
    color: #ffffff;
    border-color: #026da7;
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
    background-color: transparent;
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlinedbutton:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlineda:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlineda:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlineda:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlineda:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    color: #0288D1;
    border: 1px solid;
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
    background-color: transparent;
    color: #0288D1;
    border-color: transparent;
}

.p-button.p-button-info.p-button-textbutton:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-info.p-button-textbutton:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-textbutton:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-info.p-button-textbutton:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-texta:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-info.p-button-texta:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-texta:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-info.p-button-texta:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-info.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
    color: #ffffff;
    background: #689F38;
    border: 1px solid #689F38;
}

.p-button.p-button-successbutton:enabled:hover, .p-buttonset.p-button-success > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-success > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-successbutton:enabled:hover {
    background: #5e8f32;
    color: #ffffff;
    border-color: #5e8f32;
}

.p-button.p-button-successbutton:enabled:focus, .p-buttonset.p-button-success > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-success > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-successbutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #c2e0a8;
}

.p-button.p-button-successbutton:enabled:active, .p-buttonset.p-button-success > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-success > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-successbutton:enabled:active {
    background: #537f2d;
    color: #ffffff;
    border-color: #537f2d;
}

.p-button.p-button-success:not(button):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:not(button):not(.p-disabled):hover {
    background: #5e8f32;
    color: #ffffff;
    border-color: #5e8f32;
}

.p-button.p-button-success:not(button):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #c2e0a8;
}

.p-button.p-button-success:not(button):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-success:not(button):not(.p-disabled):active {
    background: #537f2d;
    color: #ffffff;
    border-color: #537f2d;
}

.p-button.p-button-successa:enabled:hover, .p-buttonset.p-button-success > .p-buttona:enabled:hover, .p-splitbutton.p-button-success > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-successa:enabled:hover {
    background: #5e8f32;
    color: #ffffff;
    border-color: #5e8f32;
}

.p-button.p-button-successa:enabled:focus, .p-buttonset.p-button-success > .p-buttona:enabled:focus, .p-splitbutton.p-button-success > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-successa:enabled:focus {
    box-shadow: 0 0 0 0.2rem #c2e0a8;
}

.p-button.p-button-successa:enabled:active, .p-buttonset.p-button-success > .p-buttona:enabled:active, .p-splitbutton.p-button-success > .p-buttona:enabled:active, .p-fileupload-choose.p-button-successa:enabled:active {
    background: #537f2d;
    color: #ffffff;
    border-color: #537f2d;
}

.p-button.p-button-success:not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:not(a):not(.p-disabled):hover {
    background: #5e8f32;
    color: #ffffff;
    border-color: #5e8f32;
}

.p-button.p-button-success:not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #c2e0a8;
}

.p-button.p-button-success:not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success:not(a):not(.p-disabled):active {
    background: #537f2d;
    color: #ffffff;
    border-color: #537f2d;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
    background-color: transparent;
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlinedbutton:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlineda:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlineda:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlineda:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlineda:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    color: #689F38;
    border: 1px solid;
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
    background-color: transparent;
    color: #689F38;
    border-color: transparent;
}

.p-button.p-button-success.p-button-textbutton:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-success.p-button-textbutton:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-textbutton:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-success.p-button-textbutton:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-texta:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-success.p-button-texta:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-texta:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-success.p-button-texta:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-success.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
    color: #212529;
    background: #FBC02D;
    border: 1px solid #FBC02D;
}

.p-button.p-button-warningbutton:enabled:hover, .p-buttonset.p-button-warning > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-warning > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-warningbutton:enabled:hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710;
}

.p-button.p-button-warningbutton:enabled:focus, .p-buttonset.p-button-warning > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-warning > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-warningbutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warningbutton:enabled:active, .p-buttonset.p-button-warning > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-warning > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-warningbutton:enabled:active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704;
}

.p-button.p-button-warning:not(button):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710;
}

.p-button.p-button-warning:not(button):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warning:not(button):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:not(button):not(.p-disabled):active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704;
}

.p-button.p-button-warninga:enabled:hover, .p-buttonset.p-button-warning > .p-buttona:enabled:hover, .p-splitbutton.p-button-warning > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-warninga:enabled:hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710;
}

.p-button.p-button-warninga:enabled:focus, .p-buttonset.p-button-warning > .p-buttona:enabled:focus, .p-splitbutton.p-button-warning > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-warninga:enabled:focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warninga:enabled:active, .p-buttonset.p-button-warning > .p-buttona:enabled:active, .p-splitbutton.p-button-warning > .p-buttona:enabled:active, .p-fileupload-choose.p-button-warninga:enabled:active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704;
}

.p-button.p-button-warning:not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710;
}

.p-button.p-button-warning:not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warning:not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:not(a):not(.p-disabled):active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704;
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
    background-color: transparent;
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlinedbutton:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlineda:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlineda:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlineda:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    color: #FBC02D;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
    background-color: transparent;
    color: #FBC02D;
    border-color: transparent;
}

.p-button.p-button-warning.p-button-textbutton:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-textbutton:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-warning.p-button-textbutton:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-texta:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-texta:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-texta:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-warning.p-button-texta:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-warning.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
    color: #ffffff;
    background: #523557;
    border: 1px solid #523557;
}

.p-button.p-button-helpbutton:enabled:hover, .p-buttonset.p-button-help > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-help > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-helpbutton:enabled:hover {
    background: #8c239e;
    color: #ffffff;
    border-color: #8c239e;
}

.p-button.p-button-helpbutton:enabled:focus, .p-buttonset.p-button-help > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-help > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-helpbutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-helpbutton:enabled:active, .p-buttonset.p-button-help > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-help > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-helpbutton:enabled:active {
    background: #7d1f8d;
    color: #ffffff;
    border-color: #7d1f8d;
}

.p-button.p-button-help:not(button):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:not(button):not(.p-disabled):hover {
    background: #8c239e;
    color: #ffffff;
    border-color: #8c239e;
}

.p-button.p-button-help:not(button):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-help:not(button):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-help:not(button):not(.p-disabled):active {
    background: #7d1f8d;
    color: #ffffff;
    border-color: #7d1f8d;
}

.p-button.p-button-helpa:enabled:hover, .p-buttonset.p-button-help > .p-buttona:enabled:hover, .p-splitbutton.p-button-help > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-helpa:enabled:hover {
    background: #8c239e;
    color: #ffffff;
    border-color: #8c239e;
}

.p-button.p-button-helpa:enabled:focus, .p-buttonset.p-button-help > .p-buttona:enabled:focus, .p-splitbutton.p-button-help > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-helpa:enabled:focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-helpa:enabled:active, .p-buttonset.p-button-help > .p-buttona:enabled:active, .p-splitbutton.p-button-help > .p-buttona:enabled:active, .p-fileupload-choose.p-button-helpa:enabled:active {
    background: #7d1f8d;
    color: #ffffff;
    border-color: #7d1f8d;
}

.p-button.p-button-help:not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:not(a):not(.p-disabled):hover {
    background: #8c239e;
    color: #ffffff;
    border-color: #8c239e;
}

.p-button.p-button-help:not(a):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-help:not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help:not(a):not(.p-disabled):active {
    background: #7d1f8d;
    color: #ffffff;
    border-color: #7d1f8d;
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
    background-color: transparent;
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlinedbutton:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlineda:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlineda:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlineda:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlineda:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    color: #523557;
    border: 1px solid;
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
    background-color: transparent;
    color: #523557;
    border-color: transparent;
}

.p-button.p-button-help.p-button-textbutton:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-help.p-button-textbutton:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-textbutton:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-help.p-button-textbutton:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-texta:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-help.p-button-texta:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-texta:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-help.p-button-texta:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-help.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #523557;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
    color: #ffffff;
    background: #D32F2F;
    border: 1px solid #D32F2F;
}

.p-button.p-button-dangerbutton:enabled:hover, .p-buttonset.p-button-danger > .p-buttonbutton:enabled:hover, .p-splitbutton.p-button-danger > .p-buttonbutton:enabled:hover, .p-fileupload-choose.p-button-dangerbutton:enabled:hover {
    background: #c02929;
    color: #ffffff;
    border-color: #c02929;
}

.p-button.p-button-dangerbutton:enabled:focus, .p-buttonset.p-button-danger > .p-buttonbutton:enabled:focus, .p-splitbutton.p-button-danger > .p-buttonbutton:enabled:focus, .p-fileupload-choose.p-button-dangerbutton:enabled:focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.p-button.p-button-dangerbutton:enabled:active, .p-buttonset.p-button-danger > .p-buttonbutton:enabled:active, .p-splitbutton.p-button-danger > .p-buttonbutton:enabled:active, .p-fileupload-choose.p-button-dangerbutton:enabled:active {
    background: #aa2424;
    color: #ffffff;
    border-color: #aa2424;
}

.p-button.p-button-danger:not(button):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):hover {
    background: #c02929;
    color: #ffffff;
    border-color: #c02929;
}

.p-button.p-button-danger:not(button):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.p-button.p-button-danger:not(button):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:not(button):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:not(button):not(.p-disabled):active {
    background: #aa2424;
    color: #ffffff;
    border-color: #aa2424;
}

.p-button.p-button-dangera:enabled:hover, .p-buttonset.p-button-danger > .p-buttona:enabled:hover, .p-splitbutton.p-button-danger > .p-buttona:enabled:hover, .p-fileupload-choose.p-button-dangera:enabled:hover {
    background: #c02929;
    color: #ffffff;
    border-color: #c02929;
}

.p-button.p-button-dangera:enabled:focus, .p-buttonset.p-button-danger > .p-buttona:enabled:focus, .p-splitbutton.p-button-danger > .p-buttona:enabled:focus, .p-fileupload-choose.p-button-dangera:enabled:focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.p-button.p-button-dangera:enabled:active, .p-buttonset.p-button-danger > .p-buttona:enabled:active, .p-splitbutton.p-button-danger > .p-buttona:enabled:active, .p-fileupload-choose.p-button-dangera:enabled:active {
    background: #aa2424;
    color: #ffffff;
    border-color: #aa2424;
}

.p-button.p-button-danger:not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):hover {
    background: #c02929;
    color: #ffffff;
    border-color: #c02929;
}

.p-button.p-button-danger:not(a):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.p-button.p-button-danger:not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:not(a):not(.p-disabled):active {
    background: #aa2424;
    color: #ffffff;
    border-color: #aa2424;
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
    background-color: transparent;
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlinedbutton:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlinedbutton:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlinedbutton:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlinedbutton:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlinedbutton:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlinedbutton:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlinedbutton:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlineda:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlineda:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlineda:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlineda:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlineda:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlineda:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlineda:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(a):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    color: #D32F2F;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
    background-color: transparent;
    color: #D32F2F;
    border-color: transparent;
}

.p-button.p-button-danger.p-button-textbutton:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-textbutton:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-textbutton:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-textbutton:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-textbutton:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-textbutton:enabled:active, .p-fileupload-choose.p-button-danger.p-button-textbutton:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-text:not(button):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-texta:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-texta:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-texta:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-texta:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-texta:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-texta:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-texta:enabled:active, .p-fileupload-choose.p-button-danger.p-button-texta:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-danger.p-button-text:not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:not(a):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-link {
    color: #9bc1bc;
    background: transparent;
    border: transparent;
}

.p-button.p-button-linkbutton:enabled:hover {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

    .p-button.p-button-linkbutton:enabled:hover .p-button-label {
        text-decoration: underline;
    }

.p-button.p-button-linkbutton:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-linkbutton:enabled:active {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-link:not(button):not(.p-disabled):hover {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

    .p-button.p-button-link:not(button):not(.p-disabled):hover .p-button-label {
        text-decoration: underline;
    }

.p-button.p-button-link:not(button):not(.p-disabled):focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-link:not(button):not(.p-disabled):active {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-linka:enabled:hover {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

    .p-button.p-button-linka:enabled:hover .p-button-label {
        text-decoration: underline;
    }

.p-button.p-button-linka:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-linka:enabled:active {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-link:not(a):not(.p-disabled):hover {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

    .p-button.p-button-link:not(a):not(.p-disabled):hover .p-button-label {
        text-decoration: underline;
    }

.p-button.p-button-link:not(a):not(.p-disabled):focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #9bc1bc;
    border-color: transparent;
}

.p-button.p-button-link:not(a):not(.p-disabled):active {
    background: transparent;
    color: #9bc1bc;
    border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
}

    .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
    .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
        color: #495057;
        border-color: transparent;
        background: #e9ecef;
    }

    .p-carousel .p-carousel-content .p-carousel-prev:focus,
    .p-carousel .p-carousel-content .p-carousel-next:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

.p-carousel .p-carousel-indicators {
    padding: 1rem;
}

    .p-carousel .p-carousel-indicators .p-carousel-indicator {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

        .p-carousel .p-carousel-indicators .p-carousel-indicator button {
            background-color: #e9ecef;
            width: 2rem;
            height: 0.5rem;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            border-radius: 0;
        }

            .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
                background: #dee2e6;
            }

        .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
            background: #FFF3E0;
            color: #495057;
        }

.p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #495057;
    background: #FFF3E0;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: #495057;
}

    .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
        color: #6c757d;
    }

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #ed6a5a;
}

    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: #ed6a5a;
    }

    .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
        background: #e9ecef;
        color: #ed6a5a;
    }

        .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
            color: #ed6a5a;
        }

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem #9bc1bc;
    outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #9bc1bc;
}

    .p-datatable .p-datatable-tbody > tr > td {
        text-align: left;
        border: 1px solid #e9ecef;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
        outline-color: #9bc1bc;
    }

        .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
        .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
        .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
        .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
            .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
            margin-right: 0.5rem;
        }

        .p-datatable .p-datatable-tbody > tr > td.p-highlight {
            background: #FFF3E0;
            color: #495057;
        }

    .p-datatable .p-datatable-tbody > tr.p-highlight {
        background: #FFF3E0;
        color: #495057;
    }

    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
        box-shadow: inset 0 2px 0 0 #FFF3E0;
    }

    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
        box-shadow: inset 0 -2px 0 0 #FFF3E0;
    }

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}

.p-datatable .p-column-resizer-helper {
    background: #ed6a5a;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #f8f9fa;
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #fcfcfc;
}

    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
        background: #FFF3E0;
        color: #495057;
    }

        .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
            color: #495057;
        }

            .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
                color: #495057;
            }

    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
        background: #fcfcfc;
    }

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-datatable-drag-selection-helper {
    background: rgba(255, 193, 7, 0.16);
}

.p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-dataview-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-dataview .p-dataview-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #e9ecef;
    border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
}

.p-datascroller .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datascroller .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datascroller .p-datascroller-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datascroller .p-datascroller-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
}

.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: solid #e9ecef;
    border-width: 0 0 1px 0;
}

.p-datascroller .p-datascroller-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.fc {
    /* FullCalendar 4 */
    /* FullCalendar 5 */
}

    .fc.fc-unthemed .fc-view-container th {
        background: #f8f9fa;
        border: 1px solid #dee2e6;
        color: #495057;
    }

    .fc.fc-unthemed .fc-view-container td.fc-widget-content {
        border: 1px solid #dee2e6;
        color: #495057;
    }

    .fc.fc-unthemed .fc-view-container td.fc-head-container {
        border: 1px solid #dee2e6;
    }

    .fc.fc-unthemed .fc-view-container .fc-view {
        background: #ffffff;
    }

    .fc.fc-unthemed .fc-view-container .fc-row {
        border-right: 1px solid #dee2e6;
    }

    .fc.fc-unthemed .fc-view-container .fc-event {
        background: #c0d8d5;
        border: 1px solid #c0d8d5;
        color: #212529;
    }

    .fc.fc-unthemed .fc-view-container .fc-divider {
        background: #f8f9fa;
        border: 1px solid #dee2e6;
    }

    .fc.fc-unthemed .fc-toolbar .fc-button {
        color: #212529;
        background: #ed6a5a;
        border: 1px solid #ed6a5a;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 3px;
        display: flex;
        align-items: center;
    }

        .fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
            background: #c0d8d5;
            color: #212529;
            border-color: #c0d8d5;
        }

        .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
            background: #9bc1bc;
            color: #212529;
            border-color: #9bc1bc;
        }

            .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
            font-family: 'PrimeIcons' !important;
            text-indent: 0;
            font-size: 1rem;
        }

            .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
                content: "";
            }

        .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
            font-family: 'PrimeIcons' !important;
            text-indent: 0;
            font-size: 1rem;
        }

            .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
                content: "";
            }

        .fc.fc-unthemed .fc-toolbar .fc-button:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

        .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
            background: #ffffff;
            border: 1px solid #ced4da;
            color: #495057;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        }

            .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
                background: #e9ecef;
                border-color: #ced4da;
                color: #495057;
            }

            .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
                background: #ed6a5a;
                border-color: #ed6a5a;
                color: #212529;
            }

                .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
                    background: #c0d8d5;
                    border-color: #c0d8d5;
                    color: #212529;
                }

            .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
                z-index: 1;
            }

    .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
        border-radius: 0;
    }

        .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

    .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
        border-color: #dee2e6;
    }

    .fc.fc-theme-standard .fc-view-harness th {
        background: #f8f9fa;
        border-color: #dee2e6;
        color: #495057;
    }

    .fc.fc-theme-standard .fc-view-harness td {
        color: #495057;
        border-color: #dee2e6;
    }

    .fc.fc-theme-standard .fc-view-harness .fc-view {
        background: #ffffff;
    }

    .fc.fc-theme-standard .fc-view-harness .fc-popover {
        background: none;
        border: 0 none;
    }

        .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
            border: 1px solid #dee2e6;
            padding: 1rem;
            background: #f8f9fa;
            color: #495057;
        }

            .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                font-family: 'PrimeIcons' !important;
                font-size: 1rem;
                width: 2rem;
                height: 2rem;
                color: #6c757d;
                border: 0 none;
                background: transparent;
                border-radius: 50%;
                transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            }

                .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
                    content: "";
                }

                .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
                    color: #495057;
                    border-color: transparent;
                    background: #e9ecef;
                }

                .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: 0 0 0 0.2rem #9bc1bc;
                }

        .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
            padding: 1rem;
            border: 1px solid #dee2e6;
            background: #ffffff;
            color: #495057;
            border-top: 0 none;
        }

    .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
        color: #212529;
        background: #c0d8d5;
        border-color: #c0d8d5;
    }

        .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
            color: #212529;
        }

    .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
        background: #c0d8d5;
        border-color: #c0d8d5;
    }

    .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
        background: #e9ecef;
        color: #495057;
    }

    .fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
        background: #f8f9fa;
    }

    .fc.fc-theme-standard .fc-toolbar .fc-button {
        color: #212529;
        background: #ed6a5a;
        border: 1px solid #ed6a5a;
        font-size: 1rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 3px;
    }

        .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
            background: #c0d8d5;
            color: #212529;
            border-color: #c0d8d5;
        }

        .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
            background: #c0d8d5;
            color: #212529;
            border-color: #c0d8d5;
        }

            .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
            opacity: 0.6;
            color: #212529;
            background: #ed6a5a;
            border: 1px solid #ed6a5a;
        }

        .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
            font-family: 'PrimeIcons' !important;
            text-indent: 0;
            font-size: 1rem;
        }

            .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
                content: "";
            }

        .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
            font-family: 'PrimeIcons' !important;
            text-indent: 0;
            font-size: 1rem;
        }

            .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
                content: "";
            }

        .fc.fc-theme-standard .fc-toolbar .fc-button:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

        .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
            background: #ffffff;
            border: 1px solid #ced4da;
            color: #495057;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        }

            .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
                background: #e9ecef;
                border-color: #ced4da;
                color: #495057;
            }

            .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
                background: #ed6a5a;
                border-color: #ed6a5a;
                color: #212529;
            }

                .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
                    background: #c0d8d5;
                    border-color: #c0d8d5;
                    color: #212529;
                }

            .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
                z-index: 1;
            }

    .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
        border-radius: 0;
    }

        .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

.p-orderlist .p-orderlist-controls {
    padding: 1rem;
}

    .p-orderlist .p-orderlist-controls .p-button {
        margin-bottom: 0.5rem;
    }

.p-orderlist .p-orderlist-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    padding: 1rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-orderlist .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

    .p-orderlist .p-orderlist-list .p-orderlist-item {
        padding: 0.5rem 1rem;
        margin: 0;
        border: 0 none;
        color: #495057;
        background: transparent;
        transition: box-shadow 0.2s, -webkit-transform 0.2s;
        transition: transform 0.2s, box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
    }

        .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
            background: #e9ecef;
            color: #495057;
        }

        .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

        .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
            color: #495057;
            background: #FFF3E0;
        }

@media screen and (max-width: 769px) {
    .p-orderlist {
        flex-direction: column;
    }

        .p-orderlist .p-orderlist-controls {
            padding: 1rem;
            flex-direction: row;
        }

            .p-orderlist .p-orderlist-controls .p-button {
                margin-right: 0.5rem;
                margin-bottom: 0;
            }

                .p-orderlist .p-orderlist-controls .p-button:last-child {
                    margin-right: 0;
                }
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #FFF3E0;
    color: #495057;
}

    .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
        color: #ffc261;
    }

.p-organizationchart .p-organizationchart-line-down {
    background: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
}

    .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;
    }

        .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

.p-paginator {
    background: #ffffff;
    color: #6c757d;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
}

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
    }

        .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
            background: #e9ecef;
            border-color: transparent;
            color: #495057;
        }

    .p-paginator .p-paginator-first {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .p-paginator .p-paginator-last {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .p-paginator .p-dropdown {
        margin-left: 0.5rem;
        height: 2.357rem;
    }

        .p-paginator .p-dropdown .p-dropdown-label {
            padding-right: 0;
        }

    .p-paginator .p-paginator-current {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        padding: 0 0.5rem;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #6c757d;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
    }

        .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
            background: #FFF3E0;
            border-color: #FFF3E0;
            color: #495057;
        }

        .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
            background: #e9ecef;
            border-color: transparent;
            color: #495057;
        }

.p-picklist .p-picklist-buttons {
    padding: 1rem;
}

    .p-picklist .p-picklist-buttons .p-button {
        margin-bottom: 0.5rem;
    }

.p-picklist .p-picklist-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    padding: 1rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-picklist .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

    .p-picklist .p-picklist-list .p-picklist-item {
        padding: 0.5rem 1rem;
        margin: 0;
        border: 0 none;
        color: #495057;
        background: transparent;
        transition: box-shadow 0.2s, -webkit-transform 0.2s;
        transition: transform 0.2s, box-shadow 0.2s;
        transition: transform 0.2s, box-shadow 0.2s, -webkit-transform 0.2s;
    }

        .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
            background: #e9ecef;
            color: #495057;
        }

        .p-picklist .p-picklist-list .p-picklist-item:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

        .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
            color: #495057;
            background: #FFF3E0;
        }

@media screen and (max-width: 769px) {
    .p-picklist {
        flex-direction: column;
    }

        .p-picklist .p-picklist-buttons {
            padding: 1rem;
            flex-direction: row;
        }

            .p-picklist .p-picklist-buttons .p-button {
                margin-right: 0.5rem;
                margin-bottom: 0;
            }

                .p-picklist .p-picklist-buttons .p-button:last-child {
                    margin-right: 0;
                }

        .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
            content: "";
        }

        .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
            content: "";
        }

        .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
            content: "";
        }

        .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
            content: "";
        }
}

.p-tree {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    border-radius: 3px;
}

    .p-tree .p-tree-container .p-treenode {
        padding: 0.143rem;
    }

        .p-tree .p-tree-container .p-treenode .p-treenode-content {
            border-radius: 3px;
            transition: box-shadow 0.2s;
            padding: 0;
        }

            .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
                margin-right: 0.5rem;
                width: 2rem;
                height: 2rem;
                color: #6c757d;
                border: 0 none;
                background: transparent;
                border-radius: 50%;
                transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            }

                .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
                    color: #495057;
                    border-color: transparent;
                    background: #e9ecef;
                }

                .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: 0 0 0 0.2rem #9bc1bc;
                }

            .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
                margin-right: 0.5rem;
                color: #6c757d;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
                margin-right: 0.5rem;
            }

                .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
                    color: #495057;
                }

            .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
                background: #FFF3E0;
                color: #495057;
            }

                .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
                .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
                    color: #495057;
                }

                    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
                    .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
                        color: #495057;
                    }

            .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
                background: #e9ecef;
                color: #495057;
            }

    .p-tree .p-tree-filter-container {
        margin-bottom: 0.5rem;
    }

        .p-tree .p-tree-filter-container .p-tree-filter {
            width: 100%;
            padding-right: 1.5rem;
        }

        .p-tree .p-tree-filter-container .p-tree-filter-icon {
            right: 0.5rem;
            color: #6c757d;
        }

    .p-tree .p-treenode-children {
        padding: 0 0 0 1rem;
    }

    .p-tree .p-tree-loading-icon {
        font-size: 2rem;
    }

.p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-treetable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-treetable .p-treetable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
}

.p-treetable .p-sortable-column {
    outline-color: #9bc1bc;
}

    .p-treetable .p-sortable-column .p-sortable-column-icon {
        color: #6c757d;
        margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column .p-sortable-column-badge {
        border-radius: 50%;
        height: 1.143rem;
        min-width: 1.143rem;
        line-height: 1.143rem;
        color: #495057;
        background: #FFF3E0;
        margin-left: 0.5rem;
    }

    .p-treetable .p-sortable-column:not(.p-highlight):hover {
        background: #e9ecef;
        color: #495057;
    }

        .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
            color: #6c757d;
        }

    .p-treetable .p-sortable-column.p-highlight {
        background: #f8f9fa;
        color: #ed6a5a;
    }

        .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
            color: #ed6a5a;
        }

.p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #9bc1bc;
}

    .p-treetable .p-treetable-tbody > tr > td {
        text-align: left;
        border: 1px solid #e9ecef;
        border-width: 0 0 1px 0;
        padding: 1rem 1rem;
    }

        .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin-right: 0.5rem;
        }

            .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

            .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
                margin-right: 0.5rem;
            }

                .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
                    color: #495057;
                }

    .p-treetable .p-treetable-tbody > tr.p-highlight {
        background: #FFF3E0;
        color: #495057;
    }

        .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
            color: #495057;
        }

            .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
                color: #495057;
            }

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}

    .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
        color: #495057;
    }

.p-treetable .p-column-resizer-helper {
    background: #ed6a5a;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
}

.p-timeline .p-timeline-event-marker {
    border: 2px solid #ed6a5a;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
}

.p-timeline .p-timeline-event-connector {
    background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #c0d8d5;
    background: var(--opal-light);
    font-weight: 600;
    border-radius: 0.5rem;
    transition: box-shadow 0.2s;
}

    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        margin-right: 0.5rem;
    }

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: 0 0 0 0.2rem #9bc1bc; */
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #f8f9fa;
    border-color: #dee2e6;
    color: #495057;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #e9ecef;
    color: #495057;
}

.p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.p-accordion .p-accordion-tab {
    margin-bottom: 0;
}

    .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
        border-radius: 0;
    }

    .p-accordion .p-accordion-tab .p-accordion-content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
        border-top: 0 none;
    }

    .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        border-top: 0 none;
    }

    .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }

    .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

    .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }

.p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

    .p-card .p-card-body {
        padding: 1rem;
    }

    .p-card .p-card-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }

    .p-card .p-card-subtitle {
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: #6c757d;
    }

    .p-card .p-card-content {
        padding: 1rem 0;
    }

    .p-card .p-card-footer {
        padding: 1rem 0 0 0;
    }

.p-fieldset {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-radius: 3px;
}

    .p-fieldset .p-fieldset-legend {
        padding: 1rem;
        border: 1px solid #dee2e6;
        color: #495057;
        background: #f8f9fa;
        font-weight: 600;
        border-radius: 3px;
    }

    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
        padding: 0;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

        .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
            padding: 1rem;
            color: #495057;
            border-radius: 3px;
            transition: box-shadow 0.2s;
        }

            .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
                margin-right: 0.5rem;
            }

            .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
            background: #e9ecef;
            border-color: #dee2e6;
            color: #495057;
        }

    .p-fieldset .p-fieldset-content {
        padding: 1rem;
    }

.p-divider .p-divider-content {
    background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}

    .p-divider.p-divider-horizontal:before {
        border-top: 1px #04486c;
    }

    .p-divider.p-divider-horizontal .p-divider-content {
        padding: 0 0.5rem;
    }

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}

    .p-divider.p-divider-vertical:before {
        border-left: 1px #dee2e6;
    }

    .p-divider.p-divider-vertical .p-divider-content {
        padding: 0.5rem 0;
    }

.p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1rem;
    background: #f8f9fa;
    color: #495057;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

    .p-panel .p-panel-header .p-panel-title {
        font-weight: 600;
    }

    .p-panel .p-panel-header .p-panel-header-icon {
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

        .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
            color: #495057;
            border-color: transparent;
            background: #e9ecef;
        }

        .p-panel .p-panel-header .p-panel-header-icon:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
}

.p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0 none;
}

.p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0 none;
}

.p-splitter {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 3px;
    color: #495057;
}

    .p-splitter .p-splitter-gutter {
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        background: #f8f9fa;
    }

        .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
            background: #dee2e6;
        }

    .p-splitter .p-splitter-gutter-resizing {
        background: #dee2e6;
    }

.p-scrollpanel .p-scrollpanel-bar {
    background: #f8f9fa;
    border: 0 none;
}

.p-tabview .p-tabview-nav {
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}

    .p-tabview .p-tabview-nav li {
        margin-right: 0;
    }

        .p-tabview .p-tabview-nav li .p-tabview-nav-link {
            border: solid #dee2e6;
            border-width: 0 0 2px 0;
            border-color: transparent transparent #dee2e6 transparent;
            color: #6c757d;
            padding: 1rem;
            font-weight: 600;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            transition: box-shadow 0.2s;
            margin: 0 0 -2px 0;
        }

            .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            border-color: #6c757d;
            color: #6c757d;
        }

        .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            border-color: #ed6a5a;
            color: #ed6a5a;
        }

.p-tabview .p-tabview-panels {
    padding: 1rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-toolbar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 3px;
}

    .p-toolbar .p-toolbar-separator {
        margin: 0 0.5rem;
    }

.p-confirm-popup {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

    .p-confirm-popup .p-confirm-popup-content {
        padding: 1rem;
    }

    .p-confirm-popup .p-confirm-popup-footer {
        text-align: right;
        padding: 0 1rem 1rem 1rem;
    }

        .p-confirm-popup .p-confirm-popup-footer button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }

            .p-confirm-popup .p-confirm-popup-footer button:last-child {
                margin: 0;
            }

    .p-confirm-popup:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }

    .p-confirm-popup:before {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
    }

    .p-confirm-popup.p-confirm-popup-flipped:after {
        border-top-color: #ffffff;
    }

    .p-confirm-popup.p-confirm-popup-flipped:before {
        border-top-color: #ffffff;
    }

    .p-confirm-popup .p-confirm-popup-icon {
        font-size: 1.5rem;
    }

    .p-confirm-popup .p-confirm-popup-message {
        margin-left: 1rem;
    }

.p-dialog {
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
}

    .p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #ffffff;
        color: #495057;
        padding: 1.5rem;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

        .p-dialog .p-dialog-header .p-dialog-title {
            font-weight: 600;
            font-size: 1.25rem;
        }

        .p-dialog .p-dialog-header .p-dialog-header-icon {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin-right: 0.5rem;
        }

            .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

            .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
                margin-right: 0;
            }

    .p-dialog .p-dialog-content {
        background: #ffffff;
        color: #495057;
        padding: 0 1.5rem 2rem 1.5rem;
    }

    .p-dialog .p-dialog-footer {
        border-top: 0 none;
        background: #ffffff;
        color: #495057;
        padding: 0 1.5rem 1.5rem 1.5rem;
        text-align: right;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

        .p-dialog .p-dialog-footer button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
        font-size: 2rem;
    }

    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: 1rem;
    }

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
    background: #c0d8d5;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

    .p-overlaypanel .p-overlaypanel-content {
        padding: 1rem;
    }

    .p-overlaypanel .p-overlaypanel-close {
        background: #c0d8d5;
        color: #212529;
        width: 2rem;
        height: 2rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

        .p-overlaypanel .p-overlaypanel-close:enabled:hover {
            background: #9bc1bc;
            color: #212529;
        }

    .p-overlaypanel:after {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #c0d8d5;
    }

    .p-overlaypanel:before {
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #c0d8d5;
    }

    .p-overlaypanel.p-overlaypanel-flipped:after {
        background: #c0d8d5;
        border-top-color: #ffffff;
    }

    .p-overlaypanel.p-overlaypanel-flipped:before {
        border-top-color: #ffffff;
    }

.p-sidebar {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

    .p-sidebar .p-sidebar-header {
        padding: 1rem;
    }

        .p-sidebar .p-sidebar-header .p-sidebar-close,
        .p-sidebar .p-sidebar-header .p-sidebar-icon {
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
            .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
                color: #495057;
                border-color: transparent;
                background: #e9ecef;
            }

            .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
            .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-sidebar .p-sidebar-header + .p-sidebar-content {
            padding-top: 0;
        }

    .p-sidebar .p-sidebar-content {
        padding: 1rem;
    }

.p-sidebar-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #495057;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #495057;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #495057;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

    .p-fileupload .p-fileupload-buttonbar .p-button {
        margin-right: 0.5rem;
    }

.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-fileupload .p-progressbar {
    height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-breadcrumb {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    padding: 1rem;
}

    .p-breadcrumb ul li .p-menuitem-link {
        transition: box-shadow 0.2s;
        border-radius: 3px;
    }

        .p-breadcrumb ul li .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

        .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
        }

    .p-breadcrumb ul li.p-breadcrumb-chevron {
        margin: 0 0.5rem 0 0.5rem;
        color: #495057;
    }

    .p-breadcrumb ul li:last-child .p-menuitem-text {
        color: #495057;
    }

    .p-breadcrumb ul li:last-child .p-menuitem-icon {
        color: #6c757d;
    }

.p-contextmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
}

    .p-contextmenu .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-contextmenu .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-contextmenu .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-contextmenu .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-contextmenu .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-contextmenu .p-submenu-list {
        padding: 0.25rem 0;
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: #e9ecef;
    }

        .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

    .p-contextmenu .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.25rem 0;
    }

    .p-contextmenu .p-submenu-icon {
        font-size: 0.875rem;
    }

.p-megamenu {
    padding: 0.5rem;
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}

    .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 3px;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
            margin-left: 0.5rem;
        }

        .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
    .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }

        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
            color: #495057;
        }

        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
            color: #6c757d;
        }

        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
        .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
            color: #6c757d;
        }

    .p-megamenu .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-megamenu .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-megamenu .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-megamenu .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-megamenu .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-megamenu .p-megamenu-panel {
        background: #ffffff;
        color: #495057;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-megamenu .p-megamenu-submenu-header {
        margin: 0;
        padding: 0.75rem 1rem;
        color: #495057;
        background: #ffffff;
        font-weight: 600;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .p-megamenu .p-megamenu-submenu {
        padding: 0.25rem 0;
        width: 12.5rem;
    }

        .p-megamenu .p-megamenu-submenu .p-menu-separator {
            border-top: 1px solid #dee2e6;
            margin: 0.25rem 0;
        }

    .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: #e9ecef;
    }

        .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

    .p-megamenu.p-megamenu-vertical {
        width: 12.5rem;
        padding: 0.25rem 0;
    }

.p-menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
}

    .p-menu .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-menu .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-menu .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-menu .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-menu .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-menu .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-menu.p-menu-overlay {
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-menu .p-submenu-header {
        margin: 0;
        padding: 0.75rem 1rem;
        color: #495057;
        background: #ffffff;
        font-weight: 600;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .p-menu .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.25rem 0;
    }

.p-menubar {
    padding: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    background: #ed6a5a;
    color: #5d646b;
    border: 0px solid #dee2e6;
    border-radius: 3px;
}

    .p-menubar .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-menubar .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-menubar .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-menubar .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-menubar .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-menubar .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: 0.75rem 1.25rem;
        color: #495057;
        border-radius: 3px;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
            color: black;
        }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
            margin-left: 0.5rem;
        }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }

        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
            color: #495057;
        }

        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
            color: #6c757d;
        }

        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
        .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
            color: #6c757d;
        }

    .p-menubar .p-submenu-list {
        padding: 0.25rem 0;
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 12.5rem;
    }

        .p-menubar .p-submenu-list .p-menu-separator {
            border-top: 1px solid #dee2e6;
            margin: 0.25rem 0;
        }

        .p-menubar .p-submenu-list .p-submenu-icon {
            font-size: 0.875rem;
        }

    .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: #e9ecef;
    }

        .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

@media screen and (max-width: 992px) {
    .p-menubar {
        position: relative;
        padding: 1rem ;
    }

        .p-menubar .p-menubar-button {
            display: flex;
            margin-right : 1rem;
            padding-right: 1rem;
            padding-left: 1rem;
            width: 2rem;
            height: 2rem;
            color: #6c757d;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-menubar .p-menubar-button:hover {
                color: #6c757d;
                background: #e9ecef;
            }

            .p-menubar .p-menubar-button:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-menubar .p-menubar-root-list {
            position: absolute;
            display: none;
            padding: 0.25rem 0;
            background: #ed6a5a;
            border: 0 none;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            width: 100%;
        }

            .p-menubar .p-menubar-root-list .p-menu-separator {
                border-top: 1px solid #dee2e6;
                margin: 0.25rem 0;
            }

            .p-menubar .p-menubar-root-list .p-submenu-icon {
                font-size: 0.875rem;
            }

            .p-menubar .p-menubar-root-list > .p-menuitem {
                width: 100%;
                position: static;
            }

                .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
                    padding: 0.75rem 1rem;
                    color: #495057;
                    border-radius: 0;
                    transition: box-shadow 0.2s;
                    -webkit-user-select: none;
                        -ms-user-select: none;
                            user-select: none;
                }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
                        color: #495057;
                    }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
                        color: #6c757d;
                        margin-right: 0.5rem;
                    }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
                        color: #6c757d;
                    }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
                        background: #e9ecef;
                    }

                        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                            color: #495057;
                        }

                        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                            color: #6c757d;
                        }

                        .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                            color: #6c757d;
                        }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
                        outline: 0 none;
                        outline-offset: 0;
                        box-shadow: inset 0 0 0 0.15rem #9bc1bc;
                    }

                    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
                        margin-left: auto;
                        transition: -webkit-transform 0.2s;
                        transition: transform 0.2s;
                        transition: transform 0.2s, -webkit-transform 0.2s;
                    }

                .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
                    -webkit-transform: rotate(-180deg);
                            transform: rotate(-180deg);
                }

            .p-menubar .p-menubar-root-list .p-submenu-list {
                width: 100%;
                position: static;
                box-shadow: none;
                border: 0 none;
            }

                .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
                    transition: -webkit-transform 0.2s;
                    transition: transform 0.2s;
                    transition: transform 0.2s, -webkit-transform 0.2s;
                    -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                }

                .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
                    -webkit-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                }

            .p-menubar .p-menubar-root-list .p-menuitem {
                width: 100%;
                position: static;
            }

            .p-menubar .p-menubar-root-list ul li a {
                padding-left: 2.25rem;
            }

            .p-menubar .p-menubar-root-list ul li ul li a {
                padding-left: 3.75rem;
            }

            .p-menubar .p-menubar-root-list ul li ul li ul li a {
                padding-left: 5.25rem;
            }

            .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
                padding-left: 6.75rem;
            }

            .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
                padding-left: 8.25rem;
            }

        .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
            display: flex;
            flex-direction: column;
            top: 100%;
            left: 0;
            z-index: 1;
        }
}

.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

    .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
        margin-right: 0.5rem;
    }

    .p-panelmenu .p-panelmenu-header > a:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}

    .p-panelmenu .p-panelmenu-header.p-highlight > a {
        background: #f8f9fa;
        border-color: #dee2e6;
        color: #495057;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
        border-color: #dee2e6;
        background: #e9ecef;
        color: #495057;
    }

.p-panelmenu .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

    .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

        .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
            margin-right: 0.5rem;
        }

    .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
        padding: 0 0 0 1rem;
    }

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
}

    .p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
        border-radius: 0;
    }

    .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
        border-radius: 0;
    }

    .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
        border-top: 0 none;
    }

    .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
        border-top: 0 none;
    }

    .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

.p-slidemenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
}

    .p-slidemenu .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-slidemenu .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-slidemenu .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-slidemenu .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-slidemenu .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-slidemenu.p-slidemenu-overlay {
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-slidemenu .p-slidemenu-list {
        padding: 0.25rem 0;
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
        background: #e9ecef;
    }

        .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
            color: #495057;
        }

        .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
            color: #6c757d;
        }

    .p-slidemenu .p-slidemenu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.25rem 0;
    }

    .p-slidemenu .p-slidemenu-icon {
        font-size: 0.875rem;
    }

    .p-slidemenu .p-slidemenu-backward {
        padding: 0.75rem 1rem;
        color: #495057;
    }

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 3px;
    background: #ffffff;
}

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        color: #495057;
        border: 1px solid #e9ecef;
        background: #ffffff;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        margin-top: 0.5rem;
        color: #6c757d;
    }

    .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #FFF3E0;
    color: #495057;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #495057;
}

.p-steps .p-steps-item:before {
    content: ' ';
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
        margin-right: 0;
    }

        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
            border: solid #dee2e6;
            border-width: 0 0 2px 0;
            border-color: transparent transparent #dee2e6 transparent;
            background: #ffffff;
            color: #6c757d;
            padding: 1rem;
            font-weight: 600;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            transition: box-shadow 0.2s;
            margin: 0 0 -2px 0;
        }

            .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
                margin-right: 0.5rem;
            }

            .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
            background: #ffffff;
            border-color: #6c757d;
            color: #6c757d;
        }

        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
            background: #ffffff;
            border-color: #ed6a5a;
            color: #ed6a5a;
        }

.p-tieredmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    width: 12.5rem;
}

    .p-tieredmenu .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

        .p-tieredmenu .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
            color: #6c757d;
            margin-right: 0.5rem;
        }

        .p-tieredmenu .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

        .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
            background: #e9ecef;
        }

            .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
                color: #495057;
            }

            .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
                color: #6c757d;
            }

            .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
                color: #6c757d;
            }

        .p-tieredmenu .p-menuitem-link:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #9bc1bc;
        }

    .p-tieredmenu.p-tieredmenu-overlay {
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-tieredmenu .p-submenu-list {
        padding: 0.25rem 0;
        background: #ffffff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: #e9ecef;
    }

        .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
            color: #495057;
        }

        .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
            color: #6c757d;
        }

    .p-tieredmenu .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: 0.25rem 0;
    }

    .p-tieredmenu .p-submenu-icon {
        font-size: 0.875rem;
    }

.p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 3px;
}

    .p-inline-message.p-inline-message-info {
        background: #B3E5FC;
        border: solid #0891cf;
        border-width: 0px;
        color: #044868;
    }

        .p-inline-message.p-inline-message-info .p-inline-message-icon {
            color: #044868;
        }

    .p-inline-message.p-inline-message-success {
        background: #C8E6C9;
        border: solid #439446;
        border-width: 0px;
        color: #224a23;
    }

        .p-inline-message.p-inline-message-success .p-inline-message-icon {
            color: #224a23;
        }

    .p-inline-message.p-inline-message-warn {
        background: #FFECB3;
        border: solid #d9a300;
        border-width: 0px;
        color: #6d5100;
    }

        .p-inline-message.p-inline-message-warn .p-inline-message-icon {
            color: #6d5100;
        }

    .p-inline-message.p-inline-message-error {
        background: #FFCDD2;
        border: solid #e60017;
        border-width: 0px;
        color: #73000c;
    }

        .p-inline-message.p-inline-message-error .p-inline-message-icon {
            color: #73000c;
        }

    .p-inline-message .p-inline-message-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    .p-inline-message .p-inline-message-text {
        font-size: 1rem;
    }

    .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
        margin-right: 0;
    }

.p-message {
    margin: 1rem 0;
    border-radius: 3px;
}

    .p-message .p-message-wrapper {
        padding: 1rem 1.5rem;
    }

    .p-message .p-message-close {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: transparent;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

        .p-message .p-message-close:hover {
            background: rgba(255, 255, 255, 0.3);
        }

        .p-message .p-message-close:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

    .p-message.p-message-info {
        background: #B3E5FC;
        border: solid #0891cf;
        border-width: 0 0 0 6px;
        color: #044868;
    }

        .p-message.p-message-info .p-message-icon {
            color: #044868;
        }

        .p-message.p-message-info .p-message-close {
            color: #044868;
        }

    .p-message.p-message-success {
        background: #C8E6C9;
        border: solid #439446;
        border-width: 0 0 0 6px;
        color: #224a23;
    }

        .p-message.p-message-success .p-message-icon {
            color: #224a23;
        }

        .p-message.p-message-success .p-message-close {
            color: #224a23;
        }

    .p-message.p-message-warn {
        background: #FFECB3;
        border: solid #d9a300;
        border-width: 0 0 0 6px;
        color: #6d5100;
    }

        .p-message.p-message-warn .p-message-icon {
            color: #6d5100;
        }

        .p-message.p-message-warn .p-message-close {
            color: #6d5100;
        }

    .p-message.p-message-error {
        background: #FFCDD2;
        border: solid #e60017;
        border-width: 0 0 0 6px;
        color: #73000c;
    }

        .p-message.p-message-error .p-message-icon {
            color: #73000c;
        }

        .p-message.p-message-error .p-message-close {
            color: #73000c;
        }

    .p-message .p-message-text {
        font-size: 1rem;
        font-weight: 500;
    }

    .p-message .p-message-icon {
        font-size: 1.5rem;
        margin-right: 0.5rem;
    }

.p-toast {
    opacity: 0.9;
}

    .p-toast .p-toast-message {
        margin: 0 0 1rem 0;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;
    }

        .p-toast .p-toast-message .p-toast-message-content {
            padding: 1rem;
            border-width: 0 0 0 6px;
        }

            .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
                margin: 0 0 0 1rem;
            }

            .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
                font-size: 2rem;
            }

            .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
                font-weight: 700;
            }

            .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
                margin: 0.5rem 0 0 0;
            }

        .p-toast .p-toast-message .p-toast-icon-close {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: transparent;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        }

            .p-toast .p-toast-message .p-toast-icon-close:hover {
                background: rgba(255, 255, 255, 0.3);
            }

            .p-toast .p-toast-message .p-toast-icon-close:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem #9bc1bc;
            }

        .p-toast .p-toast-message.p-toast-message-info {
            background: #B3E5FC;
            border: solid #0891cf;
            border-width: 0 0 0 6px;
            color: #044868;
        }

            .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
            .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
                color: #044868;
            }

        .p-toast .p-toast-message.p-toast-message-success {
            background: #C8E6C9;
            border: solid #439446;
            border-width: 0 0 0 6px;
            color: #224a23;
        }

            .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
            .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
                color: #224a23;
            }

        .p-toast .p-toast-message.p-toast-message-warn {
            background: #FFECB3;
            border: solid #d9a300;
            border-width: 0 0 0 6px;
            color: #6d5100;
        }

            .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
            .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
                color: #6d5100;
            }

        .p-toast .p-toast-message.p-toast-message-error {
            background: #FFCDD2;
            border: solid #e60017;
            border-width: 0 0 0 6px;
            color: #73000c;
        }

            .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
            .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
                color: #73000c;
            }

.p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

    .p-galleria .p-galleria-close .p-galleria-close-icon {
        font-size: 2rem;
    }

    .p-galleria .p-galleria-close:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f8f9fa;
    }

.p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 0 0.5rem;
}

    .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
    .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
        font-size: 2rem;
    }

    .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
        background: rgba(255, 255, 255, 0.1);
        color: #f8f9fa;
    }

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #f8f9fa;
    padding: 1rem;
}

.p-galleria .p-galleria-indicators {
    padding: 1rem;
}

    .p-galleria .p-galleria-indicators .p-galleria-indicator button {
        background-color: #e9ecef;
        width: 1rem;
        height: 1rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
    }

        .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
            background: #dee2e6;
        }

    .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #FFF3E0;
        color: #495057;
    }

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
}

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
        background: rgba(255, 255, 255, 0.4);
    }

        .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
            background: rgba(255, 255, 255, 0.6);
        }

    .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background: #FFF3E0;
        color: #495057;
    }

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
}

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
        margin: 0.5rem;
        background-color: transparent;
        color: #f8f9fa;
        width: 2rem;
        height: 2rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
    }

        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
            background: rgba(255, 255, 255, 0.1);
            color: #f8f9fa;
        }

    .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
        transition: box-shadow 0.2s;
    }

        .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

.p-galleria-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
    background-color: #dee2e6;
    border-radius: 3px;
}

    .p-avatar.p-avatar-lg {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
    }

        .p-avatar.p-avatar-lg .p-avatar-icon {
            font-size: 1.5rem;
        }

    .p-avatar.p-avatar-xl {
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
    }

        .p-avatar.p-avatar-xl .p-avatar-icon {
            font-size: 2rem;
        }

.p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
}

.p-chip {
    background-color: #e6ebe0;
    color: #495057;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

    .p-chip .p-chip-text {
        line-height: 1.5;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    .p-chip .p-chip-icon {
        margin-right: 0.5rem;
    }

    .p-chip img {
        width: 2rem;
        height: 2rem;
        margin-left: -0.5rem;
        margin-right: 0.5rem;
    }

    .p-chip .p-chip-remove-icon {
        border-radius: 3px;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin-left: 0.5rem;
    }

        .p-chip .p-chip-remove-icon:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #9bc1bc;
        }

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

    .p-scrolltop.p-link {
        background: rgba(0, 0, 0, 0.7);
    }

        .p-scrolltop.p-link:hover {
            background: rgba(0, 0, 0, 0.8);
        }

    .p-scrolltop .p-scrolltop-icon {
        font-size: 1.5rem;
        color: #f8f9fa;
    }

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
}

    .p-skeleton:after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }

.p-tag {
    background: #ed6a5a;
    color: #212529;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
}

    .p-tag.p-tag-success {
        background-color: #689F38;
        color: #ffffff;
    }

    .p-tag.p-tag-info {
        background-color: #0288D1;
        color: #ffffff;
    }

    .p-tag.p-tag-warning {
        background-color: #FBC02D;
        color: #212529;
    }

    .p-tag.p-tag-danger {
        background-color: #D32F2F;
        color: #ffffff;
    }

    .p-tag .p-tag-icon {
        margin-right: 0.25rem;
        font-size: 0.75rem;
    }

.p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

    .p-inplace .p-inplace-display:not(.p-disabled):hover {
        background: #e9ecef;
        color: #495057;
    }

    .p-inplace .p-inplace-display:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #9bc1bc;
    }

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #dee2e6;
    border-radius: 3px;
}

    .p-progressbar .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: #ed6a5a;
    }

    .p-progressbar .p-progressbar-label {
        color: #495057;
        line-height: 1.5rem;
    }

.p-terminal {
    background: #ffffff;
    color: #495057;
    border: 1px solid #dee2e6;
    padding: 1rem;
}

    .p-terminal .p-terminal-input {
        font-size: 1rem;
        font-family: WorkSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

.p-blockui.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.p-badge {
    background: #ed6a5a;
    color: #212529;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
}

    .p-badge.p-badge-secondary {
        background-color: #607D8B;
        color: #ffffff;
    }

    .p-badge.p-badge-success {
        background-color: #689F38;
        color: #ffffff;
    }

    .p-badge.p-badge-info {
        background-color: #0288D1;
        color: #ffffff;
    }

    .p-badge.p-badge-warning {
        background-color: #FBC02D;
        color: #212529;
    }

    .p-badge.p-badge-danger {
        background-color: #D32F2F;
        color: #ffffff;
    }

    .p-badge.p-badge-lg {
        font-size: 1.125rem;
        min-width: 2.25rem;
        height: 2.25rem;
        line-height: 2.25rem;
    }

    .p-badge.p-badge-xl {
        font-size: 1.5rem;
        min-width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }

.p-tag {
    background: #ed6a5a;
    color: #212529;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
}

    .p-tag.p-tag-success {
        background-color: #689F38;
        color: #ffffff;
    }

    .p-tag.p-tag-info {
        background-color: #0288D1;
        color: #ffffff;
    }

    .p-tag.p-tag-warning {
        background-color: #FBC02D;
        color: #212529;
    }

    .p-tag.p-tag-danger {
        background-color: #D32F2F;
        color: #ffffff;
    }

.p-virtualscroller-loading-icon {
    font-size: 2rem;
}

/* Customizations to the designer theme should be defined here */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #ed6a5a;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #ed6a5a;
}

.float-form-button{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0rem;
    background-color: white;
    padding: 0.7rem;
}

.form-entreprise-theme div>label,
.form-entreprise-theme h1,
.form-entreprise-theme h2,
.form-entreprise-theme h3{
    font-weight: 700;
}

.form-entreprise-theme h1{
    font-size: 24px;
}

.form-entreprise-theme h1,
.form-entreprise-theme h2
{
    color: var(--resolution-blue);
}

.form-entreprise-theme div>label:nth-child(1){
    color:var(--resolution-blue);
}

.form-entreprise-theme div>label:nth-child(2){
    color:var(--terra-cotta);
}

.form-entreprise-theme input,
.form-entreprise-theme .p-inputtextarea,
.form-entreprise-theme .p-dropdown,
.form-entreprise-theme .p-autocomplete,
.form-entreprise-theme .p-multiselect{
    border-radius:5px;
    background-color: white;
}

.form-entreprise-theme input::-webkit-input-placeholder, .form-entreprise-theme textarea::-webkit-input-placeholder{
    font-style: italic;
}

.form-entreprise-theme input:-ms-input-placeholder, .form-entreprise-theme textarea:-ms-input-placeholder{
    font-style: italic;
}

.form-entreprise-theme input::placeholder,
.form-entreprise-theme textarea::placeholder{
    font-style: italic;
}

.form-entreprise-theme.with-shadows input,
.form-entreprise-theme.with-shadows .p-inputtextarea,
.form-entreprise-theme.with-shadows .p-dropdown{
    border: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}


.form-entreprise-theme.with-blue-borders input,
.form-entreprise-theme.with-blue-borders .no-borders{
    border: 1px solid var(--pastel-blue);
}

.form-entreprise-theme.with-blue-borders .p-inputtextarea:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-dropdown:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-autocomplete:not(.no-borders):not(.p-invalid),
.form-entreprise-theme.with-blue-borders .p-multiselect:not(.no-borders):not(.p-invalid){
    border: 1px solid var(--resolution-blue);
}

@media screen and (max-width:992px){
    .float-form-button{
        background-color: white;
    }
}
.dip-btn-p-lateral {
    padding: 0.5rem 2rem !important;
}

.dip-btn-red-outline {
    color: black;
    border: 1px solid var(--terra-cotta);
    background-color: white;
    padding: 0.5rem;
}

.dip-btn-red-outline:not(a):not(.p-disabled):hover {
    color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-red-outline:not(a):not(.p-disabled):active {
    color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-red-outline:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-iris-outline {
    color: var(--iris-100);
    border: 1px solid var(--iris-100);
    background-color: white;
    padding: 0.5rem;
}

.dip-btn-iris-outline:not(a):not(.p-disabled):hover {
    color: var(--iris-100);
    border-color: var(--iris-100);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-iris-outline:not(a):not(.p-disabled):active {
    color: var(--iris-100);
    border-color: var(--iris-100);
    background: rgba(211, 47, 47, 0.04)
}

.dip-btn-iris-outline:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-red-bg {
    color: white;
    border: 1px solid var(--terra-cotta);
    background-color: var(--terra-cotta);
    padding: 0.5rem 2rem;
}

.dip-btn-kashmir-blue-bg {
    color: var(--terra-cotta);
    border: 1px solid var(--kashmir-blue);
    background-color: var(--kashmir-blue);
    padding: 0.5rem 2rem;
}

.dip-btn-iris-bg {
    color: white;
    border: 1px solid var(--iris-100);
    background-color: var(--iris-100);
    padding: 0.5rem 2rem;
}

.dip-btn-white-bg {
    color: var(--iris-100);
    border: 1px solid white;
    background-color: white;
    padding: 0.5rem 2rem;
}

.dip-btn-id-bg {
    color: white;
    border: 1px solid var(--indigo-dye);
    background-color: var(--indigo-dye);
    padding: 0.5rem 2rem;
}

.dip-btn-bk-bg {
    color: white;
    border: 1px solid black;
    background-color: black;
    padding: 0.5rem 2rem;
}

.dip-btn-ib-bg {
    color: white;
    border: 1px solid var(--indigo-blue);
    background-color: var(--indigo-blue);
    padding: 0.5rem 2rem;
}

.dip-btn-ib-bg:not(a):not(.p-disabled):hover {
    background-color: var(--indigo-dye);
    color: white;
}

.dip-rounded-red-border-white-bg {
    color: black;
    border: 1px solid var(--terra-cotta);
    background-color: white;
    border-radius: 2rem;
    padding: 0.5rem;
}

.dip-rounded-red-border-white-bg:not(a):not(.p-disabled):hover {
    color: white;
    background-color: var(--terra-cotta);
}

.dip-rounded-red-border-white-bg:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-draggable-tag {
    cursor: grab;
    color: var(--terra-cotta);
    border: 1px solid var(--terra-cotta);
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

/* ROUND BUTTON */

.dip-btn-round.p-button {
    width: 7rem;
    height: 7rem;
    margin: 1rem;
    padding: 2rem 1rem;
    border: 1px solid var(--terra-cotta) !important;
    border-radius: 10rem !important;
}

.dip-btn-round.p-button:not(a):not(.p-disabled):active {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-button:not(a):not(.p-disabled):hover {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}

.dip-btn-round.p-buttonset .p-button {
    width: 7rem;
    height: 7rem;
    margin: 1rem;
    padding: 2rem 1rem;
    border: 1px solid var(--terra-cotta) !important;
    border-radius: 10rem !important;
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):active {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):hover {
    background: var(--terra-cotta);
    color: white;
    border-color: var(--terra-cotta);
}

.dip-btn-round.p-buttonset .p-button:not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}


.dip-slct-btn-spaced.p-buttonset .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.dip-slct-btn-spaced.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border: 1px solid var(--terra-cotta);
    border-radius: 1rem;
}

.dip-slct-btn-spaced.p-buttonset .p-button:first-of-type {
    border-radius: 1rem;
    border: 1px solid var(--terra-cotta);
}

.dip-slct-btn-spaced.p-buttonset .p-button:last-of-type {
    border-radius: 1rem;
    border: 1px solid var(--terra-cotta);
}

.dip-slct-btn-spaced.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: var(--alabaster);
    border: 1px solid var(--terra-cotta);
}

@media screen and (max-width:992px) {
    .dip-btn-round.p-button {
        width: 5rem;
        height: 5rem;
    }
}
/* COLOR */

.dip-color-tc {
    color: var(--terra-cotta)
}

.dip-color-id {
    color: var(--indigo-dye);
}

.dip-color-eb {
    color: var(--eerie-black);
}

.dip-color-lb {
    color: var(--linkedin-blue);
}

.dip-color-white {
    color: white;
}
.dip-color-grey {
    color: grey;
}

.dip-color-res-blue{
    color:var(--resolution-blue)
}

.dip-color-pas-blue{
    color:var(--pastel-blue)
}

/* BACKGROUND COLOR */

.dip-bg-color-tc {
    background-color: var(--terra-cotta);
}

.dip-bg-color-eb {
    background-color: var(--eerie-black);
}

.dip-bg-color-whisper {
    background-color: var(--whisper);
}

.dip-bg-color-al {
    background-color: var(--alabaster);
}

.dip-bg-color-white {
    background-color: white;
}

.dip-bg-color-pb{
    background-color: var(--pastel-blue);
}

.dip-bg-color-res-blue{
    background-color: var(--resolution-blue);
}

/* FLEX */

.dip-flex {
    display: flex;
}

.dip-block {
    display: block;
}

@media screen and (max-width:992px) {
    .dip-flex {
        flex-direction: column;
    }
}

.dip-flex-row {
    display: flex;
    flex-direction: row;
}

.dip-flex-column {
    display: flex;
    flex-direction: column;
}

.dip-inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}
.dip-inline-flex-row {
    display: inline-flex;
    flex-direction: row;
}

.dip-flex-grow-1 {
    flex-grow: 1;
}

/* ALIGNEMENT */

.dip-ta-center {
    text-align: center;
}

.dip-ta-left{
    text-align: left;
}

.dip-ta-right {
    text-align: right;
}

.dip-ta-justify{
    text-align: justify;
}

.dip-pc-center {
    place-content: center;
}

.dip-ai-center {
    align-items: center;
}
.dip-ai-start {
    align-items: flex-start;
}
.dip-ai-end {
    align-items: flex-end;
}

.dip-as-center {
    align-self: center;
}

.dip-jc-center {
    justify-content: center;
}

.dip-jc-between {
    justify-content: space-between;
}

.dip-jc-around {
    justify-content: space-around;
}

.dip-jc-end {
    justify-content: end;
}

.dip-ps-end{
    place-self: end;
}

/* MARGIN */

.dip-m-0 {
    margin: 0;
}

.dip-mt-0 {
    margin-top: 0 !important;
}

.dip-mb-0 {
    margin-bottom: 0 !important;
}

.dip-ml-auto{
    margin-left: auto;
}

.dip-mt-auto{
    margin-top: auto;
}


.dip-ml-0 {
    margin-left: 0 !important;
}


.dip-mr-0 {
    margin-right: 0 !important;
}

/* PADDING */

.dip-p-0{
    padding: 0 !important;
}

/* HOVER */

.dip-hover-color-tc:hover {
    cursor: pointer;
    color: var(--terra-cotta);
}

/* HEIGHT */

.dip-h-100 {
    height: 100%;
}

.dip-h-auto {
    height: auto;
}

/* WIDTH */

.dip-w-100 {
    width: 100%;
}

.dip-w-auto {
    width: auto;
}

/* FONT-FAMILY */

.dip-ff-muslish{
    font-family: var(--font-family-mulish);
}

.dip-ff-poppins{
    font-family: var(--font-family-poppins);
}

/* FONT-SIZE */

.dip-fs-2 {
    font-size: 1.5rem;
}

.dip-fs-3 {
    font-size: 2rem;
}

.dip-fs-3 {
    font-size: 2.5rem;
}

.dip-fs-4 {
    font-size: 3rem;
}

/* FONT-WEIGHT */

.dip-fw-400{
    font-weight: 400;
}

.dip-fw-500{
    font-weight: 500;
}

.dip-fw-600{
    font-weight: 600;
}

.dip-fw-700{
    font-weight: 700;
}



.dip-pointer {
    cursor: pointer;
}

/* DISPLAY */

.dip-only-pc {
    display: flex !important;
}

.dip-only-mobile {
    display: none !important;
}

.dip-disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* VISIBILITY */

.dip-visibility-hidden {
    visibility: hidden;
} 

.dip-display-none {
    display: none !important;
}

@media screen and (max-width:992px) {
    .dip-only-pc {
        display: none !important;
    }

    .dip-only-mobile {
        display: flex !important;
    }
}
.dip-wysiwyg {
    word-break: break-word;
}

.dip-wysiwyg img {
    max-width: 100%;
    height: auto;
}

.dip-wysiwyg iframe {
    max-width: calc(100vw - 15rem);
    height:auto;
    aspect-ratio: 16/9;
}

@media screen and (min-width:1500px) {
    .dip-wysiwyg iframe {
        max-width: 80rem;
    }
}

@media screen and (max-width:992px) {
    .dip-wysiwyg iframe {
        max-width: calc(100vw - 5rem);
    }
}


.datatable-administrateurs .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.datatable-editeurs .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}



.datatable-cv_etudiants .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


.datatable-etudiants .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.getu-status-container .p-tag{
    color : white
}

.getu-status-container .status-CreatingAccount{
    background-color : var(--linkedin-blue)
}

.getu-status-container .status-Active{
    background-color : var(--opal)
}
.getu-status-container .status-Disabled{
    background-color : var(--terra-cotta)
}

.getu-status-container .status-Archived{
    background-color : var(--selective-yellow)
}

.getu-status-container .status-Invited{
    background-color : var(--green-success)
}
.dee-confirm-dialog-container {
    width: 50%;
}

.dde-datatable-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.dde-datatable-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    justify-content: left;
    align-items: left;
}

.dee-confirm-buttons-container {
    display: flex;
    justify-content: flex-end;
}

.datatable-entreprises .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


.datatable-demandeFormation_etudiants .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dt-df-e-custom-tooltip .p-tooltip-text {
    max-width: 50rem;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.center-element-oc{
    text-align: center;
    margin-top: 20%;
}

.grey-italic-message{
    color:grey;
    font-style: italic;
}

.select-matching{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}



@media screen and (max-width:992px) {
    .select-matching .p-button{
        padding : 0.5rem
    }
}


.p-selectbutton  .p-button{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  .p-selectbutton .p-button.p-highlight{
      color : white;
  }
.dip-tag{
    font-family: var(--font-family-poppins);
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 2rem;
    display: inline-block;
    text-align: center;
}

.dip-tag-p-sm,
.dip-tag.dip-tag-p-sm{
    padding: 0.2rem 0.5rem;
}

.dip-tag-p-md,
.dip-tag.dip-tag-p-md{
    padding: 0.5rem 1rem;
}

.dip-tag-p-lg,
.dip-tag.dip-tag-p-lg{
    padding: 0.5rem 2rem;
}

.dip-tag-letter-spacing
.dip-tag,.dip-tag-letter-spacing{
    letter-spacing: 0.1rem;
}

.dip-tag-fs-sm{
    font-size: 0.65rem;
}

.dip-tag-orange {
    color: var(--iris-100);
    border: 1px solid var(--iris-60);
    background-color: var(--iris-60);
}

.dip-tag-yellow {
    color: var(--eerie-black);
    border: 1px solid var(--rum-swizzle);
    background-color: var(--rum-swizzle);
}

.dip-tag-blue {
    color: var(--royal-blue);
    border: 1px solid var(--perano);
    background-color: var(--perano);
}

.dip-tag-pearl {
    color: var(--jaffa);
    border: 1px solid var(--double-pearl-lusta);
    background-color: var(--double-pearl-lusta);
}

.dip-tag-pink {
    color: var(--eerie-black);
    border: 1px solid var(--carousel-pink);
    background-color: var(--carousel-pink);
}
.thumbnail-paginator{
    background-color: transparent;
}
.credentials{
    padding-bottom: 0rem;
}

.credentials span{
    color : var(--indigo-blue);
    font-size : 1.3rem;
}

.logo-for-school{
    text-align: center;
    align-self: center;
}

.logo-for-school img{
    width: auto;
    max-width: 100%;
    height: 9rem;
}

.role-display{
    padding-top:0rem;
}

.composante-name{
    font-size:1.2rem;
}

.school-name{
    color : var(--indigo-dye);
    font-size : 1.5rem;
}

.profile-header-content{
    text-align: left;
}

@media screen and (max-width:992px) {
    .profile-header-content{
        text-align: center;
    }
}
.dashboard-item{
    background-color: white;
    padding : 1rem;
    border-radius: 0.5rem;
}
.ssb-main {
    background-color: var(--iris-100);
    width: 100%;
}

.ssb-submain {
    display: flex;
    height: 14.87rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 4rem;
}

.ssb-submain-title {
    font-size: 1.5em;
    margin-bottom: 1em;
    text-align: center;
    color: white;
    font-weight: 600;
    margin-top: 0;
}

.ssb-submain-subtitle {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
}

.ssb-inputrounded.p-inputtext {
    border-radius: 2rem;
    width: 100%
}

.ssb-color-advanced {
    background-color: var(--indigo_bis);
    font-weight: 500;
}

.ssb-hr {
    height: 0.1rem;
    color: var(--indigo-dye);
    background-color: var(--indigo-dye);
    border: 0rem;
}

.ssb-fields-wraper {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.ssb-fields-wraper>div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p-input-icon-right.icon-search-position {
    display: flex;
}

.icon-search-position.p-input-icon-right>i:last-of-type {
    z-index: 99;
}

.ssb-fields-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.ssb-field {
    padding: 0.5rem 0;
    flex-basis: 50%;
}

.ssb-autocomplete.p-autocomplete {
    width: 100%;
}

.ssb-search-button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ssb-item {
    max-width: -webkit-fill-available;
    white-space: pre-wrap;
}

.sbb-advanced-search {
    text-decoration: none;
    color: white;
    font-weight: 600;
}

.ssb-search-wrapper .ssb-autocomplete input {
    text-overflow: ellipsis !important;
    padding-right: 1.5rem;
}

.ssb-button-results span{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    pointer-events: none; 
}

@media screen and (max-width:992px) {
    .ssb-advanced-search-container {
        text-align: center;
        margin-right: 0;
    }

    .ssb-fields-container {
        flex-direction: column;
        align-items: stretch;
    }

    .ssb-field {
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-basis: 100%;
    }

    .ssb-search-button {
        text-align: center;
        flex-direction: column-reverse;
        padding: 0rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .ssb-search-button>span:first-child {
        margin: 0.5rem 0;
    }

    .ssb-search-button div {
        margin-top: 1rem !important;
    }

    .ssb-fields-wraper {
        width: 100%;
    }

    .ssb-fields-wraper>div:first-child {
        flex-direction: column;
    }

    .ssb-submain {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .container-him-intro h1 {
        font-size: 1.6rem;
        font-weight: 600;
    }
}
.catchphrase-text-title {
    font-size: 4vw;
    color: black;
    font-weight: 400;
}

.banner-home-student {
    background-image: url(/static/media/bannerHomeStudents.1fbf43bf.png);
}

.catchphrase-text-subtitle {
    font-size: 1.5rem;
    color: black;
    font-weight: 300;
}

.catchphrase-bloc-student {
    display: flex;
    place-content: center;
    position: relative;
    width: 57%;
    height: 100%;
}

.catchphrase-text-title h1 {
    margin: 0;
    font-weight: 600;
    font-size: 2.5rem;
    display: inline;
}

.catchphrase-bloc-student-home {
    display: flex;
    flex-direction: column;
    place-content: center;
    position: relative;
    width: 57%;
    height: 100%;
}

.container-buttons-home-student {
    display: flex;
}

.container-buttons-home-student .p-button {
    border: 1px solid;
    padding: 0.8rem 1.8rem;
    font-size: 1.2rem;
    letter-spacing: 4px;
    margin-right: 2rem;
}

.container-buttons-home-student .p-button:first-child {
    border: none;
}

@media screen and (max-width:992px) {
    .banner-home-student {
        background-image: url(/static/media/bannerHomeStudentMobile.20dabdbc.png);
    }

    .container-buttons-home-student {
        margin:0;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .container-buttons-home-student div,
    .container-buttons-home-student a {
        width: 100%;
    }

    .container-buttons-home-student .p-button {
        font-size: 0.7rem;
        padding: 0.9rem 1rem;
        margin-top: 1rem;
        margin-right: 0 !important;
        width: 100%;
        min-width: 19rem;
    }

    .catchphrase-bloc-student-home {
        width: 90%;
        align-items: center;
    }

    .catchphrase-text-title {
        font-size: 2.5rem;
    }

    .catchphrase-text-subtitle {
        font-size: 1.5rem;
    }

    .catchphrase-text-title h1 {
        margin-top: 1rem;
    }
}
.container-him {
    margin: 0 2rem;
}

.container-numbers{
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container-him-intro,
.container-global-number  {
    font-family: var(--font-family-poppins);
}

.container-him-intro{
    margin: 0;
}

.container-him-intro h1 {
    text-align: start;
}

.container-global-number{
    display: flex;
    justify-content: flex-start;
    flex-direction: column; 
}

.number-style-number {
    font-size: 4rem;
    font-weight: 600;
    width: 4rem;
    min-width: 4rem;
} 

.number-style-first-line {
    font-size: 1.3rem;
    font-weight: 500;
}

.number-style-others-lines {
    font-size: 1.3rem;
    font-weight: 600;
} 


.bhnc-button.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined{
    color:black;
    border-color: var(--terra-cotta);
    background-color: white;
    width: auto;
}

.catchphrase-first h1{
margin: 0;
font-weight: 500;
font-size: inherit;
display: inline;
}

.catchphrase-text h1{
    margin: 0;
    font-weight: 500;
    font-size: inherit;
    display: inline;
    }


.lgm-container>div {
    width: 25%;
    padding: 0.5rem;
}

.lgm-container {
    margin-top: 4rem;
}

.lgm-container img {
    max-width: -webkit-fill-available;
}

.lgm-join-container {
    display: flex;
    height: 100%;
    position: relative;
    place-content: center;
}

.lgm-button-container {
    position: absolute;
    place-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 3rem;
}

.lgm-button {
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
}

.lgm-text-container {
    position: relative;
    margin: 0 auto;
    padding: 1rem 1.2rem 0 1.2rem;
    border-radius: 3rem;
    min-height: 7rem;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: 17rem;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.05);
}

.lgm-text-container span {
    color: grey;
    font-weight: 500;
}

.lgm-text-button-container {
    position: relative;
    margin: 0 auto;
    padding: 0.2rem 1rem 0 1rem;
    border-radius: 3rem;
    min-height: 7rem;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: 17rem;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.05);
}

.lgm-text-container h3 {
    font-size: 1.25rem;
    font-weight: 600;
}

.lgm-text-button-container h3 {
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.lgm-img-container {
    display: flex;
    margin-left: 4.5rem;
    height: 6.875rem;
    margin-bottom: -0.5rem;
}

.lgm-img-container img {
    max-height: 6.875rem;
}

.lgm-img-container .picto-1{
    padding-left: 3rem;
    -webkit-transform: rotate(21.38deg);
            transform: rotate(21.38deg);
    z-index: 95;
}

.lgm-img-container .picto-2{
    padding-left: 2rem;
    -webkit-transform: rotate(-8.51deg);
            transform: rotate(-8.51deg);
    z-index: 95;
    height: 75%;
}

.lgm-img-container .picto-3{
    padding-left: 1.5rem;
    z-index: 95;
    height: 83%;
}

.lgm-img-last-container {
    place-content: center;
    margin-left: 0rem;
}


.lgm-img-container :nth-child(2) {
    margin-left: -2rem;
    margin-bottom: 0.5rem;
}

@media screen and (max-width:992px) {
    .lgm-container>div {
        width: 100%;
    }

    .lgm-img-container {
        margin-left: 0rem;
        place-content: center;
    }
    
}

.container-home-student-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.center-home {
    text-align: center;
    margin: auto;
    width: 100%;
}

.banner-container {
    margin-left: 2.5rem;
    background-position: 180% 33%;
    background-size: 90% auto;
    background-repeat: no-repeat;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.banner-etudiant {
    background-image: url(/static/media/accueilEtudiant.bf519ae0.webp);
}

.banner-entreprise {
    background-image: url(/static/media/accueilEntreprise.c9524bf9.webp);
}

.banner-ecole {
    background-image: url(/static/media/accueilEcole.8e76492f.webp);
}

.catchphrase-first {
    font-size: 2.2vw;
    color: var(--indigo-dye);
    font-weight: 500;
}

.catchphrase-text {
    font-size: 3vw;
    color: var(--indigo-dye);
    font-weight: 500;
}

.catchphrase-bloc {
    display: flex;
    place-content: center;
    position: relative;
    width: 50%;
    height: 100%;
}

.catchprase-text {
    place-content: center;
    width: 80%;
    position: absolute;
    top: 8px;
    left: 16px;
}

.catchphrase-second {
    font-size: 2rem;
    color: var(--indigo-dye);
}

.catchphrase-component {
    font-size: 1.7rem;
    color: var(--indigo-dye);
}

.catchphrase-blank {
    margin-top: 6rem;
}

.catchphrase-modules {
    margin: 0rem 6rem 2rem 6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.catchphrase-modules>div {
    flex-basis: 33.333333%
}

.catchphrase-logo {
    margin: 0 0 0 0;
}

.catchphrase-logo>img {
    max-height: 8rem;
    width: auto;
}

.catchphrase-phrase {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--indigo-dye);
}

.catchphrase-button {
    padding: 0.2rem;
    text-transform: uppercase;
    overflow: hidden;
}

.banner-home {
    height: 16rem;
    width: 100%;
}

.banner-image {
    background-image: url(/static/media/banner_home.7bcd4735.webp);
    padding-bottom: 50%;
    /*height: 100vh;*/
    background-size: 100%;
    margin-bottom: -10%;
}

.banner-trapezium {
    height: 16rem;
    width: 100%;
}

.div-inline {
    overflow: auto
}

.h-style {
    margin-left: 10%;
}

.header-global {
    display: inline-flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: 1rem;
}

.header-margin-compensator {
    margin-top: -2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}

.header-content-negative-top-margin {
    margin-top: -5.5rem;
}

.btn-inspire-me{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
}

@media screen and (max-width:992px) {
    .banner-container {
        background-size: 130%;
        background-position: 100% 45%;
    }

    .header-global {
        margin-top: 0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    .header-margin-compensator {
        margin-top: -0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    .header-background-picture-border {
        background-color: white;
        margin-top: -2rem;
        content: '';
        border-radius: 50% 50% 0% 0% / 20% 20% 0% 0%;
        padding: 2rem;
    }

    .header-content-negative-top-margin {
        margin-top: -6rem;
        padding: 0 0.5rem;
    }

    .header-search-section-style {
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 3rem 0;
    }

    .header-formation-section-style {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .catchphrase-modules {
        margin: 0rem 1rem 2rem 1rem;
        flex-direction: column;
    }

    .catchphrase-modules>div {
        flex-basis: 100%;
    }

    .catchphrase-modules>div:nth-child(1) {
        order: 1;
    }

    .catchphrase-modules>div:nth-child(2) {
        order: 5;
    }

    .catchphrase-modules>div:nth-child(3) {
        order: 9;
    }

    .catchphrase-modules>div:nth-child(4) {
        order: 2;
    }

    .catchphrase-modules>div:nth-child(5) {
        order: 6;
    }

    .catchphrase-modules>div:nth-child(6) {
        order: 10;
    }

    .catchphrase-modules>div:nth-child(7) {
        order: 3;
    }

    .catchphrase-modules>div:nth-child(8) {
        order: 7;
    }

    .catchphrase-modules>div:nth-child(9) {
        order: 11;
    }

    .catchphrase-modules>div:nth-child(10) {
        order: 4;
    }

    .catchphrase-modules>div:nth-child(11) {
        order: 8;
    }

    .catchphrase-modules>div:nth-child(12) {
        order: 12;
    }

    .catchphrase-component {
        margin-top: -1rem;
    }

    .catchphrase-phrase {
        padding: 0.5rem;
    }

    .catchphrase-first {
        font-size: 1.5rem;
    }

    .catchphrase-text {
        font-size: 2rem;
    }

    .catchphrase-second {
        font-size: 1rem;
    }

    .banner-container {
        background-position: 0;
        background-size: 100%;
        background-repeat: no-repeat;
        height: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .btn-inspire-me{
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: white;
        justify-content: center;
        box-shadow: 0px -9px 15px -3px rgba(0,0,0,0.1);
        align-items: center;
        height: 4rem;
    }

    .btn-inspire-me button{
        justify-content: center;
        font-size: 1.1rem;
    }
}

@media screen and (max-width:560px) {
    .banner-container {
        background-size: 170%;
        background-position: 100% 45%;
    }
}
.sp-container-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.sp-container-text .sp-text-label {
    font-family: var(--font-family-mulish);
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
}

.sp-container-text .dip-tag {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.08rem;
}

.sp-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sp-selector-container button {
    display: flex;
    align-items: center;
    width: 30%;
    height: 11.68rem;
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sp-container-text div:nth-child(2) {
    text-align: right;
}

.sp-container-image {
    display: flex;
    width: 13rem;
    align-items: center;
}

.sp-selector-button>div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.sp-selector-button img {
    max-height: 7rem;
}

@media screen and (max-width:992px) {
    .sp-selector-container button {
        width: 44%;
    }

}

@media screen and (max-width:740px) {
    .sp-selector-container button {
        width: 100%;
    }
}
.domaine-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.domaine-selector-container button {
    width: 20%;
    margin: 0.5rem;
    padding: 0 0.5rem;
    height: 10rem;
    background-color: var(--whisper);
    justify-content: center;
    border-radius: 1rem;
}

.domaine-selector-button>div{
    display: flex;
    flex-direction: column;
}

.domaine-selector-button div:first-child {
    height: 5rem;
}

.domaine-selector-button img{
    max-height: 5rem;
}

@media screen and (max-width:992px) {
    .domaine-selector-container button {
        width: 44%;
    }
}
.slc-table {
    display: table;
    margin: auto;
}

.slc-img-container {
    width: 100%;
    height: 9rem;
    padding: 1rem 0.5rem;
    display: table-cell;
    vertical-align: middle;
}

.slc-img-container img {
    max-height: 7rem;
    max-width: 100%;
}

.slc-carousel {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.slc-carousel .p-carousel-item:not(.p-carousel-item-active) {
    width: 0;
}

.slc-carousel .p-items-hidden .p-carousel-item {
    visibility: visible;
}

.slc-carousel .p-carousel-prev.p-link {
    margin: 0.1rem;
}

.slc-carousel .p-carousel-next.p-link {
    margin: 0.1rem;
}

.slc-discover {
    visibility: visible;
    width: 100%;
    background-color: var(--terra-cotta);
    color: white;
    border-radius: 1rem;
    padding: 0.5rem 0.25rem;
    opacity: 1;
}

.slc-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0px;
    position: relative;
    background-color: white;
    margin: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.slc-item-container:hover {
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
    top: -6px;
    background-color: white;
    cursor: pointer;
}

.slc-item-container:hover:before {
    -webkit-transform: scale(2.15);
            transform: scale(2.15);
}

.slc-oe-item-container {
    display: flex;
    flex-direction: column;
    top: 0px;
    position: relative;
    background-color: white;
    margin: 1rem;
    text-decoration: none;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}


.slc-oe-item-container:hover {
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
    top: -6px;
    background-color: white;
    cursor: pointer;
}

.slc-oe-item-container:hover:before {
    -webkit-transform: scale(2.15);
            transform: scale(2.15);
}


.slc-container-img-max {
    height: 150px;
}

.slc-carousel .p-carousel-indicators {
    display: none;
}

.oe-titre {
    font-family: var(--font-family-mulish);
    font-weight: 700;
    font-size: 1rem;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oe-text {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 0.8rem;
    color: dimgray;
    height: 2.2rem;
}

.oe-container-text {
    width: 80%;
}

.oe-container-text-like {
    margin: 1.2rem;
    display: flex;
    justify-content: space-between;
}

.oe-container-text-like-button {
    margin-left: 1.2rem;
}

@media screen and (max-width:992px) {

    .p-carousel-items-container .p-items-hidden {
        -webkit-transform: none;
                transform: none;
    }

    .p-carousel-items-content {
        overflow: auto !important;
    }
}
.dialog-wrapper-content {
    margin-top: 5rem;
}

.p-dialog .p-dialog-header {
    padding: 1rem !important;
}

.container-dialog {
    width: 75%;
}

.dom-card-list .p-button {
    padding: 0 !important;
}

.dom-card-container p {
    font-weight: 900;
}

.btn-inspire-me.dip-only-pc button {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.btn-inspire-me.dip-only-pc button span {
    font-size: 2rem;
    font-weight: 700;
}

@media screen and (max-width:992px) {
    .dialog-wrapper-content h1 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .btn-inspire-me .p-button:not(a):not(.p-disabled):hover,
    .dialog-wrapper-content .p-button:not(a):not(.p-disabled):hover,
    .dialog-wrapper-content .p-selectbutton .p-button.p-highlight {
        background-color: var(--iris-100);
        color: white;
    }
}
.container-stepper {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
}

.p-dialog-header-icons {
  z-index: 999;
}

.stepper-active-1 li:nth-child(1)::before,
.stepper-active-2 li:nth-child(1)::before,
.stepper-active-2 li:nth-child(2)::before,
.stepper-active-2 li:nth-child(2)::after,
.stepper-active-3 li:nth-child(1)::before,
.stepper-active-3 li:nth-child(2)::before,
.stepper-active-3 li:nth-child(3)::before,
.stepper-active-3 li:nth-child(2)::after,
.stepper-active-3 li:nth-child(3)::after {
  border-color: var(--iris-100);
  background: var(--iris-100);
  color: white
}

.progressbar li {
  width: 30%;
  position: relative;
  text-align: center;
  list-style: none;
}

.progressbar {
  counter-reset: step;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li.active+li:before {
  border-color: var(--iris-100);
  background: var(--iris-100);
  color: white
}

.progressbar li.hide+li:before {
  border-color: #979797;
  background: #979797;
  color: white
}

@media screen and (max-width:992px) {
  .progressbar li {
    font-size: 0.8rem;
  }
}
.btn-domaines .p-button {
    border-radius: 1em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    flex: 24% 0;
}

.container-img-domaines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 5rem;
    height: auto;
}

.btn-domaines {
    display: flex;
    flex-wrap: wrap;
}

.dom-card-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dom-card-content {
    flex-grow: 1;
    text-align: center;
}

.dom-card-image {
    width: 5rem;
}

.dom-card-image img {
    width: 4rem;
    height: auto;
}


.dom-card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:1160px) {
    .btn-domaines {
        flex-wrap: nowrap;
        flex-direction: column;
        display: inline-flex;
        width: 100%;
    }

    .dom-card-image {
        width: 5rem;
    }

    .dom-card-image img {
        width: 3rem;
    }

    .dom-card-container {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 0.5rem;
    }

    .dom-card-content p {
        font-size: 1.12rem;
    }

    .dom-card-content {
        flex-grow: 0;
        text-align: left;
    }
}

.im-txt-bold {
    font-weight: 700;
    color: black;
}

.container-global-domaines .field-checkbox {
    border-radius: 0.5em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    padding: 1rem 1rem;
    margin-left: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.container-global-domaines .field-checkbox .p-checkbox .p-checkbox-box.p-highlight {
    border-color: green !important;
    background: green !important;
}

.container-global-domaines .field-checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: white;
}

.container-global-domaines .field-checkbox label {
    margin-left: 1rem;
}

.btn-diplome .p-button {
    border-radius: 1em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    padding: 0.2rem 1rem;
}

.btn-diplome {
    display: flex;
    justify-content: space-evenly;
}


@media screen and (max-width:992px) {

    .btn-diplome {
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .container-global-domaines .field-checkbox {
        padding: 1rem 0.5rem;
        margin-left: 0rem !important; 
        margin-bottom: 0.8rem !important;
    }

    .container-global-domaines .field-checkbox label {
        margin-left: 0.5rem;
    }
}
.select-button-centering {
   text-align: center;
   margin-bottom: 1rem;
}

.view-formation-action-buttons {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   position: absolute;
   right: 1rem;
}

.vf-panel-title .p-tabview-nav {
   font-family: var(--font-family-mulish);
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 25px;
   text-align: center;
   letter-spacing: 0.747641px;
}

.fomation-mention-parcours {
   align-self: center;
}

.cards-override-picture {
   margin-top: -12rem;
}

.p-scrollpanel.custombar {
   width: 100%;
   height: 8em;
}

.p-scrollpanel.custombar .p-scrollpanel-bar {
   background-color: var(--opal-light);
   opacity: 1;
   transition: background-color .2s;
}

.p-scrollpanel.custombar .p-scrollpanel-content {
   overflow-x: auto;
}


.tuile-formation .p-carousel-item:not(.p-carousel-item-active) {
   width: 0px !important;
   height: 0px !important;
}

.accordion-for-formation {
   margin-bottom: 1rem;
   font-family: var(--font-family-mulish);
   font-weight: 600;
}

.accordion-for-formation .p-accordion-header .p-accordion-header-link {
   background-color: #ffffff;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.accordion-for-formation .p-accordion-header.p-highlight {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: #ffffff;

}

.accordion-for-formation .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: #ffffff;
   border-color: transparent;
}

.accordion-for-formation .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid black;
   color: black;
}


.accordion-for-formation .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid black;
   color: black;
}

.accordion-for-formation .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   overflow: auto;
}


.accordion-for-formation .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: white;
}

.accordion-for-formation .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: white;
}

.select-button-skills .p-button.p-highlight {
   background-color: var(--opal-light);
}

.select-button-skills .p-button.p-highlight:hover {
   background-color: var(--opal-light);
}

.select-button-skills .p-button {
   border-radius: 2rem;
}


.no-padding .p-card-body {
   padding: 0%;
}

.like-button-formation {
   text-align: right;
   padding: 0.5rem;
}

.formation-content {
   padding: 1rem;
}

.formation-global {
   display: flex;
   flex-direction: column;
   overflow-x: hidden;
   margin: -2rem;
   background-color: white;
}

.view-formation-pc-display {
   display: block;
}

.view-formation-mobile-display {
   display: none;
}

@media screen and (max-width:992px) {
   .formation-content {
      padding-left: 1rem;
      padding-right: 1rem;
   }

   .cards-override-picture {
      margin-top: -12rem;
   }

   .formation-global {
      margin: -0.5rem;
   }

   .p-scrollpanel.custombar {
      height: auto;
   }

   .view-formation-pc-display {
      display: none;
   }

   .view-formation-mobile-display {
      display: block;
   }
}
.wysiwyg-mobile-renderer{
    display: none;
}

.wysiwyg-pc-renderer{
    display: block;
}
@media screen and (max-width:992px) {
    .wysiwyg-mobile-renderer{
        display: block;
    }
    
    .wysiwyg-pc-renderer{
        display: none;
    }
}
.fh-margin-compensator{
    margin:-1rem -1rem 0 -1rem;
    padding: 5rem 1rem;
}

.fh-card-header  {
    background-color: var(--whisper);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: var(--font-family-poppins);
}


.fh-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.fh-card-title h1 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 700;
    font-family: var(--font-family-mulish);
}
.fh-card-title h2 {
    margin: 0;
    font-weight: 400;
}

.fh-card-mention {
    color: var(--indigo-dye);
    font-weight: 500;
}

.fh-card-parcours {
    font-weight: 400;
    color: var(--indigo-dye);
}

.item-centering {
    text-align: center;
    align-self: center;
    justify-content: center;
}


.div-image-resized {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.div-image-resized img {
    height: 6rem;
    max-width: 100%;
}

.fh-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.fh-logo-container {
    display: table;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    /* height: 6.8rem; */
    background-color: white;
    flex: 20% 0;
}

.fh-info-container{
    flex: 80% 1;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
}

.fh-tags-container>span{
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.fh-logo-sub-container{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding:0.2rem;
}

.fh-campus-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
}

.fh-campus-container .p-inputtext {
    padding: 0 0.5rem 0 0;
}

.fh-campus-container .p-dropdown {
    border: none !important;
    background-color: var(--whisper);
    color: black;
}

.fh-campus-container img {
    width: 1.5rem;
}

.formation-header-action-buttons {
   display: flex;
   flex-direction: column;
}

.formation-header-action-buttons>div,
.formation-header-action-buttons a,
.formation-header-action-buttons .p-button
{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.formation-header-action-buttons .p-button{
    padding: 0.5rem;
    white-space: nowrap;

}

.primary-color-border {
    border: solid 1px var(--primary-color);
 }

@media screen and (max-width:992px) {
    .fh-margin-compensator{
        padding: 2rem 1rem;
    }

    .fh-card-title h1 {
        font-size: 1.7rem;
    }
    .fh-card-title h2 {
        font-size: 1.4rem;
    }

    .fh-card-title {
        font-size: 1.7rem;
    }
    .fh-info-container{
        padding-top: 0.5rem;
        padding-left: 0rem;
    }
}

@media screen and (max-width:992px) {
    .lf-heart-container.p-button {
        margin-top: 1rem;
    }
}
.pop-container{
    color:var(--resolution-blue);
    width: 20rem;
    background: var(--periwinkle-blue);
}

.pop-container::before{
    border-bottom-color: var(--periwinkle-blue);
}

.pop-multiselect{
    width:-webkit-fill-available
}
.fcc-main-container{
    background-color: var(--link-water);
    margin: 0 -1.5rem;
    padding: 0.5rem 1.5rem;
    font-family: var(--font-family-poppins);
}
.title-offer{
    font-weight: 500;
}


.max-lines {
    display: inline-block;
     text-overflow: ellipsis;
     word-wrap: break-word;
     overflow: hidden;
     max-height: 7rem;
     line-height: 1rem;
}

.published-date-style{
    font-style: italic;
}

.logo-max-size{
    max-height: 80px;
    max-width: 100%;
}

.p-card.p-component{
border-radius: 1rem;
}

.oc-no-padding .p-card-content{
     padding: 0 !important;
}

.container-min-height{
    min-height: 7rem;
}

@media screen and (max-width:992px) {
    .container-min-height{
        min-height: 0rem;
    }
}

.contrat-type-style{
    color: var(--primary-color);
    font-weight: 500;
}
.loader-height-for-thumbnail-list{
    height: 34rem;
}

.search-candidat-infomessage{
    position : absolute;
    pointer-events: none;
    margin-top: 12rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.search-candidat-fake-display{
    -webkit-filter : blur(0.5rem);
            filter : blur(0.5rem);
    pointer-events: none;
}

a.link-search-candidat-fake-display{
    color: #000;
}

a.link-search-candidat-fake-display:hover{
    color: #000;
}
.ct-oe-item-container {
    top: 0px;
    position: relative;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.ct-oe-item-container:hover{
    top: -6px;
    cursor: pointer;
}

.ct-contrats{
    height : 6rem;
    overflow-y: hidden;
}

.ct-oe-item-container:hover .ct-box-container{
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
}

.ct-oe-item-container:hover:before .ct-box-container{
    -webkit-transform: scale(2.15);
            transform: scale(2.15);
}

.ct-titre {
    font-family: var(--font-family-mulish);
    font-weight: 700;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}


.ct-box-container {
    padding: 4rem 1rem 1rem 1rem;
    margin: -4rem 0.5rem 1rem 0.5rem;
    height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: white;
}

.ct-profile-image {
    display: flex;
    margin: 0 1.5rem;
    justify-content: space-between;
}

.ct-profile-image img {
    object-fit: cover;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    height: 7rem;
    width: 7rem;

}

.ct-profile-image .p-avatar {
    height: 7rem;
    width: 7rem;
    border-radius: 7px;
    border: 3px solid #E6E6E6;
}

.ct-profile-image .p-avatar .p-avatar-icon {
    font-size: 3.2rem;
}

.ct-text {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 0.8rem;
    color: dimgray;
}

.ct-mobility {
    height: 5.5rem;
}

.ct-like-container{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem;
}
.p-fluid .p-input-icon-left.dt-fd-search-style{
    width:15rem;
}
.mst-icon{
    color: var(--indigo-dye);
}
.fd-red-tab.p-accordion .p-accordion-header .p-accordion-header-link{
    background-color: #f1aca1;
}

.p-dialog-footer .fd-footer-content {
    padding-top: 1.2rem;
}
.field_margin {
    margin: 2rem;
    width: 25rem;
}

.field_margin-right {
    margin: 20rem;
}

.field-checkbox {
    margin-right: 1em;
}

.label-align-margin-right{
    align-self: center;
    margin-right: 1rem;
}

.fsp-main-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.fsp-main-title h1{
    margin-top: 0;
}

.fsp-main-title .fsp-visualize-profil.p-button:first-child{
    background-color: var(--iris-100);
    border: 1px solid var(--iris-100);
    color: var(--white);
    padding: 0.5rem;
}

.fsp-ellipsis.p-button.p-button-text{
    color: black;
}

.fsp-ellipsis.p-button.p-button-text:not(a):not(.p-disabled):active{
    background: none;
    color: black;
}

.fsp-ellipsis.p-button.p-button-text:not(a):not(.p-disabled):hover{
    background: none;
    color: black;
}

.fsp-main h1{
    color:var(--indigo-dye);
    font-size: 1.5rem;
    margin-top: 0;
}

.fsp-loading{
    text-align:center;
    margin-top: 10rem;
}

.fsp-disabled{
    pointer-events: none;
    opacity: 0.5;
}

.fcp-file-container-style{
    display: flex;
    justify-content: flex-start;
}

.fsp-tabview.p-tabview .p-tabview-panels{
    padding: 1rem 0;
}

.fsp-tabview .p-tabview-nav{
    justify-content: space-between;
}

.fsp-tabview.p-tabview .p-tabview-nav li{
    background-color: var(--last-default);
    flex: 20% 1;
}

.fsp-tabview .p-tabview-title{
    text-align: center;
}

.fsp-tabview.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    justify-content: center;
    margin: auto;
}

.fsp-tabview.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-bottom: 4px solid;
}

.fsp-tabview.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus{
    box-shadow: none;
}

.fcp-card-container{
    padding: 1rem 4rem;
    border-radius: 10px;
}

.fcp-right-container{
    width: 75%
}

@media screen and (max-width:992px) {
    .fsp-main-title{
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .fcp-file-container-style{
        justify-content: center;
    }

    .fsp-tabview.p-tabview .p-tabview-nav li{
        flex:auto;
    }

    .fsp-tabview .p-tabview-nav{
        width: auto;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
     }

     .fsp-tabview .p-tabview-title{
        text-align: center;
        white-space: nowrap;
    }

    .fsp-main-title > div:nth-child(2){
        text-align: right;
    }

    .fcp-card-container{
        padding: 1rem;
    }
    
    .fcp-right-container{
        width: 100%
    }
}
.uploader-custom-template .p-fileupload-content{
    display: none;
}

.flavor-picture-style{
    height: 6rem;
}

.flavor-text-style{
    height: 5.6rem;
    width: 7rem;
    display: table-cell;
    border: 1px solid black;
    border-radius: 1rem;
    justify-content: center;
    text-align: center;
    padding: 0.2rem;
    vertical-align:middle;
    margin: 0.2rem 0rem;
}

.flavor-picture-style img{
    height: 5.5rem;
    width: auto;
}

.waiting-for-validation{
    padding:0.1rem;
    color:var(--terra-cotta);
}

.fu-button-cv{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
    border: 1px solid;
}

.send-email-validation-btn{
    display: flex;
    justify-content: center;
    width : 100%;
}

.delete-langue-btn{
    text-align: center;
}

.delete-langue-btn .p-button.p-button-icon-only {
    height:2.5rem;
    width: 2.5rem;
}

.add-langue-btn{
    display: flex;
    align-items: center;
}

.info-langue-display{
    margin-bottom: 0.5rem;
}

.info-niveau-langue-display{
    margin-bottom: 0.5rem;
}

.personnal-info-file-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personnal-info-subtitle{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    color: #333333;
}

.personnal-info-file-container > div{
    padding : 0.1rem;
}

.paragraphe-aide-profil-etudiant {
    font-style: italic;
}

.pif-global-input-container{
    margin-bottom: 2rem;
}

.pif-input-container .p-inputtext{
    padding: 1rem;
}

.pif-wysiwyg-container{
    margin: 1rem 0;
}

.pif-radio-button.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
    background-color: transparent;
}

.pif-radio-button.p-radiobutton .p-radiobutton-box.p-highlight{
    border-color: #E0E0E0;
    background: var(--iris-100);
}

@media screen and (max-width:992px) {
    .info-niveau-langue-display{
        display: none;
    }
    .responsive-langue-display{
        margin-bottom: 1rem;
    }
    .send-email-validation-btn{
        margin-top: 0rem;
    }
}
.fnb-container{
    z-index: 1;
}

.fnb-invisible{
    visibility: hidden;
}

.fnb-navigation.p-button{
    color: var(--iris-100);
    border:solid 1px var(--iris-100);
    border-radius: 2.3rem;
}

.fnb-navigation-left.p-button{
    margin-right: 2rem;
}

.fnb-navigation-right.p-button{
    margin-left: 2rem;
}

.fnb-save.p-button{
    background-color: var(--iris-100);
}

.fnb-pc.p-button{
    display: inline-flex;
    border-radius: 2.3rem;
}

.fnb-mobile.p-button{
    display: none;
}

@media screen and (max-width:992px) {
    .fnb-navigation.p-button .p-button-label{
        display: none;
    }

    .fnb-pc.p-button{
        display: none;
    }

    .fnb-navigation.p-button{
        color: var(--iris-100);
        border:solid 1px var(--iris-100);
        border-radius: 50%;
        padding: 1rem;
    }
    
    .fnb-mobile.p-button{
        display: inline-flex;
    }
    .fnb-mobile.p-button.p-button-icon-only.p-button-rounded{
        height: 3.5rem;
        width: 3.5rem;
    }

}
.formation-list-item-fs-main-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}

.formation-list-item-fs-formation-container{
    display: flex;
    flex-direction: row;
}

.formation-list-item-fs-formation-container>div{
    padding: 0.5rem;
}

.formation-list-item-fs-maformationin-container>div:first-child{
    display: flex;
    width: 8rem;
    height: auto;
    align-items: center;
    justify-content: center;
}

.formation-list-item-fs-formation-container>div:first-child img{
    display: flex;
    width: 7rem;
    height: auto;
    max-height: 4.6rem;
}

.formation-list-item-fs-composante{
    font-weight: 500;
}


.formation-list-item-fs-obtention{
    font-style: italic;
}

.formation-list-item-fs-buttons{
    justify-content: flex-end;
    flex:none;
}

@media screen and (max-width:992px) {
    .formation-list-item-fs-main-container{
        flex-direction: column;
    }
    .formation-list-item-fs-formation-container{
        flex-direction: column;
    }
    .formation-list-item-fs-formation-container>div:first-child{
        align-self: center;
        width: auto;
        height: 5.6rem;
    }
    .formation-list-item-fs-formation-container>div:first-child img{
        height: 4.6rem;
        width: auto;
    }
    .formation-list-item-fs-buttons{
        text-align: right;
    }
}
.disabled-label{
    color:var(--grey-disabled)
}

.year-dropdown-style{
    max-width:16rem
}

.p-formgrid.p-grid .p-col-12.delete-button-container {
    text-align: right;
    padding-top: 1.7rem;
}

.formation-obtention-container{
    display: flex;
    flex-direction: row;
}

.formation-obtention-align{
    padding-top: 1.7rem;
}

.statut-panel-style{
    min-height: 12rem;
}

@media screen and (max-width:992px){
    .p-formgrid.p-grid .p-col-12.delete-button-container{
        padding-top: 1rem;
        text-align: center;
    }
    .formation-obtention-container{
        flex-direction: column;
    }
    .year-dropdown-style{
        max-width:100%
    }

    .formation-obtention-align{
        padding-top: 0rem;
    }
}
.form-course-pop-up{
    width:80vw;
}

.form-course-pop-up .p-dialog-content{
    overflow-y:visible;
}

.fcpu-btn-continue{
    padding-top: 0.5rem;
}

.fcpu-btn-continue .p-button{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
    border: 1px solid;
}


.fscpu-btn-cancel{
    text-align: end;
}

.fscpu-btn-cancel .p-button{
    width: auto;
    color: var(--iris-100);
    border: 1px solid var(--iris-100);
}

.fscpu-btn-submit .p-button{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
}

.fscpu-btn-submit-invalid .p-button{
    width: auto;
    color: var(--white);
    background-color: grey;
}

@media screen and (max-width:992px) {
    .form-course-pop-up{
        width:100vw;
    }

    .fscpu-btn-cancel{
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .fscpu-btn-submit{
        text-align: center;
    }
    .fscpu-btn-submit-invalid{
        text-align: center;
    }
}


.add-formation-btn{
    display: flex;
    align-items: center;
}

.studies-main-title{
    margin:0;
    margin-bottom:0.5rem;
}

.studies-input-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    max-width: 22rem;
}

.studies-input-container .p-inputtext{
    padding: 1.35rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.studies-formation-desc{
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.125rem;
}

.studies-formation-attention{
    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 1.06rem;
    line-height: 1.25rem;
    color: var(--iris-100)
}
.sp-checkbox-label{
    font-size: 1.1rem;
}
.alternance-container{
    margin-top: 0.5rem;
}

.active-search-tree-select.p-treeselect.p-treeselect-chip .p-treeselect-token{
    background: var(--terra-cotta);
    color: white;
    border-radius: 3px;
}

.active-search-multi-select.p-multiselect.p-multiselect-chip .p-multiselect-token{
    background: var(--terra-cotta);
    color: white;
}

.active-search-multi-select-panel .p-multiselect-item{
    white-space: normal;
}

.recherche-active-disabled-label{
    color:var(--grey-disabled)
}

.recherche-active-soon-container{
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.as-div-container{
    padding: 0 2rem;
}

.cpms-filter-button-position{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cpms-filter-button-container{
    padding: 0.2rem;
}

.cpms-button-style.p-button{
    border: 1px solid var(--midnight-express);
    color: var(--midnight-express);
    border-radius: 0.6rem;
    padding: 0.2rem 0.8rem;
    width: auto;
    margin: 0;
}

.as-title-grid-height{
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
}

@media screen and (max-width:992px){
    .alternance-container{
        margin-top: 0rem;
    }

    .as-div-container{
        padding: 0 3rem;
    }

    .as-title-grid-height{
        display: flex;
        justify-content: space-around;
        text-align: center;
    }
}

.cs-caption-formation-desc{
    color: var(--iris-100);
}

.cs-multi-select-competences{
    width: 90%;
}

.cs-multi-select-competences.p-multiselect.p-multiselect-chip .p-multiselect-token{
    border: 1px solid var(--midnight-express);
    color: var(--midnight-express);
    background: white;
    border-radius: 0.8rem;
    padding: 0.2rem 0.8rem;
}

.cs-multi-select-competences-panel.p-multiselect-panel .p-multiselect-item.p-highlight{
    max-width: -webkit-fill-available;
    white-space: pre-wrap;    
}

.cs-multi-select-competences-panel.p-multiselect-panel .p-multiselect-item{
    font-size: 1rem;
}
.ra-button-seemore.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
    color: black;
    border-color: var(--primary-color);
}

.refined-search-main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.refined-search-title-container {
    display: flex;
    align-items: center;
}

.refined-search-lower-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.refined-search-filters-container {
    padding: 0.5rem;
    flex-basis: 30%;
    width: 25%;
    margin-top: 4rem;
}

.refined-search-filters-center {
    align-self: center;
}

.refined-search-right-container {
    flex-basis: 70%;
}

.refined-search-results-container-filters {
    padding: 0.5rem;
    flex-basis: 70%;
}

.refined-btn-goback {
    width: 37%;
}

.column-search-item {
    width: 50%;
}

.refined-search-results-container-filters .container-for-horizontal-display {
    display: flex;
    flex-wrap: wrap;
}

.refined-search-results-container-filters{
    padding: 0.5rem;
    justify-self: flex-end;
    flex-grow: 1;

}

.fake-candidat {
    -webkit-filter : blur(0.5rem);
            filter : blur(0.5rem);
    pointer-events: none;
}

.refined-search-nb-result-and-order-container {
    padding: 0.5rem;
    flex-basis: 70%;
}

.refined-search-float-button {
    display: none;
}

.search-candidat-infomessage-filter{
    position : absolute;
    pointer-events: none;
    margin-top: 12rem;
    margin-left: 5rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    z-index: 1;
}

/* PaginatorDemo.css */

.paginator-demo .image-gallery {
    text-align: center;
    padding: 1rem;
}


@media screen and (max-width:992px) {
    .refined-search-title-container {
        display: flex;
        flex-direction: column;
    }

    .refined-btn-goback {
        width: 100%;
    }

    .refined-search-lower-container>div:nth-child(1) {
        order: 2;
    }

    .refined-search-lower-container>div:nth-child(2) {
        order: 1;
    }

    .refined-search-lower-container>div:nth-child(3) {
        order: 3;
    }

    .refined-search-lower-container {
        flex-direction: column;
    }

    .refined-search-filters-container {
        display: none;
    }

    .refined-search-right-container {
        flex-basis: 100%;
    }

    .refined-search-results-container-filters {
        padding: 0;
        min-height: 0;
        margin-left: 0.3rem;
        flex-basis: 100%;
        justify-self: flex-end;
    }

    .refined-search-nb-result-and-order-container {
        padding: 0.5rem;
        flex-basis: 100%;
    }

    .refined-search-float-button {
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0rem;
        padding: 0.7rem;
    }

    .refined-search-float-button .p-button {
        border: 2px solid var(--indigo-dye);
        border-radius: 2rem;
    }
}

@media screen and (max-width:750px) {
    .column-search-item {
        width: 100%;
    }

    .refined-btn-goback {
        width: 100%;
    }
}
.tab-main-title-filter {
   font-size: 1.6rem;
   font-weight: 700;
   line-height: 1.8rem;
   text-indent: 0.1rem;
   background: var(--white);
   padding: 0.3rem 0rem;
}

.msf-tab-title-filter {
   display: flex;
   font-weight: 700;
   font-size: 1rem;
}

.msf-accordion {
   box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
   border-radius: 0.6rem;
   background: var(--white);
   padding: 1.5rem;
}

.msf-accordion .p-accordion-content {
   padding: 0rem;
}

.msf-accordion .p-accordion-content .p-accordion-content {
   padding: 1rem;
}

.msf-accordion .msf-erase-filters {
   color: var(--white);
   background: var(--iris-100);
   padding: 0.4rem 0.6rem;
   border-radius: 0.4rem;
   margin: 1rem 0rem;
}

.msf-otheroptions {
   padding: 1rem 0.5rem;
}

.msf-otheroptions-line {
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   border-bottom: 1px solid #ADADAD;
}

.msf-otheroptions-borderless-line-various-filter {
   display: flex;
   padding: 0.5rem;
   align-items: center;
}

.msf-otheroptions-borderless-line-various-filter .p-checkbox-box.p-highlight {
   background: #696969;
   border: 1px solid #545454;
}

.msf-otheroptions-borderless-line-various-filter .p-checkbox .p-checkbox-box .p-checkbox-icon {
   color: transparent;
}

.msf-checkbox-various-filter {
   margin-right: 0.5rem;
}

.msf-checkbox-various-filter.p-checkbox .p-checkbox-box.p-highlight {
   border-color: green;
   background: green;
}

.msf-checkbox-various-filter.p-checkbox .p-checkbox-box.p-highlight:hover {
   border-color: green;
   background: green;
}


.msf-checkbox-various-filter.p-checkbox .p-checkbox-box .p-checkbox-icon {
   color: white;
}

.msf-input-switch.p-inputswitch {
   height: 1rem;
   width: 2.3rem;
}

.msf-input-switch.p-inputswitch .p-inputswitch-slider::before {
   width: 0.9rem;
   height: 0.9rem;
   margin-top: -0.45rem;
   left: 0.1rem
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
   background: var(--terra-cotta);
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
   background: var(--terra-cotta);
}

.msf-accordion .p-accordion-header.p-highlight {
   border-radius: 0.5rem;
   background-color: var(--opal-light);

}

.msf-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
   border-color: transparent;
}

.msf-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 0.2em solid var(--indigo-dye);
   background-color: var(--white);
}

.msf-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   /* border-color: #dee2e6; */
   border-bottom: 0.2em solid var(--linkedin-blue);
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: var(--white);
}

.msf-sub-accordion.p-accordion.p-accordion-tab-active .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-bottom: transparent;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   /* border-bottom: var(--indigo-dye) 1px solid; */
   border-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content {
   border-bottom: 1px solid #ADADAD;
   border-bottom-left-radius: 0rem;
   border-bottom-right-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-header .p-accordion-header-link {
   padding: 1rem 0.5rem;
}

.msf-sub-accordion .p-accordion-header-text {
   width: -webkit-fill-available;
}

.msf-sub-accordion .p-tag {
   background: var(--indigo-dye);
   color: white;
   border-radius: 1rem;
   font-weight: 500;
}

.msf-selectbutton.p-selectbutton .p-button.p-highlight {
   background: var(--iris-100);
}

.msf-selectbutton.p-buttonset .p-button:first-of-type {
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content .msf-selectbutton {
   display: block;
}

.msf-selectbutton.p-buttonset .p-button {
   padding: 0.2rem 0.8rem;
}

.msf-selectbutton .p-button.p-highlight:hover {
   color: white;
   border-color: #ced4da;
}

.msf-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
   background: #e9ecef;
}

.msf-selectbutton.p-buttonset .p-button:last-of-type {
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-selectbutton.p-buttonset.msf-level-diplom .p-button:last-of-type {
   padding: 0.5rem 1.4rem;
}

/* diplomeMultiSelect */
.msf-diplome-multiselect .p-multiselect {
   display: flex;
   padding: 0.5rem;
   border: 1px solid var(--surface-300);
   box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
}

.msf-diplome-multiselect .p-multiselect-label-container {
   padding: 0.2rem;
}

.msf-diplome-multiselect-company .p-multiselect.p-multiselect-chip .p-multiselect-token{
   background: var(--iris-100);
   color: white;
}

.dms-filter-item .dmp-button-style {
   border: 1px solid var(--midnight-express);
   color: var(--midnight-express);
   border-radius: 0.6rem;
   padding: 0.2rem 0.8rem;
   margin-right: 0.5rem;
   margin-bottom: 0.5rem;
}

/* /diplomeMultiSelect */

.msf-tab-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.msf-tab-subheader {
   display: flex;
}

.msf-tooltip {
   display: flex;
   align-self: center;
}

.msf-slider {
   margin: 0rem 2rem;
}

@media screen and (max-width:992px) {

   .msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
      background: white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ADADAD;
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
   }
}
.tab-main-title{
   display: flex;
   justify-content: center;
   font-size: 1.4rem;
   padding-top: 0.3rem;
   padding-bottom: 0.3rem;
   font-weight: 400;
}

.msf-tab-title{
    font-weight: 500;
    display: flex;
}

.msf-accordion .p-accordion-content{
   padding: 0rem;
}

.msf-accordion .p-accordion-content .p-accordion-content{
   padding: 1rem;
}

.msf-otheroptions{
   padding: 1rem 0.5rem;
}

.msf-otheroptions-line{
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   border-bottom: 1px solid #ADADAD;
   background-color: var(--white);
}

.msf-otheroptions-borderless-line{
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   background-color: var(--white);
}

.msf-input-switch.p-inputswitch{
   height: 1rem;
   width: 2.3rem;
}

.msf-input-switch.p-inputswitch .p-inputswitch-slider::before{
   width: 0.9rem;
   height: 0.9rem;
   margin-top: -0.45rem;
   left : 0.1rem
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
   background: var(--terra-cotta);
}

.msf-accordion .p-accordion-header.p-highlight{
   border-radius: 0.5rem;
    background-color: var(--white);
    
 }
 .msf-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border-top-right-radius : 0.5rem;
    border-top-left-radius : 0.5rem;
    background-color: var(--white);
    border-color: transparent;
 }
 .msf-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    /* border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
    border-color: #dee2e6;
    border-bottom:  0.1em solid transparent;
    background-color: var(--white);
 }
 
 .msf-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    /* border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6; */
    /* border-color: #dee2e6; */
    border-bottom:  0.1em solid transparent;
    background-color: var(--white);
 }
 .msf-accordion .p-accordion-tab .p-accordion-content{
    border-bottom-right-radius : 0.5rem;
    border-bottom-left-radius : 0.5rem;
    background-color: var(--white);
 }
 
 .msf-accordion .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border: 1px solid transparent;
    border-top-right-radius : 0.5rem;
    border-top-left-radius : 0.5rem;
    background-color: var(--white);
 }
 .msf-accordion .p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    background-color: var(--white);
 }

 .msf-sub-accordion.p-accordion.p-accordion-tab-active .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
   border-bottom: transparent;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
   border: 1px solid transparent;
   border-bottom: 1px solid #ADADAD;
   border-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content {
   border: 1px solid transparent;
   border-bottom-left-radius: 0rem;
   border-bottom-right-radius:  0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
   padding: 1rem 0.5rem;
}

.msf-sub-accordion .p-accordion-header-text{
   width: -webkit-fill-available;
}

.msf-sub-accordion .p-tag{
   background: var(--indigo-dye);
   color: white;
   border-radius: 1rem;
   font-weight: 500;
}

.msf-selectbutton.p-selectbutton .p-button.p-highlight{
   background: var(--iris-100);
}

.msf-selectbutton.p-buttonset .p-button:first-of-type{
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type){
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-selectbutton.p-buttonset .p-button{
   padding: 0.2rem 0.8rem;
}

.msf-selectbutton .p-button.p-highlight:hover{
   color: white;
   border-color: #ced4da;
}

.msf-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
   background: #e9ecef;
}

.msf-selectbutton.p-buttonset .p-button:last-of-type{
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom:0.1rem;
}

.msf-input input{
   border-radius: 2rem;
   border: var(--indigo-dye) 2px solid;
   padding: 0.2rem 1rem
}

.msf-tab-header{
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.msf-tab-subheader{
   display: flex;
}

.msf-tooltip{
   display: flex;
   align-self: center;
}

@media screen and (max-width:992px) {

    .msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
       background: white;
    }
}
.ler-button:hover{
    background-color: var(--terra-cotta);
}
.ler-button:focus{
    background-color: var(--terra-cotta);
}
.ler-chip{
    background-color: var(--alabaster);
}

.ler-autcomplete.p-autocomplete input{
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    margin-bottom: 2rem;
    border: 1px solid var(--surface-300);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
}

.ler-autcomplete-panel{
    max-width:-webkit-fill-available;
}

.ler-autcomplete-panel li{
    max-width: -webkit-fill-available;
}

.ler-item{
    max-width:-webkit-fill-available;
    white-space: pre-wrap;
}

.ler-delete-all.p-button{
    border-radius: 2rem;
    color: var(--terra-cotta);
    padding: 0.2rem 1rem;
    border: 1px solid var(--terra-cotta);
    margin-top: 1rem;
}

.ler-filter-item{
    display: flex;
    align-items: center;
}

.ler-filter-item-value{
    padding: 0.2rem;
}

.ler-autocomplete-container{
    margin-bottom: 1rem;
}

.ler-autocomplete-icon{
    margin: 0rem;
    z-index: 1;
    position: relative;
    margin-top: -4.3rem;
    margin-left: 88%;
}

.lerav-chip-exige{
    background-color: var(--terra-cotta);
}
.lerav-chip-souhaite{
    background-color: var(--alabaster);
}

.lerav-chip-exige > div:first-child, .lerav-chip-souhaite > div:first-child{
    display: inline-flex;
}

.lerav-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lerav-subline{
    display: flex;
    align-items: center;
}

.lerav-button{
    width: -webkit-min-content;
    width: min-content;
}

.lerav-exige{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lerav-exige>span{
    font-size: 0.9rem;
}
.le-input.p-inputgroup input {
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    border: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.le-input.p-inputgroup button{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid var(--iris-100);
}

.le-input{
    border: 1px solid var(--surface-300);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
}

.nnrao-results-text .p-tag .p-tag-value+span {
    font-family: var(--font-family-mulish);
    font-size: 1.5rem;
    font-weight: 400;
    background-color: var(--whisper);
}

.nnrao-results-text .p-tag {
    background-color: var(--whisper);
}

.nnrao-tri div b {
    color: var(--iris-100);
}

.nnrao-tri .p-dropdown {
    background-color: #fff;
    border: 1px solid var(--alto);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: var(--font-family-poppins);
}

.nnrao-tri .p-inputtext {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 1rem;
}
.mpsf-header{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    padding: 0rem 1rem;
}

.mpsf-header-main-title{
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
}

.mpsf-header .mpsf-erase-filters{
    color: var(--white);
    background: var(--iris-100);
    padding: 0.4rem 0.6rem;
    border-radius: 0.4rem;
    margin-top: 1rem;
}

.mpsf-dialog{
    width: 100vw
}

.mpsf-dialog.p-dialog .p-dialog-content{
    padding: 0rem;
}

.mpsf-dialog.p-dialog .p-dialog-header{
    padding: 0.5rem;
    border-bottom: var(--indigo-dye) 2px solid;
}

.mpsf-button .p-button-label{
        font-size: 0.8rem;
}

.refined-search-float-button .p-button-label{
    color: var(--indigo-dye);
}

.mspf-button-label .p-tag{
    border-radius: 1rem;
    background-color: var(--indigo-dye);
    color:white;
}
ul{
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

img.thumbnail{
    height: 0.75rem;
    width: 0.75rem;
    align-self: center;
    margin-right: 0.5rem;
} 

.margin-formation-campus{
    margin: 0.5rem 1rem;
    background-color: white;
    border-radius: 0.6rem;
}

.check-button{
    border: solid 1px var(--primary-color);
    padding: 0.25rem 0.5rem;
    font-size: small;
 }

 .check-button i{
    font-size: 1em;
 }

 .check-button > label{
     display: flex;
     flex-direction: row;
     align-items: center;
     font-weight: 500;
 }

.label-list-margin-left{
    margin-left: 0.5rem;
}
.label-list{
    font-weight: 500;
}

.p-divider.p-divider-horizontal {
    border-top: 1px var(--indigo-dye);
}



/* Fix les items non affiché précédent et suivant qui prennent de la place dans le carousel*/
/*begin*/
.formation-carousel .p-carousel-item:not(.p-carousel-item-active){
    width: 0;
}
.formation-carousel .p-items-hidden .p-carousel-item{
    visibility: visible;
}
/*end*/

.formation-carousel .p-carousel-prev.p-link{
    margin:0.1rem;
}
.formation-carousel .p-carousel-next.p-link{
    margin:0.1rem;
}

.p-carousel-prev.p-link.p-disabled{
    visibility:hidden;
    
}
.p-carousel-next.p-link.p-disabled{
    visibility:hidden;
}

/* HORIZONTAL */

.formation-thumbnail-grid-horizontal{
    display: flex;
    flex-direction: row;
    width:100%;
    padding:0.5rem;
 }

 .formation-thumbnail-grid-horizontal-min-height{
    min-height: 10rem;
 }

 .formation-thumbnail-grid-horizontal .formation-logo-container{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    padding:0.5rem;
}

.formation-thumbnail-grid-horizontal .formation-logo-container img{
    width: 9rem;
    height: auto;
}

.formation-school-container{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    font-size: 0.9rem;
    padding:0.5rem;
    flex-basis: 35%;
    flex-grow: 1;
}

.indigo-dye-title{
    margin:0;
    font-size: small;
    font-weight: 500;
    color : var(--indigo-dye);
    padding: 0.3rem 0rem;
}

.indigo-dye-light-title{
    margin:0;
    font-size: small;
    font-weight: 400;
    color : var(--indigo-dye);
    padding: 0.3rem 0rem;
}



.light-title{
    margin:0;
    font-size: small;
    font-weight: 400;
    padding: 0.3rem 0rem;
}

.formation-school-container > h1{
    margin:0;
    font-size: small;
    color : var(--indigo-dye);
    padding: 0rem;
}

.formation-school-container > label{
    margin:0;
    padding: 0.3rem 0rem;
}

.formation-school-container i{
    font-size: 0.6rem;
}


.formation-campus-info{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    font-size: small;
    flex-basis: 35%;
    padding:0.3rem;
    flex-shrink: 1;
}

.formation-campus-info > div{
    padding:0.2rem;
}

.formation-thumbnail-grid-horizontal .formation-actions-info{
    display: flex;
    align-self: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-basis: 10%;
    flex-shrink: 1;
    height: 100%;
}


.formation-actions-info .formation-elipsis-menu.p-button-icon-only:focus{
    box-shadow:none;
}

.formation-actions-info-button-container{
    padding: 0.5rem 0;
    height: 100%;
    display : flex;
    flex-direction: column;
}


 /* VERTICAL */

 .formation-thumbnail-grid-vertical{
    display: flex;
    flex-direction: column;
    min-height: 30rem;
 }

 /* REORDER */
 .formation-thumbnail-grid-vertical  > div:nth-child(5){
    order : 1;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(1){
    order : 2;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(2){
    order : 3;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(3){
    order : 4;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(4){
    order : 5;
 }



.formation-actions-info-carre {
    display: flex;
    flex-direction: column;
}

.formation-actions-info-carre div {
    align-self: center;
}

 .formation-thumbnail-grid-vertical .formation-actions-info{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    flex-shrink: 1;
    flex-wrap: nowrap;
 }

 .formation-thumbnail-grid-vertical .formation-logo-container{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    flex-shrink: 1;
    padding:0.5rem;
}
.formation-thumbnail-grid-vertical .formation-school-container{
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: flex-start;
}
.formation-thumbnail-grid-vertical .formation-campus-info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
}

.formation-thumbnail-grid-vertical .formation-logo-container img{
    height: 6rem;
    width: 100%;
}



@media screen and (max-width:992px) {
    .formation-thumbnail-grid-horizontal  > div:nth-child(5){
        order : 1;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(1){
        order : 2;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(2){
        order : 3;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(3){
        order : 4;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(4){
        order : 5;
     }

     .formation-thumbnail-grid-horizontal{
        display: flex;
        flex-direction: column;
        min-height: 10rem;
        padding:0.5rem;
     }

     .formation-thumbnail-grid-horizontal .formation-actions-info{
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
     }
    
     .formation-thumbnail-grid-horizontal .formation-logo-container{
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        padding:0.5rem;
    }

    .formation-thumbnail-grid-horizontal .formation-logo-container img{
        height: 6rem;
        width: 100%;
    }
}

@media screen and (max-width:750px) {
    .margin-formation-campus{
        border-radius: 1rem;
        margin: 0.5rem 0rem;
    }
}

.menu-small-pop-up.p-menu{
    background: black;
    padding-bottom:1rem;
    border-radius: 0.5rem;
}

.menu-small-pop-up.p-menu ul li a span.p-menuitem-text{
    color: white;
}

.menu-small-pop-up.p-menu ul li a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 84%;
    left: 8%;
    bottom: 0;
}

.cfc-container {
    background-color: transparent;
    position: relative;
    margin-top: 4rem;
    border-radius: 1rem;
    height: 20rem;
}

.cfc-mention {
    display: -webkit-box; 
    -webkit-box-orient: vertical;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.cfc-mention-limit-text{
    /* number of lines to show */    
    -webkit-line-clamp: 1;
    line-clamp: 1;
    z-index: 90;
}

.cfc-parcours{
    padding-bottom: 0.5rem;
}

.formation-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.formation-logo-container {
    display: table;
    margin: auto;
    position: absolute;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    top: -2.5rem;
    left: 2rem;
    width: 10.38rem;
    height: 6.8rem;
    background-color: white;
}

.formation-logo-sub-container{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding:0.2rem;
}

.cfc-tags-container {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: -1rem;
    left: 13rem;
}

.cfc-details-container {
    color: var(--gray);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cfc-details-container .p-inputtext {
    padding: 0.2rem 0.5rem;
}

.cfc-details-container .p-dropdown {
    border: none !important;
}

.cfc-container-text div p {
    margin-bottom: 0.5rem;
    font-family: var(--font-family-mulish);
    font-weight: 700;
}


.cfc-container .dip-tag-blue {
    margin-bottom: 0.5rem;
}

.cfc-container button {
    padding: 0.5rem 1rem;
}

.cfc-container .p-button {
    border-radius: 0.3rem;
}

.cfc-container-text {
    height: 15rem;
    display: flex;
    flex-direction: column;
}

.cfc-offer-button.p-button {
    padding: 0.5rem 1rem;
}

.cfc-icon-campus {
    width: 100%;
}

.cft-button-see-formation i {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    pointer-events: none; 
}

@media screen and (max-width:992px) {
    .cfc-offer-button.p-button {
        padding: 0.5rem 0.2rem;
    }

    .cfc-container {
        height: 22rem;
    }
}
.etudiant-thumbnail-card .p-card-body{
    padding: 0;
}

.vue-candidat-main-container .etudiant-thumbnail-card .p-card-content{
    padding: 0;
}

.etudiant-thumbnail-card .p-card-content{
    padding: 0;
}

.etudiant-thumbnail-card.p-card.p-component{
    border-radius: 1rem;
}

.etudiant-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    background-color: var(--grey-cards);
}

.etudiant-thumbnail-main-container-with-formation-radius{
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.etudiant-thumbnail-main-container-without-formation-radius{
    border-radius: 1rem;
}

.etudiant-thumbnail-main-container>div{
    padding: 1rem 2rem;
    border-top-left-radius: 1rem;
}

.etudiant-thumbnail-profile{
    display: flex;
    flex-direction: row;
    background-color: white;
    flex-grow: 1;
}

.etudiant-thumbnail-profile-image{
    background-color: white;
    margin-right: 1rem;
}

.etudiant-thumbnail-profile-image img{
    object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--eerie-black);
  height: 7rem;
  width: 7rem;
  
}

.etudiant-thumbnail-profile-image .p-avatar-circle{
    height: 7rem;
  width: 7rem;
}

.etudiant-thumbnail-profile-image .p-avatar .p-avatar-icon{
    font-size: 3.2rem;
}


.etudiant-thumbnail-info-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.etudiant-thumbnail-elipsis-radius-with-formation{
    border-radius: 2% 4% 4% 0% / 8% 50% 50% 8%;
}

.etudiant-thumbnail-elipsis-radius-without-formation{
    border-radius: 2% 4% 4% 2% / 8% 50% 50% 8%;
}


.etudiant-thumbnail-info-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.etudiant-thumbnail-recherche-active-container{
    margin-left: auto;
}
.etudiant-thumbnail-recherche-active-container ul{
    padding-left: 1rem;
}

.etudiant-thumbnail-recherche-active-container h2{
    margin:0;
    color: var(--terra-cotta);
    font-size: 1.1rem;
}

.etudiant-thumbnail-recherche-active-container .p-tag{
    margin: 0.5rem 0;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.etudiant-thumbnail-recherche-active-title-container{
    display: flex;
    align-items: center;
}

.etudiant-thumbnail-recherche-active-title-container>div:first-child{
    flex-grow: 1;
}

.etudiant-thumbnail-button{
    background-color: var(--primary-color);
    color: white;
    width: auto;
    padding: 0.5rem 1rem;
}

.etudiant-thumbnail-center{
    text-align: center;
    width: 100%;
}

.tag-stage{
    background-color:var(--indigo-blue)
}

.tag-emploi{
    background-color:var(--indigo-dye)
}
.tag-alternance{
    background-color:var(--linkedin-blue)
}

.et-dialog-button{
    background-color: var(--terra-cotta);
    color: white;
}

.location-panel{
    background-color: black;
    color: white;
    background: black;
}

.location-panel::before{
    border-bottom-color:black ;
}

.location-panel::after{
    border-bottom-color:black ;
}

.p-button.location-button{
    padding:0 0.5rem;
    border: 1px solid black;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    border-color:var(--grey-cards);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    background: white;
    border: none;
    border-top: 1px solid var(--grey-cards);
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: white;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab .p-accordion-content{
    border:none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.etudiant-thumbnail-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.etudiant-thumbnail-title-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.etudiant-thumbnail-moe-in-title{
    display:none;
}

.etudiant-thumbnail-small-moe-in-title{
    display: block;
}

/* SMALL */

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image img{
    height: 5rem;
    width: 5rem;
    
  }

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image .p-avatar-circle{
    height: 5rem;
    width: 5rem;
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container{
    flex-direction: column;
    align-items: flex-start;
}
.etudiant-thumbnail-small .etudiant-thumbnail-elipsis-radius-without-formation{
    border-radius: 0;
}

.etudiant-thumbnail-small .etudiant-thumbnail-recherche-active-container{
    margin-left: 0;
    display: flex;
    flex-direction: column;
    
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container>div{
    padding: 1rem;
    width: 100%;
    min-height: 10rem;
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container>div:first-child{
    border-top-left-radius: 1rem ;
    border-top-right-radius: 1rem ;
    border-bottom-right-radius: 0;
}

.etudiant-thumbnail-toolbar
{
    padding: 0.4rem !important;
    min-height: 2rem !important;
    text-align: end
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container .etudiant-thumbnail-center{
    min-height: 0rem;
}

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image{
    text-align: center;
}

.etudiant-thumbnail-small-moe-in-title{
    display:block;
}

.margin-etudiant-thumbnail{
    margin: 0.5rem;
}
.etudiant-thumbnail-check-profile{
    text-align: right;
}

.etudiant-thumbnail-moe-in-active-search{
    display: block;
}

.etudiant-thumbnail-bottom-check-profile-hidden{
    display: none;
}

.etudiant-thumbnail-bottom-check-profile-show{
    display: block;
}

.etudiant-thumbnail-bottom-check-profile-responsive{
    display: none;
}

@media screen and (max-width:992px) {
    .etudiant-thumbnail-profile-image{
        text-align: center;
        margin-right: 0;
    }
    .etudiant-thumbnail-profile-image .p-avatar-circle{
        height: 5rem;
        width: 5rem;
    }

    .etudiant-thumbnail-profile-image img{
        height: 5rem;
        width: 5rem;
    }
    .etudiant-thumbnail-main-container>div:first-child{
        padding: 1rem;
        width: 100%;
        border-top-right-radius: 1rem;
        min-height: 0rem;
    }
    .etudiant-thumbnail-main-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .etudiant-thumbnail-elipsis-radius-with-formation{
        border-radius: 0;
    }
    .etudiant-thumbnail-elipsis-radius-without-formation{
        border-radius: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    .etudiant-thumbnail-recherche-active-container{
        margin-left: 0;
    }
    .margin-etudiant-thumbnail{
        margin: 0.5rem 0rem;
    }

    .etudiant-thumbnail-check-profile{
        display: none;
    }
    .etudiant-thumbnail-bottom-check-profile-responsive{
        display: block;
    }
    .etudiant-thumbnail-moe-in-active-search{
        display: none;
    }
    .etudiant-thumbnail-moe-in-title{
        display:block;
    }

}

.datatable-recrutements .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.frs-button.p-button{
    width: auto;
    color: white;
    background-color: var(--iris-100);
}
.moe-button.p-button{
    background-color: transparent;
}

.moe-button.p-button.p-button-icon-only{
    height: 1.5rem;
    width:1.5rem;
}

.formation-list-item-main-container{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.formation-list-item-main-container-vertical{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.formation-list-item-main-container>div{
    padding: 0.5rem;
}

.formation-list-item-main-container>div:first-child{
    display: flex;
    width: 10rem;
    height: auto;
    align-items: center;
    justify-content: center;
}

.formation-list-item-main-container-vertical>div:first-child{
    display: flex;
    align-self: center;
    width: auto;
    height: 5.6rem;
    align-items: center;
    justify-content: center;
}

.formation-list-item-info{
    width: -webkit-fill-available;
}

.formation-list-item-main-container>div:first-child img{
    display: flex;
    max-width: 7rem;
    height: auto;
    max-height: 4.6rem;
}

.formation-list-item-main-container-vertical>div:first-child img{
    display: flex;
    max-width: 7rem;
    height: auto;
    max-height: 4.6rem;
    width: auto;
}

.formation-list-item-composante{
    font-weight: 500;
}


.formation-list-item-obtention{
    font-style: italic;
}

.formation-list-item-a-venir{
    color:var(--terra-cotta);
}

@media screen and (max-width:992px) {
    .formation-list-item-main-container{
        flex-direction: column;
    }
    .formation-list-item-main-container>div:first-child{
        align-self: center;
        width: auto;
        height: 5.6rem;
    }
    .formation-list-item-main-container>div:first-child img{
        max-height: 4.6rem;
        width: auto;
    }
}
img.aci-tooltip{
    height: 1.5rem;
    width: auto;
    vertical-align: top;
} 

.aci-align{
    text-align: left;
}
.btn-goback-whisper{
    background-color: var(--whisper);
}
.candidat-thumbnail-card .p-card-body{
    padding: 0;
}

.vue-candidat-main-container .candidat-thumbnail-card .p-card-content{
    padding: 0;
}

.candidat-thumbnail-card .p-card-content{
    padding: 0;
}

.candidat-thumbnail-card.p-card.p-component{
    border-radius: 1rem;
}

.candidat-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    background-color: var(--grey-cards);
}

.candidat-thumbnail-main-container-with-formation-radius{
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.candidat-thumbnail-main-container-without-formation-radius{
    border-radius: 1rem;
}

.candidat-thumbnail-main-container>div{
    padding: 1rem 2rem;
    border-top-left-radius: 1rem;
}

.candidat-thumbnail-profile{
    display: flex;
    flex-direction: row;
    background-color: white;
    flex-grow: 1;
}

.candidat-thumbnail-profile-image{
    background-color: white;
    margin-right: 1rem;
}

.candidat-thumbnail-profile-image img{
    object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--eerie-black);
  height: 7rem;
  width: 7rem;
  
}

.candidat-thumbnail-profile-image .p-avatar-circle{
    height: 7rem;
  width: 7rem;
}

.candidat-thumbnail-profile-image .p-avatar .p-avatar-icon{
    font-size: 3.2rem;
}


.candidat-thumbnail-info-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.candidat-thumbnail-elipsis-radius-with-formation{
    border-radius: 2% 4% 4% 0% / 8% 50% 50% 8%;
}

.candidat-thumbnail-elipsis-radius-without-formation{
    border-radius: 2% 4% 4% 2% / 8% 50% 50% 8%;
}


.candidat-thumbnail-info-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.candidat-thumbnail-recherche-active-container{
    margin-left: auto;
}
.candidat-thumbnail-recherche-active-container ul{
    padding-left: 1rem;
}

.candidat-thumbnail-recherche-active-container h2{
    margin:0;
    color: var(--terra-cotta);
    font-size: 1.1rem;
}

.candidat-thumbnail-recherche-active-container .p-tag{
    margin: 0.5rem 0;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.candidat-thumbnail-recherche-active-title-container{
    display: flex;
    align-items: center;
}

.candidat-thumbnail-recherche-active-title-container>div:first-child{
    flex-grow: 1;
}

.candidat-thumbnail-button{
    background-color: var(--primary-color);
    color: white;
    width: auto;
    padding: 0.5rem 1rem;
}

.candidat-thumbnail-center{
    text-align: center;
    width: 100%;
}

.tag-stage{
    background-color:var(--indigo-blue)
}

.tag-emploi{
    background-color:var(--indigo-dye)
}
.tag-alternance{
    background-color:var(--linkedin-blue)
}

.location-panel{
    background-color: black;
    color: white;
    background: black;
}

.location-panel::before{
    border-bottom-color:black ;
}

.location-panel::after{
    border-bottom-color:black ;
}

.p-button.location-button{
    padding:0 0.5rem;
    border: 1px solid black;
}

.candidat-thumbnail-accordion.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.candidat-thumbnail-accordion.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    border-color:var(--grey-cards);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.candidat-thumbnail-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    background: white;
    border: none;
    border-top: 1px solid var(--grey-cards);
}

.candidat-thumbnail-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: white;
}

.candidat-thumbnail-accordion.p-accordion .p-accordion-tab .p-accordion-content{
    border:none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.candidat-thumbnail-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.candidat-thumbnail-title-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.candidat-thumbnail-moe-in-title{
    display:none;
}

.candidat-thumbnail-small-moe-in-title{
    display: block;
}

/* SMALL */

.candidat-thumbnail-small .candidat-thumbnail-profile-image img{
    height: 5rem;
    width: 5rem;
    
  }

.candidat-thumbnail-small .candidat-thumbnail-profile-image .p-avatar-circle{
    height: 5rem;
    width: 5rem;
}

.candidat-thumbnail-small.candidat-thumbnail-main-container{
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
}
.candidat-thumbnail-small .candidat-thumbnail-elipsis-radius-without-formation{
    border-radius: 0;
}

.candidat-thumbnail-small .candidat-thumbnail-recherche-active-container{
    margin-left: 0;
    display: flex;
    flex-direction: column;
    
}

.candidat-thumbnail-small.candidat-thumbnail-main-container>div{
    padding: 1rem;
    width: 100%;
    min-height: 10rem;
}

.candidat-thumbnail-small.candidat-thumbnail-main-container>div:first-child{
    border-top-left-radius: 1rem ;
    border-top-right-radius: 1rem ;
    border-bottom-right-radius: 0;
}

.candidat-thumbnail-toolbar
{
    padding: 0.4rem !important;
    min-height: 2rem !important;
    text-align: end
}

.candidat-thumbnail-small.candidat-thumbnail-main-container .candidat-thumbnail-center{
    min-height: 0rem;
}

.candidat-thumbnail-small .candidat-thumbnail-profile-image{
    text-align: center;
}

.candidat-thumbnail-small-moe-in-title{
    display:block;
}

.margin-candidat-thumbnail{
    margin: 0.5rem;
}
.candidat-thumbnail-check-profile{
    text-align: right;
}

.candidat-thumbnail-moe-in-active-search{
    display: block;
}

.candidat-thumbnail-bottom-check-profile-hidden{
    display: none;
}

.candidat-thumbnail-bottom-check-profile-show{
    display: block;
}

.candidat-thumbnail-bottom-check-profile-responsive{
    display: none;
}

@media screen and (max-width:992px) {
    .candidat-thumbnail-profile-image{
        text-align: center;
    }
    .candidat-thumbnail-profile-image .p-avatar-circle{
        height: 5rem;
        width: 5rem;
    }

    .candidat-thumbnail-profile-image img{
        height: 5rem;
        width: 5rem;
    }
    .candidat-thumbnail-main-container>div:first-child{
        padding: 1rem;
        width: 100%;
        border-top-right-radius: 1rem;
        min-height: 0rem;
    }
    .candidat-thumbnail-main-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .candidat-thumbnail-elipsis-radius-with-formation{
        border-radius: 0;
    }
    .candidat-thumbnail-elipsis-radius-without-formation{
        border-radius: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    .candidat-thumbnail-recherche-active-container{
        margin-left: 0;
    }
    .margin-candidat-thumbnail{
        margin: 0.5rem 0rem;
    }

    .candidat-thumbnail-check-profile{
        display: none;
    }
    .candidat-thumbnail-bottom-check-profile-responsive{
        display: block;
    }
    .candidat-thumbnail-moe-in-active-search{
        display: none;
    }
    .candidat-thumbnail-moe-in-title{
        display:block;
    }

}
/* .nrao-main {
    background-color: #f4f4f4;
    border-radius: 1rem;

    padding: 2rem;
}

.nrao-submain {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nrao-left-buttons {
    display: flex;
    align-items: center;
}

.nrao-tri {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
}


.nrao-dd.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
    color: black;
    border-color: var(--terra-cotta);
    background-color: white;
}


.nrao-treeselect-container {
    display: flex;
    flex-direction: row;
}

.nrao-treeselect-container label {
    white-space: nowrap;
    align-self: center;
}

.nrao-treeselect-container .p-multiselect {
    width: -webkit-fill-available;
}

.nrao-treeselect-container .p-multiselect .p-multiselect-label {
    width: 26rem;
}

.nrao-candidat-filter-container {
    margin-top: 1rem;
}

.nrao-checkbox {
    display: flex;
    align-items: center;
}

.nrao-selector input {
    width: 100%;
}


@media screen and (max-width:992px) {
    .nrao-submain {
        max-width: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nrao-left-buttons {
        flex-direction: column;
    }

    .nrao-left-buttons .p-button {
        margin-top: 0.5rem;
    }


    .nrao-treeselect-container {
        flex-direction: column;
    }

    .nrao-treeselect-container label {
        align-self: normal;
    }

    .nrao-treeselect-container .p-multiselect .p-multiselect-label {
        width: 12rem;
    }

    .nrao-candidat-filter-container {
        margin-top: 0rem;
    }
}  */
.candidat-search-button-seemore.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined
{
    color:black;
    border-color: var(--primary-color);
}

.candidat-search-main-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.candidat-search-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidat-search-results-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}



.candidat-search-filters-container{
    padding:0.5rem;
    flex-basis: 25%;
}
.candidat-search-filters-center{
    align-self: center;
}

.candidat-search-right-container{
    flex-basis: 75%;
}

.candidat-search-item{
    flex-basis:25%;
    width: 25%;
}



.candidat-search-float-button{
    display: none;
}

@media screen and (max-width:992px){
    .candidat-search-filters-container{
        display: none;
    }

    .candidat-search-item{
        flex-basis:100%;
        width: 100%;
    }
    .candidat-search-float-button{
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0rem;
        padding: 0.7rem;
    }

    .candidat-search-float-button .p-button{
        border: 2px solid var(--indigo-dye);
        border-radius: 2rem;
    }

}




.datatable-administrateurs .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.required-item {
   color: var(--primary-color)
}

label.label-for-OE {
   font-weight: 500;
   color: var(--indigo-dye)
}

span.span-for-OE {
   color: var(--indigo-dye);
}

.OE-main-container{
   justify-content: center;
}

.OE-card-margin {
   margin: 2%;
}

.OE-plain-button{
   background-color: var(--alabaster);
   color:var(--terra-cotta)
}

.voe-card.p-card .p-card-body {
      padding: 0;
}

.voe-card.p-card .p-card-content {
   padding: 0.5rem 2rem;
}

.voe-blur{
   -webkit-filter : blur(0.5rem);
           filter : blur(0.5rem);
   pointer-events: none;
}

.voe-compatible-course-container{
   position: absolute;
   width: 98vw;
   left: 0rem;
}

.voe-compatible-course{
   margin-top: 7rem;
}


.p-chip.required-skill {
   background-color: var(--primary-color);
}

.accordion-for-offer .p-accordion-header.p-highlight {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: #ffffff;

}

.accordion-for-offer .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: #ffffff;
   border-color: transparent;
}

.accordion-for-offer .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid var(--linkedin-blue);
   color: var(--linkedin-blue);
}


.accordion-for-offer .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid var(--linkedin-blue);
   color: var(--linkedin-blue);
}

.accordion-for-offer .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
}


.accordion-for-offer .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.accordion-for-offer .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: var(--opal-light);
}

.OE-custombar {
   width: '100%';
   height: '12em';
}

.p-scrollpanel.OE-custombar {
   width: '100%';
   max-height: '10em';
}

.p-scrollpanel.OE-custombar .p-scrollpanel-bar {
   background-color: var(--opal-light);
   opacity: 1;
   transition: background-color .2s;
}

.published-date-style-offer {
   text-align: right;
}

.published-date-style-offer span {
   font-style: italic;
   margin-right: 1rem;
}

.missing-value {
   font-style: italic;
}

@media screen and (max-width:992px) {
   .voe-card.p-card .p-card-content {
      padding: 0.5rem 1rem;
   }
}
.oept-card .p-card-body{
    padding: 0;
}

.oept-card .p-card-content{
    padding: 0;
}

.oept-card.p-card.p-component{
    border-radius: 15px;
}

.oept-main-container{
    display: flex;
    flex-direction: row;
    
    border-radius: 15px;
}

.oept-white-bg{
    background-color: white;
}

.oept-grey-bg{
    
    background-color: var(--grey-cards);
}

.oept-main-container>div{
    padding: 1rem 2rem;
    border-top-left-radius: 15px;
}

.oept-profile{
    display: flex;
    flex-direction: row;
    background-color: white;
    flex-grow: 1;
    border-radius: 2% 4% 4% 2% / 8% 50% 50% 8%;
}

.oept-profile-image{
    background-color: white;
    margin-right: 1rem;
}

.oept-profile-image img{
    object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--eerie-black);
  height: 7rem;
  width: 7rem;
  
}

.oept-profile-image .p-avatar-circle{
    height: 7rem;
  width: 7rem;
}

.oept-profile-image .p-avatar .p-avatar-icon{
    font-size: 3.2rem;
}

.oept-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.oept-title-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}


.oept-subtitle {
    font-style: italic;
    color: var(--linkedin-blue);
    font-weight: 500;
}

.oept-contract-container .p-tag {
    margin: 0.5rem 0;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.oept-italic {
    font-style: italic;
}

.oept-like-container {
    text-align: end;
}

.oept-logo{
    margin-right:1.5rem;
}

.oept-logo img {
    max-height: 7rem;
    width: 7rem;
}

.oept-like-in-title{
    text-align: right;
    display:none;
}


.oept-right-container{
    margin-left: auto;
}

.oept-postuler-container{
    margin-top: auto;
    display:flex;
}

@media screen and (max-width:992px) {

    .oept-main-container>div:first-child{
        padding: 1rem;
        width: 100%;
        border-top-right-radius: 15px;
        min-height: 0rem;
    }

    .oept-main-container>div{
        padding: 1rem;
    }
    .oept-main-container{
        flex-direction: column;
        align-items: flex-start;
    }

    .oept-info-container{
        width: 100%;
    }
    .oept-like-container{
        display: none;
    }

    .oept-right-container{
        margin-left: 0;
        width: 100%;
    }

    .oept-profile{
        flex-direction: column;
        border-radius: 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .oept-logo{
        margin-right:0rem;
        text-align: center;
    }

    .oept-like-in-title{
        display:block;
    }

    .oept-postuler-container{
        margin-top: 0.5rem;
    }
}
.cpat-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.cpat-logo-container {
    display: table;
    border-radius: 7px;
    background-color: white;
    flex: 20% 0;
}

.cpat-logo-container img {
    margin-left: 0rem;
}

.cpet-button-full-description {
    display: flex;
    place-content: center;
}

.cpet-description-container div:first-child p:first-child { 
    margin-top: 0;
}
.oespt-title{
    margin: 0;
}

.oespt-center{
    text-align: center;
}
.formation-actions-speeddial.p-speeddial{
    position: relative;
}

.formation-actions-speeddial .p-speeddial-action{
    justify-content: center;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-right: 0.5rem;
    border-radius: 50%;
    color: white;
    padding: 0.5rem 0rem;
    height:2.357rem;
    width: 2.357rem;
}

.formation-actions-speeddial.p-speeddial-opened .p-speeddial-list{
    display: flex;
}

.formation-actions-speeddial .p-speeddial-list{
    display: none;
}

.formation-actions-speeddial .p-button{
    background: var(--terra-cotta);
    color: white;
}

.formation-actions-speeddial .p-button:not(a):not(.p):hover{
    background: var(--terra-cotta);
    color: white;
}



.admin-order-speeddial li:nth-child(1) .p-speeddial-action{
    background-color: var(--p-danger);
}
.admin-order-speeddial li:hover:nth-child(1) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-danger-hover);
}

.admin-order-speeddial li:nth-child(2) .p-speeddial-action{
    background-color: var(--p-warning);
}
.admin-order-speeddial li:hover:nth-child(2) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-warning-hover);
}

.admin-order-speeddial li:nth-child(3) .p-speeddial-action{
    background-color: var(--p-help);
}
.admin-order-speeddial li:hover:nth-child(3) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-help-hover);
}

.admin-order-speeddial li:nth-child(4) .p-speeddial-action{
    background-color: var(--p-success);
}
.admin-order-speeddial li:hover:nth-child(4) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-success-hover);
}


.default-order-speeddial li:nth-child(1) .p-speeddial-action{
    background-color: var(--p-warning);
}
.default-order-speeddial li:hover:nth-child(1) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-warning-hover);
}

.default-order-speeddial li:nth-child(2) .p-speeddial-action{
    background-color: var(--p-success);
}
.default-order-speeddial li:hover:nth-child(2) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-success-hover);
}

.dtfa-disabled-button{
    display: block;
}

.datatable-ecoles .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.save-formation{
    display: inline-flex;
    margin-right: 1rem;
}

.p-dialog .p-dialog-footer button.save-button-formation {
    color:var(--terra-cotta);
}
.p-dialog .p-dialog-footer .save-button-formation > button {
    margin:0.25rem;
    padding: 0;
}
.p-menu-list.p-reset.menu-save-button-formation{
    color:var(--terra-cotta);
}




.ajout-picklist-item{
    padding-bottom: 3rem;
}

.ajout-picklist-item .p-speeddial-action{
    margin:0.5rem;
}

.ajout-picklist-item .p-speeddial-opened .p-speeddial-item{
    background-color: var(--green-success);;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}
.dragdrop-style{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.datatable-editeurs .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-fileupload.ajoutlogo >.p-button  {
   background-color: var(--green-success);
   color:white;
}
.aep-align-check{
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 1.75rem;
}
.ec-margin{
    margin-right: 5rem;
}

.ec-help{
    width: 18rem;
    font-size: large;
}

.ec-container{
    background: #FFFFFF;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 0.5rem 2rem;
    color:var(--resolution-blue);
    width: 18rem;
}

.ec-container h1{
    color:var(--terra-cotta);
    font-size: 20px;
}

.ec-container h2{
    font-size: 16px;
}

@media screen and (max-width:992px){
    .ec-container{
        width: auto;
    }

    .ec-margin{
        margin-right: 0rem;
    }
}
.fcp-tab .p-tabview-title{
    font-size: larger;
}

.fcp-tab .p-tabview-nav li .p-tabview-nav-link{
    color: var(--resolution-blue);
}

.fcp-popup-first-connection.p-dialog .p-dialog-header .p-dialog-title{
    color: var(--resolution-blue);
    font-size: 1.6rem;
    font-weight: 500;
}

.fcp-popup-first-connection.p-dialog .p-dialog-content{
    color: var(--midnight-express);
    font-size: 1.3rem;
    font-weight: 500;
}

.fcp-button-popup-first-connection.p-button{
    width: auto;
    color: white;
    background-color: var(--terra-cotta);
}

.fcp-tab.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    font-weight: 600;
}

.fcp-tab.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-weight: 500;
}
.api-button-style{
    align-self:flex-end;
}

.api-button-style .p-button{
    width: 100%;
}

.mailto-text-style{
    color:var(--terra-cotta);
    text-decoration: underline;
}
.abonnement-card{
    position: relative;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 0.5rem 2rem;
}

.partner-banner {
    position: absolute;
    top: 20px;
    right: -15px;
    background-color: red;
    color: white;
    padding: 5px 20px;
    font-size: 0.9rem;
    font-weight: bold;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
    z-index: 1;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
}

.ac-title-container {
    text-align: center;
    margin: -0.5rem -2rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 4rem;
    color: white;
    margin-bottom: 0.8rem;
    background-color: var(--resolution-blue);
    
    display: flex;
    
    flex-direction: column;
    justify-content: center;
}

.ac-title-container h1{
    font-size: 1.5rem;
    margin: 0;
}

.ac-description-general-container{
    font-size: 0.8rem;
    font-weight: 500;
}

.ac-second-title-container {
    color: var(--terra-cotta);
    font-size: 1.3rem;
    font-weight: 600;
}

.ac-thrid-title-container {
    color: var(--terra-cotta);
    font-size: 1rem;
    font-weight: 600;
}

.abonnement-card h3 {
    font-size: 1.2rem;
    margin: 0;
    color: var(--resolution-blue);
    margin-bottom: 1rem;
}

.abonnement-card p {
    color: var(--indigo-dye);
}

.abonnement-card-list{
    list-style-image: url(/static/media/verifiedIcon.c6638f26.svg);
}

.abonnement-card-link{
    font-weight: 500;
    text-decoration: underline;
    color: var(--resolution-blue);
}

.ac-abonnement-card-button {
    color: rgb(124, 167, 218);
}

.ac-abonnement-card-button .p-button-label{
    font-style: italic;
}

.ac-description-container {
    flex-wrap: nowrap;
    font-weight: 500;
    justify-content: start;
}

.ac-icon-style {
    width: 1.2rem;
    height: 1.2rem;
}

.ac-check-color {
    color: #00B050;
}

.ac-close-color {
    color: red;
}

.ac-base-container{
    color: black;
    font-size: 1.2rem;
    font-weight: 990;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    text-decoration-thickness: 0.1rem;
}

.ac-green-container {
    color: #00B050;
    font-size: 1.4rem;
    font-weight: 600;
}

.ac-price-button-contain{
    justify-content: space-around;
}

.ac-price-alone-contain {
    justify-content: center;
}

.ac-price-contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ac-input-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--resolution-blue);
    font-size: 1.1rem;
    font-weight: 600;
}

.ac-description-subscription{
    color: var(--terra-cotta);
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
.dgce-add-collaborateur-container .p-inputtext.dgce-input-email-container {
    width: 30rem;
}
.see-more-button{
    text-align: center;
}

.see-more-button .p-button.p-button-outlined{
    background-color: white;
    justify-content: center;
}

.link-to-rechercheAffinee{
    color: var(--terra-cotta);
    text-decoration: underline;
}
.cursus-card .p-divider.p-divider-horizontal:before{
    border-top: solid 1px var(--grey-disabled);
}

.cursus-card.p-card.p-component{
    border-radius: 15px;
}

.cursus-card .p-card-body{
    padding: 0.5rem;
}
.langue-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.langue-thumbnail-main-container>div{
    flex: 1 1 50%;
    padding: 0.5rem;
}

.langue-thumbnail-card.p-card.p-component{
    border-radius: 15px;
}

@media screen and (max-width:992px){
    .langue-thumbnail-main-container{
        flex-direction: column;
    }
    .langue-thumbnail-main-container>div{
        flex: 1 1 100%;
    }
}
.listelibelle-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listelibelle-thumbnail-main-container>div{
    /*flex: 1 50%;*/
    padding: 0.5rem;
    
}

.listelibelle-thumbnail-card.p-card.p-component{
    border-radius: 15px;
}

.listelibelle-thumbnail-seemore{
   text-align: center;
}

.listelibelle-thumbnail-lineheight{
    line-height: 25px;
}

.listelibelle-thumbnail-subtitle{
    margin-top:0
}

.listelibelle-thumbnail-button-padding{
    padding: 0.5rem 2rem;
}

@media screen and (max-width:992px){
    .listelibelle-thumbnail-main-container{
        flex-direction: column;
    }
    .listelibelle-thumbnail-main-container>div{
        flex: 1 1 100%;
    }
}
.presentation-thumbnail-card.p-card.p-component{
    border-radius: 15px;
}
.vue-candidat-no-result-container{
    color: grey;
    padding: 5rem 0;
    font-size: 2rem;
    text-align: center;
    font-style: italic;
}

.vue-candidat-main-container{
    justify-content: center;
}

.vue-candidat-main-container .p-card-content{
    padding:0 1rem;
}

.vue-candidat-card-title{
    margin: 0;
    padding: 1rem 2rem;
    color : var(--linkedin-blue);
    font-size:1.5rem;
}

.vue-candidat-share-bnt-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

@media screen and (max-width:992px) {
    .vue-candidat-card-title{
        padding: 1rem;
    }
    .vue-candidat-main-container .p-card-content{
        padding:0;
    }

    .vue-candidat-main-container{
        padding: 0.5rem;
    }
}
.float-accueil-button-confidentialite {
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0rem;
    padding: 0.7rem;
}

.float-accueil-button-confidentialite .p-button{
    background-color: var(--terra-cotta);
    color: white;
}

.confidentialite-container{
    margin: 0 3rem;
}

.accueil-button-etudiant {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}


.accueil-button-etudiant .p-button {
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
}

.plain-button-trouver-formation {
    text-align: center;
    margin-top: 3rem;
}

.plain-button {
    text-align: center;

}

.plain-button .p-button {
    background-color: var(--terra-cotta);
    color: white;

}

.plain-button-trouver-formation .p-button {
    background-color: var(--terra-cotta);
    color: white;

}

.savoirplus-etudiant-container {
    margin: 0 10rem;
}

.savoirplus-etudiant-block{
    font-weight: 500;
}

.savoirplus-etudiant-container ul li::marker {
    /* content: "\2022";  Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--terra-cotta);
    /* Change the color */
    /* font-weight: bold; If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    /* margin-left: -1em; Also needed for space (tweak if needed) */
}

.savoirplus-etudiant-head {
    text-align: center;
}

.text-huge-etudiant {
    font-size: 2rem;
}

.text-big-etudiant {
    font-size: 1.70rem;
}

.text-big-comment-ca-marche {
    font-size: 1.75rem;
    font-weight: 500;
}

.savoirplus-etudiant-container .p-card span {
    text-align: center;
    vertical-align: text-bottom;
}

.imagecard-etudiant {
    text-align: center;
}

.imagecard-etudiant img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

.divider-mini.p-divider.p-divider-horizontal {
    border-top: 1px rgb(228, 228, 228);
}

.divider-mini.p-divider.p-divider-horizontal::before {
    border-top: 1px rgb(228, 228, 228);
    border-top-style: solid;
}

.title-en-savoir-plus-etudiant{
    font-size: 1.25rem;
}

.title-en-savoir-plus-etudiant h1{
    margin: 0rem;
}


.comment-ca-marche-savoir-plus-etudiant h3{
    margin: 0;
    font-size: 1.375rem;
}

.text-big-etudiant h3
{
    font-size: 1.7rem;
}

@media screen and (max-width:992px) {
    .savoirplus-etudiant-container {
        margin: 0;
    }

    .title-en-savoir-plus-etudiant {
        font-size: 0.75rem;
    }

    .text-huge-etudiant {
        margin-left: 0;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }

    .text-big-etudiant {
        text-align: center;
        font-size: 1.25rem;
    }

    .imagecard-etudiant {
        width: 100%;
    }

    .imagecard-etudiant img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }

    .comment-ca-marche-savoir-plus-etudiant li {
        font-size: 1.25rem;
    }

    .text-big-comment-ca-marche {
        font-size: 1.50rem;
        font-weight: 500;
        text-align: center;
    }
}
.savoirplus-entreprise-container {
    margin: 0 10rem;
}

.accueil-button-entreprise {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}

.savoirplus-entreprise-head{
    text-align: center;
}

.savoirplus-entreprise-head h1{
    margin-top: 0;
}

.savoirplus-entreprise-head h2{
    margin-top: 0;
    font-size: 2em;
}

 h3.text-huge-entreprise{
    font-weight: 400;
}

.creer-un-compte-button-entreprise {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
    /* margin-top: 3rem; */
}

.accueil-button-entreprise .p-button {
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
    margin-top: 2rem;
}

.creer-un-compte-button-entreprise .p-button {
    background-color: var(--terra-cotta);
    color: white;
}


.text-huge-entreprise {
    color: var(--indigo-dye);
    font-size: 1.75rem;
}

.text-huge-entreprise2 {
    color: var(--indigo-dye);
    font-size: 1.75rem;
    font-weight: 500;
}

.imagecard-entreprise {
    text-align: center;

}

.imagecard-entreprise img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

@media screen and (max-width:992px) {

    .savoirplus-entreprise-container {
        margin: 0rem;
    }

    .text-huge-entreprise {
        margin-left: 0;
        line-height: 2.50rem;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }

    .text-huge-entreprise2 {
        margin-left: 0;
        line-height: 2.50rem;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }


    .savoirplus-entreprise-head {
        font-size: 0.75rem;
    }

    .imagecard-entreprise {
        width: 100%;
    }

    .imagecard-entreprise img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }
}
.accueil-button-ecole{
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}

.contactez-nous-button-ecole {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
    margin-top: 3rem;
}

.accueil-button-ecole .p-button{
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
    margin-top: 2rem;
}

.contactez-nous-button-ecole .p-button {
    background-color: var(--terra-cotta);
    color: white;
}


.savoirplus-ecole-container{
    margin: 0 10rem;
}

.savoirplus-ecole-head{
    text-align: center;
}

.savoirplus-ecole-head{
    margin: 0;
}

h2.text-huge-ecole{
    font-weight: 400;
}

.savoirplus-ecole-container .p-card span {
    text-align: center;
    vertical-align:text-bottom;
 }

.imagecard-ecole {
   text-align: center;

}

.imagecard-ecole img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

.text-huge-ecole {
color: var(--indigo-dye);
font-size: 2rem;
line-height: 3rem;
}

.text-description-left {
    color: var(--indigo-dye);
    text-align: left;
    font-size: 22px;
}

.text-description-right {
    color: var(--terra-cotta);
    text-align: left;
    font-size: 22px;
}

.text-descriptions {
    width: 100%;
}

@media screen and (max-width:992px) {
    .savoirplus-ecole-container{
        margin: 0;
    }

    .text-huge-ecole {
        line-height: 2.50rem;
        font-size: 1.25rem;
        text-align: center;
        }
     
    .savoirplus-ecole-container{
            margin: 0;            
        }
        
    .savoirplus-ecole-head{
        font-size:0.75rem;
    }   

    .text-description-left {
        font-size: 18px;
        text-align: center;
    }

    .text-description-right {
        font-size: 18px;
        text-align: center;
    }

    .imagecard-ecole {
        width: 100%;
    }

    .imagecard-ecole img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }
}
.advert-privacy-container{
    align-items: center;
}

@media screen and (max-width:992px) {
    .advert-privacy-container{
        align-items: normal;
    }
}

.fai-selectbutton .p-button {
    border-radius: 5px;
    border: 1px solid var(--terra-cotta);
    color: var(--terra-cotta);
}

.p-fluid .fai-selectbutton .p-button {
    width: auto;
    flex: none;
    padding: 0.5rem 1rem;
}

.fai-selectbutton .p-button.p-highlight:hover {
    background-color: var(--terra-cotta);
    border-color: var(--terra-cotta);
    color: white;
}

.fai-align-self-end {
    align-self: flex-end;
}

.p-field .fai-label-with-tooltip {
    align-items: center;
    display: flex;
}

.fai-loading-container {
    text-align: center;
    height: 100%;
}

.fai-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.fai-infotext {
    margin-left: 1rem;
    color: grey;
}

.fai-title-and-select-container{
    align-items: center;
}

.fai-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}

.fai-competence-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fai-item>span{
    padding-left: 0.5rem;
}

.fai-langue-btn.p-button{
    width: auto;
}

@media screen and (max-width:992px) {
    .fai-infotext {
        margin-left: 0rem;
    }

    .fai-title-and-select-container{
        align-items: normal;
    }
}
.cc-italic-text >a {
    color: var(--terra-cotta);
    text-decoration: underline;
}
.peso-infotext {
    color: grey;
}

.peso-infotext a {
    color: var(--resolution-blue);
    font-weight: bold;
    font-style: normal;
    -webkit-text-decoration: solid underline var(--resolution-blue);
            text-decoration: solid underline var(--resolution-blue);
}
.ai-main-container {
    background-color: var(--pastel-blue);
    border-radius: 10px;
    padding: 0 1rem;
}
.p-field-checkbox > label.ffof-no-margin-left{
    margin-left: 0;
}

.ffof-btn-cancel{
    text-align: end;
}

.ffof-btn-cancel .p-button{
    width: auto;
    color: var(--terra-cotta);
    border: 1px solid var(--terra-cotta);
}

.ffof-btn-submit .p-splitbutton{
    width: auto;
}

.ffof-btn-submit .p-splitbutton .p-splitbutton-defaultbutton{
    flex: none;
    width: auto;
}

.ffof-btn-submit .p-button{
    color: white;
    background-color: var(--terra-cotta);
    
}

@media screen and (max-width:992px) {
    .ffof-btn-cancel{
        text-align: center;
        margin-bottom: 0.5rem;
    }
    .ffof-btn-submit{
        text-align: center;
    }
}
.wysiwyg-sb {
    margin: auto;
    white-space: nowrap;
    margin-right: 1rem;
    margin-left: 0rem;
}

.wysiwyg-sb.p-buttonset .p-button:first-of-type{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.wysiwyg-sb.p-buttonset .p-button:last-of-type{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

@media screen and (max-width:992px) {
    .wysiwyg-sb {
        margin-right: auto;
        margin-left: auto;
    }
}
.card-for-ecole-header .p-card-body {
    padding: 0;
}

.card-for-ecole-header .p-card-body .p-card-content {
    padding: 0;
}

.vof-card .p-card-body {
    padding: 0.5rem 1rem;
}

.vof-card .p-card-body .p-card-content {
    padding: 0;
}

.vof-ecole-header {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
}
.vof-ecole-left-container{
    display: flex;
}

.ecole-info {
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
}


.ecole-global {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: -2rem;
}

.ecole-content {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 1rem;
}

.vof-ecole-title {
    margin: 0
}

.vof-campus-line {
    display: flex;
}

.vof-ecole-socials-container{
    display: flex;
}

.vof-io-logo{
    cursor: pointer;
    font: 1.1rem;
}

@media screen and (max-width:992px) {
    .ecole-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .vof-ecole-header {
        padding: 1rem;
        flex-direction: column;
    }
    .vof-ecole-left-container{
        flex-direction: column;
    }

    .vof-course-container{
        text-align: center;
    }

    .ecole-info {
        padding: 0 1rem;
    }

    .ecole-global {
        margin: -0.5rem;
    }

    .ecole-school {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .ecole-title {
        font-size: 2rem;
    }
}
.nla-container{
    margin-top: 20%;
    text-align: center;
}
.header-background-picture-container {
    display: flex;
    flex-direction: column;
}

.header-background-picture {
    width: 100%;
    max-height: 20rem;
    object-fit: cover;
}

.header-background-picture-border {
    background-color: var(--whisper);
    margin-top: -3.5rem;
    content: '';
    border-radius: 50% 50% 0% 0% / 60% 60% 0% 0%;
    padding: 2.5rem;
}

@media screen and (max-width:992px) {
    .header-background-picture-border {
        margin-top: -2rem;
        border-radius: 50% 50% 0% 0% / 20% 20% 0% 0%;
        padding: 2rem;
    }
}

.chat-loading{
    text-align:center;
    margin-top: 10rem;
}

.chat-hidden{
    display:none;
}

.chat-box-msg{
    flex : auto;
}
.chatbox-main .p-dataview-header{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.chatbox-header{
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}


.chatbox-header-info-container{
 display: flex;
 flex-direction: row;
}

.chatbox-header-info{
    padding-left: 1rem;
   }


.chatbox-nom{
    color: var(--indigo-dye);
}

.thread-list-back{
    cursor: pointer;
    display: none;
}

.chatbox-nom-comp{
    
    text-transform: uppercase;
    font-weight: normal;
}

@media screen and (max-width:992px) {
    .thread-list-back{
        display:block;
    }
}


.chatbox-emplois{
font-size: small;
}

.chatbox-conv{
    height: 600px;
    min-height: 600px;

}

.chatbox-conv > div > div 
{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

/* .chatbox-composite{

} */
.avatar-renderer-container-2 img {
    object-fit: cover;
    border: 1px solid var(--opal);
}

.avatar-renderer-container img {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--eerie-black);
    height: 3rem;
    width: 3rem;

}

.avatar-renderer-container .avatar-renderer-initials {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--eerie-black);
    height: 3rem;
    width: 3rem;
}
.avatar-renderer-initials{
    font-size: 1.5rem;
    text-align: center;
}
.avatar-renderer-initials>div{
    padding-top: 0.5rem;
}

.avatar-renderer-green{
    background: rgba(34, 197, 94, 0.04);
    color: #22C55E;
}

.avatar-renderer-blue{
    background: rgba(59, 130, 246, 0.04);
    color: #3B82F6;
}

.avatar-renderer-yellow{
    background: rgba(245, 158, 11, 0.04);
    color: #F59E0B;
}

.avatar-renderer-purple{
    background: rgba(168, 85, 247, 0.04);
    color: #A855F7;
}

.avatar-renderer-red{
    background: rgba(239, 68, 68, 0.04);
    color: #EF4444;
}
.chatbar-main .p-dataview-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.chatbar-main .p-paginator-bottom {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}


.chatbar-main .p-dataview-content {
    min-height: 600px;
}

.chatbar-main .p-dataview-emptymessage {
    text-align: center;
}

.message-bar-m {
    min-height: 4rem;
}

.message-bar-warn {
    margin-top: 10px;
    text-align: center;
    color: red;
}

.thread-list-item {
    cursor: pointer;
    margin: 0;
}

.thread-list-item:hover {
    background-color: var(--opal-light);
}

.thread-list-item:active {
    background-color: var(--opal-50);
}

.thread-list-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.thread-list-picture {
    padding: 15px;
}

.thread-list-info {
    padding-top: 20px;
    min-height: 5rem;
   
}

.thread-info-progressbar{
    width: 8rem;
    height: 6px;
}

.thread-list-info .thread-interlocutor-name {

    color: var(--indigo-dye);
    font-weight: bold;
}

.thread-list-info .thread-interlocutor-name-comp {

    text-transform: uppercase;
    font-weight: normal;

}

.thread-list-arrow {
    display: flex;
    margin: auto 0;
}
.sf-time-dropdown{
    align-self: center;
    text-align: right;
}
/* theme.css */
.p-tabview.r-title-content .p-tabview-nav {
    border: none;
}

.p-tabview.r-title-content .p-tabview-nav li .p-tabview-nav-link {
    font-size: 20px;
    border-width: 0 0 5px 0;
    font-weight: 700;
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li .p-tabview-nav-link {
    color: var(--periwinkle-blue);
    border-color: var(--periwinkle-blue);
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: var(--resolution-blue);
    border-color: var(--resolution-blue);
}

.p-tabview.r-title-content.hal-entreprise .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: var(--periwinkle-blue);
    border-color: var(--periwinkle-blue);
}
/* /theme.css */

/* container */
.frs-style .p-fieldset {
    border-radius: 1rem;
}

.container-register h1 {
    font-family: var(--font-family-poppins);
    font-weight: 700;
    font-size: 3rem;
}

.container-register li,
.container-register p {
    font-family: var(--font-family-poppins);
    font-weight: 300;
}

.ss-container-register p {
    font-size: 2rem;
    font-weight: 500;
}

.ss-container-register {
    width: 100%;
}

.container-register img {
    width: 27rem;
    padding-top: 2rem;
}
/* /container */

/* connexion card */
.r-container-card {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    margin-top: -2%;
    padding: 0.5rem 1rem 0rem 1rem;
}

.r-container-card.hal-entreprise{
    background: var(--pastel-blue);
    color: var(--resolution-blue);
}

.r-container-card.hal-etudiant{
    background: var(--link-water);
    color: var(--resolution-blue);
}

.r-form-container .hal-etudiant .p-tabview.r-title-content .p-tabview-nav li .p-tabview-nav-link {
    font-size: 0.9rem ;
}

.r-form-container{
    min-width: 20.4rem;
}
/* /connexion card */

@media screen and (max-width:992px) {

    .container-register {
        display: flex;
        flex-direction: column;
    }
    
    .container-text-register{
        width: 100%;
    }
    
    .frs-style {
        justify-content: center;
    }

    .ss-container-register{
        width: 100%;
    }
    .ss-container-register p{
      font-size: 1.5rem;
    }
    .ss-container-register h1{
      font-size: 2.2rem;
    }

    .container-register div:nth-child(2){
        text-align: center;
        margin-bottom: 2rem;
    }

    .container-register img {
        padding: 0;
        width: 14rem;
    }
    
}
.center {
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    background: var(--primary-color);
}

.fl-form .p-inputtext:enabled:focus {
    box-shadow: none;
}

.p-button-rounded.center {
    color: var(--white);
    border-radius: 10px;
}

.label-margin {
    margin-left: 1rem;
    margin-right: 1rem;
}

.btn-linkedin {
    width: auto;
    background-color: #1c78bd;
}

.logo-linkedin {
    height: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.login-button-icon svg {
    margin-right: 0.5rem;
}
.fl-form.fl-entreprise >div>label:nth-child(1){
    color:var(--resolution-blue);
}

.fl-form >div>label:nth-child(2){
    color:var(--terra-cotta);
}

.fl-form>div>label{
    font-weight: 700;
}
.fr-form .p-inputtext:enabled:focus {
    box-shadow: none;
}

.p-overlaypanel.hal-etudiant {
    background: var(--link-water);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); 
    border-radius: 10px;
}

.field-margin{
    margin: 1rem 0rem;
}

.frs-button.p-button.frs-etudiant{
    background-color: var(--primary-color);
    width: auto;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
}

.frs-button .p-splitbutton-defaultbutton{
    background-color: var(--primary-color);
    width: auto;
}

.frs-button .p-button.p-button-icon-only{
    background-color: var(--primary-color);
}

.frs-card{
    margin-top: -1em;
}

.frs-submit{
    display: flex;
    justify-content: center;
}

.general-conditions{
    color: black;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

/* theme.css style */

.p-overlaypanel.hal-entreprise {
    background: var(--pastel-blue);
    color: var(--resolution-blue);
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-tabview.hal-entreprise .p-tabview-nav li.p-highlight .p-tabview-nav-link  {
    border-color: var(--resolution-blue);
    color: var(--resolution-blue);
}

/* /theme.css style */

.fr-form.frs-entreprise >div>label:nth-child(1){
    color:var(--resolution-blue);
}

.fr-form >div>label:nth-child(2){
    color:var(--terra-cotta);
}

.fr-form>div>label{
    font-weight: 700;
}

.frs-button.p-button.frs-entreprise{
    background-color: var(--terra-cotta);
    width: auto;
    color: white;
    border: 1px solid var(--terra-cotta);
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
}

.fr-required-fields{
    color:var(--resolution-blue);
    font-weight: 500;
    text-align: end;
}

.fr-required-fields >span{
    color:var(--terra-cotta);
}

.fr-general-condition a.general-conditions {
    color:var(--resolution-blue);
}

.fr-general-condition a.general-conditions >span{
    color:var(--terra-cotta);
}
.ie-loader-container{
    position: absolute;
    display: flex;
    text-align: center;
    place-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.landing-page {
  flex: 1 1;
  font-family: "Inter", sans-serif;
}

.lpe-title {
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 3rem;
}

.lpe-section {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-wrapper {
  width: 100%;
  padding: 5rem 1rem;
  position: relative;
  /* overflow: hidden; */
}

.background-image {
  position: absolute;
  inset: 0;
  background-image: url("https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/iStock-1085606706_2-drPNxmocLJZCvnmLg8dkPzPQvA7uEF.jpg");
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.background-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9), /* Couche blanche semi-transparente */
      rgba(255, 255, 255, 0)
    ),
    rgba(0, 0, 0, 0.3); /* Couche sombre atténuée */
  z-index: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  margin: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

@media (max-width: 992px) {
  .section-wrapper {
    padding-top: 3rem;
  }
  .content-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lpe-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 640px) {
  .section-wrapper {
    padding-top: 2rem;
  }
  .content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lpe-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lpe-title {
    font-size: 1.5rem;
  }
}

/* Titre principal */
.main-title {
  font-size: 4.5rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-top: 0%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #00237c 25%,  /* Bleu foncé légèrement plus étendu */
    #6c63d0 50%,  /* Violet adouci, au centre */
    #5a67d8 75%   /* Violet clair commence plus tôt */
  );
  text-align: left;
  padding-left: 0;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem;
  }
}

/* Description */
.description {
  color: #f0f0f0;
  font-size: 1.125rem;
}

/* Texte en gras (strong) avec styles spécifiques */
.highlight-text {
  max-width: 650px; /* max-w-[650px] */
  color: #4b5563; /* text-gray-600 */
  font-size: 1.125rem; /* text-lg */
  margin-top: 1.5rem; /* mt-6 */
}

@media (min-width: 768px) { /* md:text-xl */
  .highlight-text { 
    font-size: 1.25rem; /* text-xl */
  }
}

body.dark .highlight-text {
  color: #9ca3af; /* dark:text-gray-400 */
}

/* Boutons */
.button-group {
  margin: 1rem 0;
}

.cta-button {
  background-color: #ed6a5a;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
}

.cta-button:hover {
  background-color: #d15a4b;
}

/* Texte d'aide */
.helper-text {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
}

/* Grille d'images */
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colonnes par défaut */
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
}

@media (min-width: 640px) { /* sm:grid-cols-4 */
  .image-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 colonnes pour écrans larges */
  }
}

/* Conteneur des images */
.image-wrapper {
  position: relative;
  height: 200px; /* h-[200px] */
  border-radius: 8px; /* rounded-lg */
  /* overflow: hidden; */
}

/* Images par défaut et en hover */
.default-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.hover-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Effet de survol */
.image-wrapper:hover .default-image {
  opacity: 0;
}

.image-wrapper:hover .hover-image {
  opacity: 1;
}

/* Card Section */
.info-card {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #00237c;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 15px rgba(0, 35, 124, 0.3); 
  /* opacity: 0.8; */
}

.info-title {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: black;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-icon {
  margin-right: 0.5rem;
  color: #00237c;
  font-size: 1.25rem;
}

.outline-button {
  margin-top: 1rem;
  background-color: transparent;
  color: #00237c;
  border: 1px solid #00237c;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
}

.outline-button:hover {
  background-color: #00237c;
  color: #fff;
}

.lpe-custom-dialog-header {
  text-align: center;
  color: var(--resolution-blue);
  font-size: 20px;
  font-weight: bold;
}







/* Section 02 */

#comment-ca-marche {
  border: 2px dashed red;
  background-color: rgba(255, 0, 0, 0.1); /* Facilement visible */
}

/* Section principale */
.how-it-works-section {
  visibility: visible !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
          transform: none !important;
}

/* Conteneur */
.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 1rem; /* px-4 */
}

.section-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

.section-description {
  text-align: center;
  font-size: 1.125rem; /* text-lg */
  color: #4b5563; /* text-gray-600 */
  margin-bottom: 2rem; /* mb-8 */
}

body.dark .section-description {
  color: #9ca3af; /* dark:text-gray-400 */
}

.steps-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

@media (min-width: 640px) {
  .steps-grid {
    grid-template-columns: repeat(2, 1fr); /* sm:grid-cols-2 */
    grid-gap: 2.5rem;
    gap: 2.5rem; /* sm:gap-10 */
  }
}

@media (min-width: 1024px) {
  .steps-grid {
    grid-template-columns: repeat(3, 1fr); /* lg:grid-cols-3 */
  }
}

/* Step Card */
.step-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 21rem;
  margin: auto;
  min-height: 300px;
  justify-content: space-between;
}

.step-circle {
  background-color: #00237c;
  color: #fff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

body.dark .step-circle {
  background-color: #00237c;
}

.step-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  min-height: 2.5rem;
  line-height: 1.2;
}

.step-description {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  text-align: center;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

body.dark .step-description {
  color: #9ca3af; /* dark:text-gray-400 */
}

/* CTA Button */
.cta-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem; /* mt-12 */
}

.cta-button {
  background-color: #ed6a5a;
  color: white;
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem; /* py-3 px-6 */
}

.cta-button:hover {
  background-color: #d15a4b;
}








/* troisème section */

/* Section principale */
.pricing-section {
  width: 100%;
  padding: 4rem 1rem;
  background: linear-gradient(to bottom right, #e3f2fd, #f3e5f5);
  font-family: "Inter", sans-serif;
}

/* Conteneur global */
.pricing-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
}

/* Texte et bouton */
.pricing-content {
  flex: 1 1;
}

.pricing-title {
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

.pricing-description {
  color: #6b7280;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.pricing-features {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  /* justifier le texte à gauche */
  text-align: left;
  margin-top: 1rem;
}

.pricing-feature {
  display: flex;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
}

.pricing-feature p {
  margin-top: 0;
}

.pricing-icon {
  width: 32px;
  height: 32px;
}

.pricing-note {
  color: #6b7280;
  font-size: 1rem;
  margin-top: 1rem;
}

.pricing-button-wrapper {
  margin-top: 2rem;
}

.pricing-button {
  background-color: #ed6a5a;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  border: none;
  border-radius: 4px;
}

.hubspot-button {
  margin-top: 1.5rem;
  display: flex;
  justify-self: center;
}

.pricing-button:hover {
  background-color: #d15a4b;
}

/* Image */
.pricing-image-wrapper {
  flex: 1 1;
  text-align: center;
}

.pricing-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column; /* Colonne pour mobile/tablette */
    align-items: center;
    text-align: center;
  }

  .pricing-content {
    order: 1; /* Le texte reste en premier */
  }

  .pricing-image-wrapper {
    order: 2; /* L'image passe en dessous */
  }
}





/* Quatrième section */

/* Section principale */
.testimonials-section {
  width: 100%;
  padding-bottom: 1rem;
  background-color: #ffffff;
  color: #333333;
  font-family: "Inter", sans-serif;
}

/* Conteneur principal */
.testimonials-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

/* Titre */
.testimonials-title {
  font-weight: bold;
  margin-bottom: 2rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

/* Grille */
.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

@media (min-width: 640px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .testimonials-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Cartes */
.testimonial-card {
  background-color: #e6e9f0;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Image */
.testimonial-image-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Texte */
.testimonial-name {
  font-size: 1.125rem;
  font-weight: bold;
}

.testimonial-role {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1rem;
}

.testimonial-quote {
  font-size: 1rem;
  font-style: italic;
  color: #333333;
}

/* Bouton */
.testimonials-button-wrapper {
  margin-top: 2rem;
}

.testimonials-button {
  background-color: #ed6a5a;
  color: white;
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
}

.testimonials-button:hover {
  background-color: #d15a4b;
}
.lpe-description img {
    max-width: 70%;
    display: flex;
    place-self: center;
}
.fpo-main{
   box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 1.5rem;
}

.fpo-main h1{
    color: var(--resolution-blue);
    font-weight: 700;
    margin: 0 0 1.5rem 0;
}

.fpo-main>div{
   margin-bottom: 1rem;
}

.fpo-main label{
    color: var(--resolution-blue);
    font-weight: 700;
}

.fpo-main .p-input-icon-right{
   width: 100%;
}

.fpo-main input{
   border: 1px solid var(--resolution-blue);
   border-radius: 5px;
   width: 100%;
}

.fpo-main input::-webkit-input-placeholder{
   font-style: italic;
}

.fpo-main input:-ms-input-placeholder{
   font-style: italic;
}

.fpo-main input::placeholder{
   font-style: italic;
}

.fpo-main .p-dropdown,
.fpo-main .p-multiselect{
   border: 1px solid var(--resolution-blue);
   border-radius: 5px;
   width: 100%;
}

.fpo-main .p-placeholder{
   font-style: italic;
}

.fpo-main .p-multiselect.p-multiselect-chip .p-multiselect-token{
   background: var(--resolution-blue);
   color: white;
}

.fpo-selectbutton.p-selectbutton .p-button{
    border: 1px solid var(--resolution-blue);
    color: var(--resolution-blue);
    border-radius: 5px;
    margin-bottom:0.1rem;
    font-weight: 400;
 }

.fpo-selectbutton.p-selectbutton .p-button.p-highlight{
    background: var(--resolution-blue);
    color: white;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:first-of-type{
    margin-right: 0.5rem;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type){
    margin-right: 0.5rem;
    border-radius: 5px;
 }
 
 .fpo-selectbutton.p-buttonset .p-button{
    padding: 0.2rem 0.8rem;
    font-weight: 400;
 }
 
 .fpo-selectbutton .p-button.p-highlight:hover{
    color: white;
    border-color: #ced4da;
 }
 
 .fpo-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
    background: #e9ecef;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:last-of-type{
 }

.eoet-main-card {
    color: var(--resolution-blue);
    padding: 1.5rem;
}

.eoet-border-card{
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
}

.eoet-top-line{
    display: flex;
    flex-direction: row;
}

.eoet-candidat-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: var(--pastel-blue);
    padding: 1rem 1.5rem 0rem 1.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
}

.eoet-candidat-box-container>div {
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.eoet-candidat-box-container>div>div:first-child {
    font-size: 2rem;
    color: var(--terra-cotta);
    font-weight: 700;
}

.eoet-candidat-box-container>div>div:last-child {
    color: var(--resolution-blue);
    font-weight: 500;
}

.eoet-tag {
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 1rem;
    padding: 0.15rem 0.85rem;
}

.eoet-pointer {
    cursor: pointer;
}

.eoet-description-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size:1rem;
    line-height: 1.5rem;
    height: 7.5rem;
}

.eoet-confirm-dialog{
    text-align: center;
}

@media screen and (max-width:992px) {
    .eoet-top-line{
        flex-direction: column-reverse;
    }

    .eoet-candidat-box-container{
        flex-wrap: wrap;
    }

    .eoet-candidat-box-container>div {
        flex:50% 1
    }
}
.cssb-select-button{
    display: flex;
    justify-content: space-between;
    background-color: var(--pastel-blue);
    border-radius: 5px;
}

.cssb-select-button .p-button-label.p-c>div:first-child{
    font-size: 1.6rem;
    color: var(--terra-cotta);
    font-weight: 700;
}

.cssb-select-button .p-button-label.p-c>div {
    color: var(--resolution-blue);
    font-weight: 500;
    font-size: 0.8rem;
}

.cssb-select-button .p-button{
    background-color: var(--pastel-blue);
    border: none;
    padding: 0.5rem 1rem;
    width:-webkit-fill-available;
}

.cssb-select-button .p-button:focus{
    box-shadow: 0 var(--terra-cotta);
}

.cssb-select-button.p-buttonset .p-button.p-highlight{
    background-color: var(--periwinkle-blue);
    border: 1px solid var(--terra-cotta);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
}

@media screen and (max-width:992px) {
    .cssb-select-button{
        flex-wrap: wrap;
    }

    .cssb-select-button .p-button{
        width:50% ;
    }

    .cssb-select-button .p-button:nth-child(1){
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0;
    }

    .cssb-select-button .p-button:not(:first-of-type):not(:last-of-type):nth-child(2){
        border-top-right-radius: 5px;
    }

    .cssb-select-button .p-button:not(:first-of-type):not(:last-of-type):nth-child(5){
        border-bottom-left-radius: 5px;
    }

    .cssb-select-button .p-button:nth-child(6){
        border-top-right-radius: 0;
        border-bottom-right-radius: 5px;
    }
}
.eo-title{
    margin: 0;
}

.eo-filter-panel-margin{
    margin-top: 4.75rem;
    margin-right:2rem;
    width: 28%;
}

.eo-only-pc{
    display: block;
}

.eo-only-mobile{
    display: none;
}

.p-dialog-content .eo-popup-message{
    padding : 1rem 6rem 1rem 0rem;
}

@media screen and (max-width:992px) {
    .eo-only-pc{
        display: none;
    }

    .eo-only-mobile{
        display: block;
    }
}
.ect-main-card {
    color: var(--resolution-blue);
    padding: 1.5rem;
}

.ect-border {
    color: var(--resolution-blue);
    padding: 1.5rem;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
}


.ect-top-line{
    display: flex;
    flex-direction: row;
}

.ect-candidat-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: var(--pastel-blue);
    padding: 1rem 1.5rem 0rem 1.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
}

.ect-candidat-box-container>div {
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.ect-candidat-box-container>div>div:first-child {
    font-size: 2rem;
    color: var(--terra-cotta);
    font-weight: 700;
}

.ect-candidat-box-container>div>div:last-child {
    color: var(--resolution-blue);
    font-weight: 500;
}

.ect-tag {
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 1rem;
    padding: 0.15rem 0.85rem;
}

.ect-pointer {
    cursor: pointer;
}

.ect-description-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ect-confirm-dialog{
    text-align: center;
}

.ect-input{
    border: 1px solid var(--resolution-blue);
    border-radius: 5px;
    width: 100%;
 }

@media screen and (max-width:992px) {
    .ect-top-line{
        flex-direction: column-reverse;
    }

    .ect-candidat-box-container{
        flex-wrap: wrap;
    }

    .ect-candidat-box-container>div {
        flex:50% 1
    }

    .ect-main-card {
        padding: 0.5rem;
    }

}
.ec-input{
    border: 1px solid var(--resolution-blue);
    border-radius: 5px;
    width: 100%;
 }

 .ec-input::-webkit-input-placeholder{
    font-style: italic;
 }

 .ec-input:-ms-input-placeholder{
    font-style: italic;
 }

 .ec-input::placeholder{
    font-style: italic;
 }
.efc-extended-data-container{
    margin-left: 9rem;
}

.efc-extended-data-container h1{
    font-weight: 600;
    font-size: 1.5rem;
    color:var(--resolution-blue);
}

.efc-extended-data-container h2{
    font-weight: 600;
    font-size: 1.2rem;
    color:var(--resolution-blue);
}

.efc-chip{
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 10px;
}

.efc-notes-container{
    margin-right: 9rem;
}

.efc-return{
    text-decoration: underline;
    color:var(--resolution-blue);
    cursor: pointer;
}

@media screen and (max-width:992px) {
    .efc-extended-data-container{
        margin-left: 0rem;
    }

    .efc-notes-container{
        margin-right: 0rem;
    }
}
.ep-confirm-dialog{
    width: 80vw;
}

.ep-confirm-dialog .p-dialog-title{
    color: var(--terra-cotta);
    text-align: center;
}

.ep-confirm-dialog.p-dialog{
    width: 50%;
}
.ept-title{
    color: var(--terra-cotta);
    font-size: x-large;
    overflow-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ept-description{
    height: 7rem;
}

.ept-description-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1rem;
    line-height: 1rem;
    height: 2.03rem;
}

.ept-tooltip.p-tooltip .p-tooltip-text {
    width: 30rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 0.2s ease-in-out;
}

.ept-button{
    flex-direction: column;
    border: black 1px solid;
    border-radius: 10px;
    width: -webkit-fill-available;
}

.ept-button>div:first-child{
    color : var(--resolution-blue);
    font-size : 0.9rem;
}

.ept-compteur{
    color: var(--terra-cotta);
    font-size: 2rem;
}

@media screen and (max-width:992px) {
    .ept-tooltip.p-tooltip{
        left: 10rem;
    }
    .ept-tooltip.p-tooltip .p-tooltip-text {
        width: 15rem;
    }
}
.fca-name-container{
    justify-content: space-evenly;
}

.fca-name-company{
    margin: 1rem;
}

.fca-name-company>span{
    font-weight: 700;
}

.fca-title-company{
    font-size: 1.4rem;
    font-weight: 700;
    margin: 1rem 0;
}

.fca-container-grid{
    margin-top: 2rem;
}

.fca-border-left>div{
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding: 0.5rem;
    font-weight: 700;
}

.fca-border-bottom{
    border-bottom: 2px solid black;
}

.fca-border-right>div{
    border-top: 2px solid black;
    border-right: 2px solid black;
    padding: 0.5rem;
}

.fca-update-button{
    background-color: var(--terra-cotta);
    color: white;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem 2rem;
    margin: 1rem 0;
}

.fca-dialog-container .p-dialog-header .p-dialog-title{
    font-size: 1.5rem;
    color: var(--terra-cotta);
    text-align: center;
}

.fca-dialog-container.p-dialog .p-dialog-content {
    padding: 0rem 2.5rem 2rem 2.5rem
}

.fca-dialog-container .p-dropdown{
    margin-top: 0.5rem;
    width: auto;
}

.fca-dropdown-container{
    margin : 2rem;
}

.fca-dropdown-contain{
    margin: 1.5rem 0rem;
}

.fca-dropdown-contain>label{
    font-weight: 700;
}

.fca-dropdown-container .p-dropdown .p-dropdown-label{
    padding: 1rem;
}

.fca-button-cancel{
    background-color: var(--white);
    color: var(--resolution-blue);
    border: 1px solid var(--resolution-blue);
    border-radius: 0.3rem;
}

.fca-button-confirm{
    background-color: var(--terra-cotta);
    color: var(--white);
    border: none;
    border-radius: 0.3rem;
}

.fca-multi-column-container{
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    padding: 0.4rem 0.2rem;
    margin-top: 0.5rem;
}

#fca-multi-column-design .ig-input-content{
    padding: 1.125rem 0.5rem;
}

#fca-multi-column-design .ig-multi-column-combo-box.igr-multi-column-combo-box >div>div {
    background-color: transparent !important;
}

.dashboard-menu-theme{
    z-index: 1;
    background-color: var(--indigo-dye);
    width: 15rem;
    color: white;
    margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
  display: flex;
    flex-direction: column;
  position: fixed;
  height: 100%;
}

.dashboard-menu-theme h1{
    text-align: center;
  margin: 0;
  padding: 0.5rem;
  padding-bottom: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative
}

.dashboard-menu-theme h1:before{
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 92%;
    left: 4%;
    bottom: 0;
}


.dashboard-menu-theme a {
    color: white;
    display: flex;
    padding: 0.5rem 1rem;
    text-decoration: none;
    position: relative;
}

.dashboard-menu-theme a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 88%;
    left: 6%;
    bottom: 0;
}

.dashboard-menu-theme a.active{
    background-color: var(--opal);
    color: white;
}

.dashboard-menu-theme a:hover:not(.active) {
    background-color: var(--opal-light);
    color: black;
}

.dashboard-menu-theme a img,.dashboard-menu-theme svg{
    max-height: 1rem;
    min-width: 1rem;
    align-self: center;
}

.dashboard-menu-theme a span{
    padding-left: 0.5rem;
    font-size: small;
}

.dashboard-menu-theme a span.regular-display{
    display: block;
}

.dashboard-menu-theme a span.small-display{
    display: none;
}
@media screen and (max-width: 992px) {
    .dashboard-menu-theme{
        overflow:hidden;
        bottom : 0;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        padding : 0;
        justify-content: space-around;
    }

    .dashboard-menu-theme h1{
        display: none;
    }

    .dashboard-menu-theme a {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.2rem;
        font-size: 0.5rem;
    }

    .dashboard-menu-theme a::before {
        content: normal;
        border: 1px solid white;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    .dashboard-menu-theme a span{
        padding-left: 0rem;
    }

    .dashboard-menu-theme a img,.dashboard-menu-theme svg{
        max-height: 2rem;
        min-height: 2rem;
        min-width: 2rem;
        min-height : 2rem;
        padding-right: 0rem;
        align-self: center;
    }

    .dashboard-menu-theme a span.regular-display{
        display: none;
    }

    .dashboard-menu-theme a span.small-display{
        display: block;
    }


}

@media screen and (min-width: 993px) {
    /* Masquer les éléments lorsque la largeur de l'écran est supérieure à 992px */
    .dashboard-menu-theme-only-mobile {
        display: none;
    }
}
.footer-main-container{
    color:white;
    font-weight:300;
    font-size: 0.8rem;
    padding: 1rem 2rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
}

.footer-logo-container{
    padding:1rem 0;
}
.footer-logo-container img{
    height: 3rem;
}

.footer-over-divider-container{
    display: flex;
    flex-direction: row;
}

.footer-over-divider-container a{
    cursor:pointer;
}

.footer-over-divider-container>div:first-child{
    flex: 45% 1;
    flex-shrink:0;
}

.footer-over-divider-container>div:nth-child(2){
    flex: 30% 1;
    flex-shrink:0;
}

.footer-over-divider-container>div:nth-child(3){
    flex: 25% 1;
    flex-shrink:0;
}

.footer-over-divider-container>div>div{
    padding-bottom: 1rem;
}

.footer-info-container{
    display: flex;
    flex-direction: column;
}
.footer-logo-info-line{
    display: flex;
    flex-direction: row;
}

.footer-logo-info-line svg{
    width: 1.2rem;
    height: 1.2rem;
}

.footer-socials-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer-socials-container svg{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.footer-clickable-link{
    color: white;
    text-decoration: none;
    cursor: pointer;
}


.footer-info-container>div{
    padding-bottom: 1rem;
}

.footer-divider.p-divider-horizontal{
    border-top:1px white solid

}
.footer-divider.p-divider-horizontal::before{
    border-top:1px white solid
}

.footer-below-divider-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-document-container{
    display: flex;
    flex-direction: row;
}

.footer-creator{
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:992px) {

    .footer-info-container{
        flex-direction: column;
    }

    .footer-logo-container{
        text-align: center;
    }

    .footer-over-divider-container{
        flex-direction: column;
    }

    .footer-over-divider-container>div:first-child{
        flex: 0 1;
    }
    
    .footer-over-divider-container>div:nth-child(2){
        flex: 0 1;
    }
    
    .footer-over-divider-container>div:nth-child(3){
        flex: 0 1;
    }

    .footer-socials-container{
        justify-content: center;
    }

    .footer-below-divider-container{
        flex-direction: column;
    }

    .footer-below-divider-container>div:nth-child(2){
        text-align: center;
    }

    .footer-document-container{
        justify-content: space-between;
        padding-bottom: 1rem;
    }


}
.NotACookieOverlay{
    background-color: white;
    text-align: center;  
}

.NotACookieOverlay .ta-left{
    text-align: left;  
}

.cookiesconsent-dialog .p-dialog-header{
    padding: 0;
}

.NotACookieOverlay .p-accordion .p-accordion-header .p-accordion-header-link
{
    padding: 0;
    background-color: white !important;
    border: 0;
}


.NotACookieOverlay .p-accordion .p-accordion-content
{
    border: 0;
    padding: 0;
}

.cookieconsent-header{
    background-color: var(--primary-color);
    text-align: center;
    padding: 1rem;
}

.cookieconsent-header .dialog-title{
    color: white;
}

/* .CookieConsent #rcc-confirm-button{
    color: white;
    }; */

    /* .CookieConsent #rcc-confirm-button{

} */


.NotACookieOverlay .ccb-cancel.p-button{
    color: var(--terra-cotta);
    border:solid 1px var(--terra-cotta);
}


.NotACookieOverlay .ccb-ok.p-button{
    background-color: var(--primary-color);
    width: auto;
    color: white;
}

/* Sidenav menu */
.sidenav {
    height: 100%;
    width: 85%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -86%;
    background-color: white;
    padding-top: 15px;
    transition: left 0.5s ease;
}

.sidenav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Sidenav menu links */
.sidenav a {
    padding: 12px 8px 12px 32px;
    text-decoration: none;
    font-size: 1.2em;
    color: black;
    display: block;
    transition: 0.3s;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.sidenav a:hover {
    color: var(--iris-100);
}

/* Active class */
.sidenav.active {
    left: 0;
}


.burger-icon span {
    display: block;
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

.sidenav .pi {
    font-size: 1em;
    color: black;
    padding-left: 1em;
}

.header-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 60px 32px;
}

.sidenav img {
    padding-left: 0rem;
    height: 1.7rem;
    width: auto;
}

.bm-img-burger-menu {
    padding-left: 0rem;
    height: 1.7rem;
    width: auto;
}

.bm-ul-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.bm-ul-menu li {
    padding: 12px 8px 12px 32px;
    font-size: 1.2em;
    display: block;
    transition: 0.3s;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.bm-ul-menu li a {
    text-decoration: none;
    color: var(--resolution-blue);
}

@media screen and (max-width:560px) {
    .bm-sidebar-container {
        width: 85%;
    }
}


.nhab-mobile-container {
    display: none;
}

.dip-icon-size>.pi {
    font-size: 1.5em;
}

@media screen and (max-width:992px) {

    .nhab-mobile-container {
        display: block;
    }
}
.Header_Auth_Login.p-button{
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
}

.auth-tab-container.p-tabview .p-tabview-nav{
    place-content: center;
    border: none;
}

.auth-tab-container.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.auth-fieldset-nullifier .p-fieldset{
    border: none;
    background: none;
}

.auth-fieldset-nullifier .p-fieldset .p-fieldset-content{
    padding: 0rem;
}

.auth-tab-panel.p-overlaypanel .p-overlaypanel-close {
    background: none;
}

.auth-tab-panel.p-overlaypanel:after {
    border-bottom-color: var(--link-water);
}

.auth-tab-panel.p-overlaypanel:before {
    border-bottom-color: var(--link-water);
}

.auth-tab-panel.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: none;
    color: none;
}

.auth-tab-panel .p-link:focus {
    box-shadow: none;
}
.profile-menu-style.p-menu {
    background: black;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
}

.profile-menu-style.p-menu ul li a span.p-menuitem-text {
    color: white;
}

.profile-header-style {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-header-style .name {
    display: block;
    padding-right: 0.5rem;
}

.profile-menu-style.p-menu ul li a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 84%;
    left: 8%;
    bottom: 0;
}

.profile-header-style img {
    margin: 0.2rem 0;
    height: 2.6rem;
    cursor: pointer;
}

.header-img-profil-etudiant-iris {
    background-color: var(--iris-100);
}

.header-img-profil-etudiant-blue {
    background-color: var(--kashmir-blue);
}

.header-img-profil-etudiant-red {
    background-color: var(--terra-cotta);
}

.header-img-profil-etudiant-iris img,
.header-img-profil-etudiant-blue img,
.header-img-profil-etudiant-red img {
    object-fit: cover;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}

@media screen and (max-width:992px) {
    .profile-header-style .name {
        display: none;
        padding-right: 0;
    }
}
.hab-pc-container,
.hab-pc-container-blue{
    display: flex;
}

.hab-pc-container a,
.hab-pc-container-blue a{
    color: white;
    cursor: pointer;
}

.hab-pc-container-white a{
    color: var(--resolution-blue);
    cursor: pointer;
}

.hab-pc-container-formations-search a{
    color: var(--white);
    cursor: pointer;
}

.hab-pc-container-formations-search{
    border: 2px solid var(--terra-cotta);
    background: var(--terra-cotta);
    border-radius: 0.3rem;
    padding: 0rem 1rem;
}

.hab-pc-container-entreprise{
    justify-content: space-between;
    width: 100%;
}

.hab-high{
    font-weight: 900;
}

.hab-highlight{
    text-decoration: underline;
    font-weight: 900;
}

.hab-mobile-container{
    display: none;
}

.hab-right{
    margin-left: auto;
}

@media screen and (max-width:1226px) {
    .hab-button-see{
        display: none;
    }
}

@media screen and (max-width:992px) {
    .hab-pc-container{
        display: none;
    }

    .hab-pc-container-blue{
        display: none;
    }

    .hab-pc-container-white{
        display: none;
    }
    
    .hab-mobile-container{
        display: block;
    }

    .hab-mobile-right{
        margin-left: auto;
    }
}
.header_logo {
    padding-left: 1rem;
    height: 2.8rem;
    width: auto;
}

.header_logo a {
    padding: 0.1rem;
}

.auth-style {
    padding-right: 1rem;
    display: flex;
}

.header-main-style-iris {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: var(--iris-100);
    z-index: 100;
}

.header-main-style-red {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: var(--terra-cotta);
    z-index: 100;
}

.header-main-style-blue {
    width: 100%;
    height: 4rem;
    background-color: var(--resolution-blue);
    z-index: 100;
}

.header-main-style-white {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 4px 15px rgba(0, 35, 124, 0.15);
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    border: none
}

.header-main-container {
    display: flex;
    flex-wrap: wrap;
    height: 4rem;
    align-items: center;
    padding: 0.5rem 0rem;
    justify-content: space-between;
}

.h-navbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    width: 100%;
}

.active-route {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: var(--eerie-black);
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.navbar__links {
    display: flex;
}

.navbar__link {
    padding: 0 0.3rem;
    font-size: 16px;
    color: inherit;
    padding: 1em;
}


.burger-menu {
    display: none;
}

.h-container-logo-link-compte {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.h-container-link-compte {
    display: flex;
    align-items: center;
}

.h-link-compte-connect-white {
    width: inherit;
}

.app-width-limit .auth-style{
    padding-right: 0;
}

@media screen and (max-width: 992px) {
    .navbar__links {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        padding: 2rem;
        background-color: var(--terra-cotta);
        visibility: hidden;
    }

    .header_logo {
        padding-left: 0rem;
        height: 1.2rem;
        width: auto;
    }

    .auth-style {
        padding-right: 0.5rem;
    }

    .auth-style .dip-btn-white-bg {
        padding: 0.5rem 0.5rem;
        font-size: 0.7rem;
    }

    .show-navbar .navbar__links {
        visibility: visible;
    }

    .navbar__link {
        display: block;
        padding: 1rem;
        font-size: 5vw;
    }

    .navbar__item::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background-color: var(--eerie-black);
    }

    .active-route {
        border: none;
    }

    .navbar__item:last-child::after {
        display: none;
    }

    .navbar__logo {
        display: block;
        width: 100%;
        padding-top: 0.5rem;
    }

    .show-navbar .navbar__logo {
        display: none;
    }

    .navbar__auth {
        display: none;
    }

    .show-navbar .navbar__auth {
        display: block;
    }

    .h-container-logo-link-compte {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        flex-wrap: nowrap;
        padding-left: 1em;
    }

    .h-link-compte-connect-white {
        width: auto;
        width: initial;
    }
}
.rob-dialog{
    width:85vw;
    height: 90vh;
}

.rob-dialog.p-dialog .p-dialog-header{
    padding: 0rem;
}

.rob-dialog.p-dialog .p-dialog-header-icons{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.rob-dialog.p-dialog .p-dialog-footer{
    padding: 0rem 1.5rem;
}

.rob-header{
    font-size: 2rem;
}

.rob-main-container{
    height: 100%;
}

.rob-progress-bar{
    height:0.5rem
}

.rob-link{
    margin: 0.5rem;
}

.rob-link a{
    text-decoration: underline;
    cursor: pointer;
    color:var(--linkedin-blue);
}

.rob-btn-previous.p-button{
    color: var(--terra-cotta);
    border:solid 1px var(--terra-cotta);
}

.rob-font-size{
    font-size: 1.5rem;
}

.rob-contrat-container>div{
    height:33.33333%;
}

.rob-contrat-font-size{
    font-size: 1.5rem;
}

.rob-contrat-font-size.sp-checkbox-label{
    font-size: 1.5rem;
}


.rob-footer-buttons-container{
    display: flex;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    bottom: 1rem;
}

.rob-footer-buttons-container>div{
    margin: 0.5rem 0;
}

.rob-footer-buttons-container img{
    height:2rem
}


.rob-intro-section-img{
    margin: 1rem;
    width: 14rem;
}

.rob-outro-img{
    width:-webkit-fill-available;
}

.rob-outro-bravo{
    font-size: 6rem;
}

.rob-outro-fs{
    font-size: 1.2rem;
}

.rob-outro-help-container{
    font-size: 1.2rem;
    padding: 1rem;

}

.rob-outro-enrich-container{
    margin-bottom: 3rem;
}

.rob-outro-end-button-container{
    display: none;
}

.rob-community-container{
    font-size: 1.4rem;
}

.rob-community-container b{
    font-size: 1.4rem;
}

.rob-community-img-container{
    align-self: center;
}

.rob-community-img-container img{
    align-self: center;
    height: 2rem;
}

.rob-photo-container img{
    height: 8rem;
}

@media screen and (max-width:992px) {
    .rob-header{
        font-size: 1.5rem;
    }

    .rob-intro-section-img{
        margin: 1rem;
        width: 8rem;
    }

    .rob-font-size{
        font-size: 1rem;
    }

    .rob-contrat-font-size{
        font-size: 1.2rem;
    }
    
    .rob-contrat-font-size.sp-checkbox-label{
        font-size: 1.2rem;
    }

    .rob-dialog{
        width:100vw;
    }

    .rob-outro-fs{
        font-size: 1rem;
    }

    .rob-outro-help-container{
        font-size: 1rem;
    }

    .rob-outro-end-button-container{
        display: block;
        padding: 1rem;
        text-align: right;
    }

    .rob-outro-bravo{
        font-size: 3rem;
    }

    .rob-outro-enrich-container{
        margin-bottom: 0rem;
    }


    .rob-community-img-container{
        align-self: auto;
    }

    .rob-footer-buttons-container{
        flex-direction: column-reverse;
    }

    .rob-outro-img{
        display: none;
    }
}
body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --indigo-dye: #04486c;
    --white: #fff;
    --whisper: #FAF8FB;
    --alabaster: #e6ebe0;
    --terra-cotta: #ed6a5a;
    --iris-100: #e34c37;
    --iris-60: #FCECCD;
    --indigo-dye-accessible: #033651;
    --midnight-express: #010035;
    --opal: #9bc1bc;
    --opal-light: #c0d8d5;
    --gray: #818181;
    --eerie-black: #1c1c1c;
    --alto: #E0E0E0;
    --carousel-pink: #F8E0F7;
    --grey-cards: #f4f4f4;
    --double-pearl-lusta: #FCEDCD;
    --jaffa: #F09451;
    --maize: #F6CCA5;
    --burnt-sienna:##EF805D;
    --linkedin-blue: #1c78bd;
    --opal-50: #cde0dd;
    --last-default: #e2e2e2;
    --indigo_bis:#87abb5;
    --periwinkle-blue:#CCD7F5;
    --indigo-blue:#517793;
    --link-water:#F5F8FD;
    --pastel-blue:#EDF1FD;
    --resolution-blue:#00237C;
    --perano: #A5BCF6;
    --royal-blue: #3A37E3;
    --kashmir-blue:#5064a4;
    --green-success:#689F38;
    --swiss-cheese:#fff4b9;
    --rum-swizzle:#F7F8E0;
    --selective-yellow:#ffc735;
    --grey-disabled:lightgray;
    --p-warning:#FBC02D;
    --p-warning-hover:#FAB710;
    --p-success:#689F38;
    --p-success-hover:#5E8F32;
    --p-info:#0288D1;
    --p-info-hover:#027ABC;
    --p-help:#523557;
    --p-help-hover:#8C239E;
    --p-danger:#D32F2F;
    --p-danger-hover:#C02929;
  }
