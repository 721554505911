.fca-name-container{
    justify-content: space-evenly;
}

.fca-name-company{
    margin: 1rem;
}

.fca-name-company>span{
    font-weight: 700;
}

.fca-title-company{
    font-size: 1.4rem;
    font-weight: 700;
    margin: 1rem 0;
}

.fca-container-grid{
    margin-top: 2rem;
}

.fca-border-left>div{
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    padding: 0.5rem;
    font-weight: 700;
}

.fca-border-bottom{
    border-bottom: 2px solid black;
}

.fca-border-right>div{
    border-top: 2px solid black;
    border-right: 2px solid black;
    padding: 0.5rem;
}

.fca-update-button{
    background-color: var(--terra-cotta);
    color: white;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem 2rem;
    margin: 1rem 0;
}

.fca-dialog-container .p-dialog-header .p-dialog-title{
    font-size: 1.5rem;
    color: var(--terra-cotta);
    text-align: center;
}

.fca-dialog-container.p-dialog .p-dialog-content {
    padding: 0rem 2.5rem 2rem 2.5rem
}

.fca-dialog-container .p-dropdown{
    margin-top: 0.5rem;
    width: auto;
}

.fca-dropdown-container{
    margin : 2rem;
}

.fca-dropdown-contain{
    margin: 1.5rem 0rem;
}

.fca-dropdown-contain>label{
    font-weight: 700;
}

.fca-dropdown-container .p-dropdown .p-dropdown-label{
    padding: 1rem;
}

.fca-button-cancel{
    background-color: var(--white);
    color: var(--resolution-blue);
    border: 1px solid var(--resolution-blue);
    border-radius: 0.3rem;
}

.fca-button-confirm{
    background-color: var(--terra-cotta);
    color: var(--white);
    border: none;
    border-radius: 0.3rem;
}

.fca-multi-column-container{
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    padding: 0.4rem 0.2rem;
    margin-top: 0.5rem;
}

#fca-multi-column-design .ig-input-content{
    padding: 1.125rem 0.5rem;
}

#fca-multi-column-design .ig-multi-column-combo-box.igr-multi-column-combo-box >div>div {
    background-color: transparent !important;
}
