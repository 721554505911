/* COLOR */

.dip-color-tc {
    color: var(--terra-cotta)
}

.dip-color-id {
    color: var(--indigo-dye);
}

.dip-color-eb {
    color: var(--eerie-black);
}

.dip-color-lb {
    color: var(--linkedin-blue);
}

.dip-color-white {
    color: white;
}
.dip-color-grey {
    color: grey;
}

.dip-color-res-blue{
    color:var(--resolution-blue)
}

.dip-color-pas-blue{
    color:var(--pastel-blue)
}

/* BACKGROUND COLOR */

.dip-bg-color-tc {
    background-color: var(--terra-cotta);
}

.dip-bg-color-eb {
    background-color: var(--eerie-black);
}

.dip-bg-color-whisper {
    background-color: var(--whisper);
}

.dip-bg-color-al {
    background-color: var(--alabaster);
}

.dip-bg-color-white {
    background-color: white;
}

.dip-bg-color-pb{
    background-color: var(--pastel-blue);
}

.dip-bg-color-res-blue{
    background-color: var(--resolution-blue);
}

/* FLEX */

.dip-flex {
    display: flex;
}

.dip-block {
    display: block;
}

@media screen and (max-width:992px) {
    .dip-flex {
        flex-direction: column;
    }
}

.dip-flex-row {
    display: flex;
    flex-direction: row;
}

.dip-flex-column {
    display: flex;
    flex-direction: column;
}

.dip-inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}
.dip-inline-flex-row {
    display: inline-flex;
    flex-direction: row;
}

.dip-flex-grow-1 {
    flex-grow: 1;
}

/* ALIGNEMENT */

.dip-ta-center {
    text-align: center;
}

.dip-ta-left{
    text-align: left;
}

.dip-ta-right {
    text-align: right;
}

.dip-ta-justify{
    text-align: justify;
}

.dip-pc-center {
    place-content: center;
}

.dip-ai-center {
    align-items: center;
}
.dip-ai-start {
    align-items: flex-start;
}
.dip-ai-end {
    align-items: flex-end;
}

.dip-as-center {
    align-self: center;
}

.dip-jc-center {
    justify-content: center;
}

.dip-jc-between {
    justify-content: space-between;
}

.dip-jc-around {
    justify-content: space-around;
}

.dip-jc-end {
    justify-content: end;
}

.dip-ps-end{
    place-self: end;
}

/* MARGIN */

.dip-m-0 {
    margin: 0;
}

.dip-mt-0 {
    margin-top: 0 !important;
}

.dip-mb-0 {
    margin-bottom: 0 !important;
}

.dip-ml-auto{
    margin-left: auto;
}

.dip-mt-auto{
    margin-top: auto;
}


.dip-ml-0 {
    margin-left: 0 !important;
}


.dip-mr-0 {
    margin-right: 0 !important;
}

/* PADDING */

.dip-p-0{
    padding: 0 !important;
}

/* HOVER */

.dip-hover-color-tc:hover {
    cursor: pointer;
    color: var(--terra-cotta);
}

/* HEIGHT */

.dip-h-100 {
    height: 100%;
}

.dip-h-auto {
    height: auto;
}

/* WIDTH */

.dip-w-100 {
    width: 100%;
}

.dip-w-auto {
    width: auto;
}

/* FONT-FAMILY */

.dip-ff-muslish{
    font-family: var(--font-family-mulish);
}

.dip-ff-poppins{
    font-family: var(--font-family-poppins);
}

/* FONT-SIZE */

.dip-fs-2 {
    font-size: 1.5rem;
}

.dip-fs-3 {
    font-size: 2rem;
}

.dip-fs-3 {
    font-size: 2.5rem;
}

.dip-fs-4 {
    font-size: 3rem;
}

/* FONT-WEIGHT */

.dip-fw-400{
    font-weight: 400;
}

.dip-fw-500{
    font-weight: 500;
}

.dip-fw-600{
    font-weight: 600;
}

.dip-fw-700{
    font-weight: 700;
}



.dip-pointer {
    cursor: pointer;
}

/* DISPLAY */

.dip-only-pc {
    display: flex !important;
}

.dip-only-mobile {
    display: none !important;
}

.dip-disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* VISIBILITY */

.dip-visibility-hidden {
    visibility: hidden;
} 

.dip-display-none {
    display: none !important;
}

@media screen and (max-width:992px) {
    .dip-only-pc {
        display: none !important;
    }

    .dip-only-mobile {
        display: flex !important;
    }
}