ul{
    margin-block-start: 0;
    margin-block-end: 0;
}

img.thumbnail{
    height: 0.75rem;
    width: 0.75rem;
    align-self: center;
    margin-right: 0.5rem;
} 

.margin-formation-campus{
    margin: 0.5rem 1rem;
    background-color: white;
    border-radius: 0.6rem;
}

.check-button{
    border: solid 1px var(--primary-color);
    padding: 0.25rem 0.5rem;
    font-size: small;
 }

 .check-button i{
    font-size: 1em;
 }

 .check-button > label{
     display: flex;
     flex-direction: row;
     align-items: center;
     font-weight: 500;
 }

.label-list-margin-left{
    margin-left: 0.5rem;
}
.label-list{
    font-weight: 500;
}

.p-divider.p-divider-horizontal {
    border-top: 1px var(--indigo-dye);
}



/* Fix les items non affiché précédent et suivant qui prennent de la place dans le carousel*/
/*begin*/
.formation-carousel .p-carousel-item:not(.p-carousel-item-active){
    width: 0;
}
.formation-carousel .p-items-hidden .p-carousel-item{
    visibility: visible;
}
/*end*/

.formation-carousel .p-carousel-prev.p-link{
    margin:0.1rem;
}
.formation-carousel .p-carousel-next.p-link{
    margin:0.1rem;
}

.p-carousel-prev.p-link.p-disabled{
    visibility:hidden;
    
}
.p-carousel-next.p-link.p-disabled{
    visibility:hidden;
}

/* HORIZONTAL */

.formation-thumbnail-grid-horizontal{
    display: flex;
    flex-direction: row;
    width:100%;
    padding:0.5rem;
 }

 .formation-thumbnail-grid-horizontal-min-height{
    min-height: 10rem;
 }

 .formation-thumbnail-grid-horizontal .formation-logo-container{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    padding:0.5rem;
}

.formation-thumbnail-grid-horizontal .formation-logo-container img{
    width: 9rem;
    height: auto;
}

.formation-school-container{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    font-size: 0.9rem;
    padding:0.5rem;
    flex-basis: 35%;
    flex-grow: 1;
}

.indigo-dye-title{
    margin:0;
    font-size: small;
    font-weight: 500;
    color : var(--indigo-dye);
    padding: 0.3rem 0rem;
}

.indigo-dye-light-title{
    margin:0;
    font-size: small;
    font-weight: 400;
    color : var(--indigo-dye);
    padding: 0.3rem 0rem;
}



.light-title{
    margin:0;
    font-size: small;
    font-weight: 400;
    padding: 0.3rem 0rem;
}

.formation-school-container > h1{
    margin:0;
    font-size: small;
    color : var(--indigo-dye);
    padding: 0rem;
}

.formation-school-container > label{
    margin:0;
    padding: 0.3rem 0rem;
}

.formation-school-container i{
    font-size: 0.6rem;
}


.formation-campus-info{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: center;
    font-size: small;
    flex-basis: 35%;
    padding:0.3rem;
    flex-shrink: 1;
}

.formation-campus-info > div{
    padding:0.2rem;
}

.formation-thumbnail-grid-horizontal .formation-actions-info{
    display: flex;
    align-self: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-basis: 10%;
    flex-shrink: 1;
    height: 100%;
}


.formation-actions-info .formation-elipsis-menu.p-button-icon-only:focus{
    box-shadow:none;
}

.formation-actions-info-button-container{
    padding: 0.5rem 0;
    height: 100%;
    display : flex;
    flex-direction: column;
}


 /* VERTICAL */

 .formation-thumbnail-grid-vertical{
    display: flex;
    flex-direction: column;
    min-height: 30rem;
 }

 /* REORDER */
 .formation-thumbnail-grid-vertical  > div:nth-child(5){
    order : 1;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(1){
    order : 2;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(2){
    order : 3;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(3){
    order : 4;
 }
 .formation-thumbnail-grid-vertical  > div:nth-child(4){
    order : 5;
 }



.formation-actions-info-carre {
    display: flex;
    flex-direction: column;
}

.formation-actions-info-carre div {
    align-self: center;
}

 .formation-thumbnail-grid-vertical .formation-actions-info{
    display: flex;
    align-self: flex-end;
    justify-content: center;
    flex-shrink: 1;
    flex-wrap: nowrap;
 }

 .formation-thumbnail-grid-vertical .formation-logo-container{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    flex-shrink: 1;
    padding:0.5rem;
}
.formation-thumbnail-grid-vertical .formation-school-container{
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: flex-start;
}
.formation-thumbnail-grid-vertical .formation-campus-info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
}

.formation-thumbnail-grid-vertical .formation-logo-container img{
    height: 6rem;
    width: 100%;
}



@media screen and (max-width:992px) {
    .formation-thumbnail-grid-horizontal  > div:nth-child(5){
        order : 1;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(1){
        order : 2;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(2){
        order : 3;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(3){
        order : 4;
     }
     .formation-thumbnail-grid-horizontal  > div:nth-child(4){
        order : 5;
     }

     .formation-thumbnail-grid-horizontal{
        display: flex;
        flex-direction: column;
        min-height: 10rem;
        padding:0.5rem;
     }

     .formation-thumbnail-grid-horizontal .formation-actions-info{
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
     }
    
     .formation-thumbnail-grid-horizontal .formation-logo-container{
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        padding:0.5rem;
    }

    .formation-thumbnail-grid-horizontal .formation-logo-container img{
        height: 6rem;
        width: 100%;
    }
}

@media screen and (max-width:750px) {
    .margin-formation-campus{
        border-radius: 1rem;
        margin: 0.5rem 0rem;
    }
}
