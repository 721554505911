.menu-small-pop-up.p-menu{
    background: black;
    padding-bottom:1rem;
    border-radius: 0.5rem;
}

.menu-small-pop-up.p-menu ul li a span.p-menuitem-text{
    color: white;
}

.menu-small-pop-up.p-menu ul li a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 84%;
    left: 8%;
    bottom: 0;
}
