
/* theme.css style */

.p-overlaypanel.hal-entreprise {
    background: var(--pastel-blue);
    color: var(--resolution-blue);
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-tabview.hal-entreprise .p-tabview-nav li.p-highlight .p-tabview-nav-link  {
    border-color: var(--resolution-blue);
    color: var(--resolution-blue);
}

/* /theme.css style */

.fr-form.frs-entreprise >div>label:nth-child(1){
    color:var(--resolution-blue);
}

.fr-form >div>label:nth-child(2){
    color:var(--terra-cotta);
}

.fr-form>div>label{
    font-weight: 700;
}

.frs-button.p-button.frs-entreprise{
    background-color: var(--terra-cotta);
    width: auto;
    color: white;
    border: 1px solid var(--terra-cotta);
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
}

.fr-required-fields{
    color:var(--resolution-blue);
    font-weight: 500;
    text-align: end;
}

.fr-required-fields >span{
    color:var(--terra-cotta);
}

.fr-general-condition a.general-conditions {
    color:var(--resolution-blue);
}

.fr-general-condition a.general-conditions >span{
    color:var(--terra-cotta);
}