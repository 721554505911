.select-button-centering {
   text-align: center;
   margin-bottom: 1rem;
}

.view-formation-action-buttons {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   position: absolute;
   right: 1rem;
}

.vf-panel-title .p-tabview-nav {
   font-family: var(--font-family-mulish);
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 25px;
   text-align: center;
   letter-spacing: 0.747641px;
}

.fomation-mention-parcours {
   align-self: center;
}

.cards-override-picture {
   margin-top: -12rem;
}

.p-scrollpanel.custombar {
   width: 100%;
   height: 8em;
}

.p-scrollpanel.custombar .p-scrollpanel-bar {
   background-color: var(--opal-light);
   opacity: 1;
   transition: background-color .2s;
}

.p-scrollpanel.custombar .p-scrollpanel-content {
   overflow-x: auto;
}


.tuile-formation .p-carousel-item:not(.p-carousel-item-active) {
   width: 0px !important;
   height: 0px !important;
}

.accordion-for-formation {
   margin-bottom: 1rem;
   font-family: var(--font-family-mulish);
   font-weight: 600;
}

.accordion-for-formation .p-accordion-header .p-accordion-header-link {
   background-color: #ffffff;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.accordion-for-formation .p-accordion-header.p-highlight {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: #ffffff;

}

.accordion-for-formation .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: #ffffff;
   border-color: transparent;
}

.accordion-for-formation .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid black;
   color: black;
}


.accordion-for-formation .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 1px solid black;
   color: black;
}

.accordion-for-formation .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   overflow: auto;
}


.accordion-for-formation .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: white;
}

.accordion-for-formation .p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: white;
}

.select-button-skills .p-button.p-highlight {
   background-color: var(--opal-light);
}

.select-button-skills .p-button.p-highlight:hover {
   background-color: var(--opal-light);
}

.select-button-skills .p-button {
   border-radius: 2rem;
}


.no-padding .p-card-body {
   padding: 0%;
}

.like-button-formation {
   text-align: right;
   padding: 0.5rem;
}

.formation-content {
   padding: 1rem;
}

.formation-global {
   display: flex;
   flex-direction: column;
   overflow-x: hidden;
   margin: -2rem;
   background-color: white;
}

.view-formation-pc-display {
   display: block;
}

.view-formation-mobile-display {
   display: none;
}

@media screen and (max-width:992px) {
   .formation-content {
      padding-left: 1rem;
      padding-right: 1rem;
   }

   .cards-override-picture {
      margin-top: -12rem;
   }

   .formation-global {
      margin: -0.5rem;
   }

   .p-scrollpanel.custombar {
      height: auto;
   }

   .view-formation-pc-display {
      display: none;
   }

   .view-formation-mobile-display {
      display: block;
   }
}