.cs-caption-formation-desc{
    color: var(--iris-100);
}

.cs-multi-select-competences{
    width: 90%;
}

.cs-multi-select-competences.p-multiselect.p-multiselect-chip .p-multiselect-token{
    border: 1px solid var(--midnight-express);
    color: var(--midnight-express);
    background: white;
    border-radius: 0.8rem;
    padding: 0.2rem 0.8rem;
}

.cs-multi-select-competences-panel.p-multiselect-panel .p-multiselect-item.p-highlight{
    max-width: -webkit-fill-available;
    white-space: pre-wrap;    
}

.cs-multi-select-competences-panel.p-multiselect-panel .p-multiselect-item{
    font-size: 1rem;
}