.savoirplus-entreprise-container {
    margin: 0 10rem;
}

.accueil-button-entreprise {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
}

.savoirplus-entreprise-head{
    text-align: center;
}

.savoirplus-entreprise-head h1{
    margin-top: 0;
}

.savoirplus-entreprise-head h2{
    margin-top: 0;
    font-size: 2em;
}

 h3.text-huge-entreprise{
    font-weight: 400;
}

.creer-un-compte-button-entreprise {
    text-align: center;
    bottom: 0rem;
    padding: 0.7rem;
    width: 100%;
    /* margin-top: 3rem; */
}

.accueil-button-entreprise .p-button {
    background-color: white;
    color: var(--terra-cotta);
    box-shadow: 5px 5px 5px lightgrey;
    margin-top: 2rem;
}

.creer-un-compte-button-entreprise .p-button {
    background-color: var(--terra-cotta);
    color: white;
}


.text-huge-entreprise {
    color: var(--indigo-dye);
    font-size: 1.75rem;
}

.text-huge-entreprise2 {
    color: var(--indigo-dye);
    font-size: 1.75rem;
    font-weight: 500;
}

.imagecard-entreprise {
    text-align: center;

}

.imagecard-entreprise img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 25rem;

}

@media screen and (max-width:992px) {

    .savoirplus-entreprise-container {
        margin: 0rem;
    }

    .text-huge-entreprise {
        margin-left: 0;
        line-height: 2.50rem;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }

    .text-huge-entreprise2 {
        margin-left: 0;
        line-height: 2.50rem;
        padding: 0;
        font-size: 1.25rem;
        text-align: center;
    }


    .savoirplus-entreprise-head {
        font-size: 0.75rem;
    }

    .imagecard-entreprise {
        width: 100%;
    }

    .imagecard-entreprise img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 15rem;
    }
}