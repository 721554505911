.title-offer{
    font-weight: 500;
}


.max-lines {
    display: inline-block;
     text-overflow: ellipsis;
     word-wrap: break-word;
     overflow: hidden;
     max-height: 7rem;
     line-height: 1rem;
}

.published-date-style{
    font-style: italic;
}

.logo-max-size{
    max-height: 80px;
    max-width: 100%;
}

.p-card.p-component{
border-radius: 1rem;
}

.oc-no-padding .p-card-content{
     padding: 0 !important;
}

.container-min-height{
    min-height: 7rem;
}

@media screen and (max-width:992px) {
    .container-min-height{
        min-height: 0rem;
    }
}