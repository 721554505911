.cpat-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.cpat-logo-container {
    display: table;
    border-radius: 7px;
    background-color: white;
    flex: 20% 0 0;
}

.cpat-logo-container img {
    margin-left: 0rem;
}

.cpet-button-full-description {
    display: flex;
    place-content: center;
}

.cpet-description-container div:first-child p:first-child { 
    margin-top: 0;
}