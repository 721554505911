.form-course-pop-up{
    width:80vw;
}

.form-course-pop-up .p-dialog-content{
    overflow-y:visible;
}

.fcpu-btn-continue{
    padding-top: 0.5rem;
}

.fcpu-btn-continue .p-button{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
    border: 1px solid;
}


.fscpu-btn-cancel{
    text-align: end;
}

.fscpu-btn-cancel .p-button{
    width: auto;
    color: var(--iris-100);
    border: 1px solid var(--iris-100);
}

.fscpu-btn-submit .p-button{
    width: auto;
    color: var(--white);
    background-color: var(--iris-100);
}

.fscpu-btn-submit-invalid .p-button{
    width: auto;
    color: var(--white);
    background-color: grey;
}

@media screen and (max-width:992px) {
    .form-course-pop-up{
        width:100vw;
    }

    .fscpu-btn-cancel{
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .fscpu-btn-submit{
        text-align: center;
    }
    .fscpu-btn-submit-invalid{
        text-align: center;
    }
}

