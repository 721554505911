.ra-button-seemore.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
    color: black;
    border-color: var(--primary-color);
}

.refined-search-main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.refined-search-title-container {
    display: flex;
    align-items: center;
}

.refined-search-lower-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.refined-search-filters-container {
    padding: 0.5rem;
    flex-basis: 30%;
    width: 25%;
    margin-top: 4rem;
}

.refined-search-filters-center {
    align-self: center;
}

.refined-search-right-container {
    flex-basis: 70%;
}

.refined-search-results-container-filters {
    padding: 0.5rem;
    flex-basis: 70%;
}

.refined-btn-goback {
    width: 37%;
}

.column-search-item {
    width: 50%;
}

.refined-search-results-container-filters .container-for-horizontal-display {
    display: flex;
    flex-wrap: wrap;
}

.refined-search-results-container-filters{
    padding: 0.5rem;
    justify-self: flex-end;
    flex-grow: 1;

}

.fake-candidat {
    filter : blur(0.5rem);
    pointer-events: none;
}

.refined-search-nb-result-and-order-container {
    padding: 0.5rem;
    flex-basis: 70%;
}

.refined-search-float-button {
    display: none;
}

.search-candidat-infomessage-filter{
    position : absolute;
    pointer-events: none;
    margin-top: 12rem;
    margin-left: 5rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    z-index: 1;
}

/* PaginatorDemo.css */

.paginator-demo .image-gallery {
    text-align: center;
    padding: 1rem;
}


@media screen and (max-width:992px) {
    .refined-search-title-container {
        display: flex;
        flex-direction: column;
    }

    .refined-btn-goback {
        width: 100%;
    }

    .refined-search-lower-container>div:nth-child(1) {
        order: 2;
    }

    .refined-search-lower-container>div:nth-child(2) {
        order: 1;
    }

    .refined-search-lower-container>div:nth-child(3) {
        order: 3;
    }

    .refined-search-lower-container {
        flex-direction: column;
    }

    .refined-search-filters-container {
        display: none;
    }

    .refined-search-right-container {
        flex-basis: 100%;
    }

    .refined-search-results-container-filters {
        padding: 0;
        min-height: 0;
        margin-left: 0.3rem;
        flex-basis: 100%;
        justify-self: flex-end;
    }

    .refined-search-nb-result-and-order-container {
        padding: 0.5rem;
        flex-basis: 100%;
    }

    .refined-search-float-button {
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0rem;
        padding: 0.7rem;
    }

    .refined-search-float-button .p-button {
        border: 2px solid var(--indigo-dye);
        border-radius: 2rem;
    }
}

@media screen and (max-width:750px) {
    .column-search-item {
        width: 100%;
    }

    .refined-btn-goback {
        width: 100%;
    }
}