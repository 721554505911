.dee-confirm-dialog-container {
    width: 50%;
}

.dde-datatable-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.dde-datatable-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    justify-content: left;
    align-items: left;
}

.dee-confirm-buttons-container {
    display: flex;
    justify-content: flex-end;
}