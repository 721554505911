.fh-margin-compensator{
    margin:-1rem -1rem 0 -1rem;
    padding: 5rem 1rem;
}

.fh-card-header  {
    background-color: var(--whisper);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: var(--font-family-poppins);
}


.fh-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.fh-card-title h1 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 700;
    font-family: var(--font-family-mulish);
}
.fh-card-title h2 {
    margin: 0;
    font-weight: 400;
}

.fh-card-mention {
    color: var(--indigo-dye);
    font-weight: 500;
}

.fh-card-parcours {
    font-weight: 400;
    color: var(--indigo-dye);
}

.item-centering {
    text-align: center;
    align-self: center;
    justify-content: center;
}


.div-image-resized {
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.div-image-resized img {
    height: 6rem;
    max-width: 100%;
}

.fh-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.fh-logo-container {
    display: table;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    /* height: 6.8rem; */
    background-color: white;
    flex: 20% 0 0;
}

.fh-info-container{
    flex: 80%;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
}

.fh-tags-container>span{
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.fh-logo-sub-container{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding:0.2rem;
}

.fh-campus-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;
}

.fh-campus-container .p-inputtext {
    padding: 0 0.5rem 0 0;
}

.fh-campus-container .p-dropdown {
    border: none !important;
    background-color: var(--whisper);
    color: black;
}

.fh-campus-container img {
    width: 1.5rem;
}

.formation-header-action-buttons {
   display: flex;
   flex-direction: column;
}

.formation-header-action-buttons>div,
.formation-header-action-buttons a,
.formation-header-action-buttons .p-button
{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.formation-header-action-buttons .p-button{
    padding: 0.5rem;
    white-space: nowrap;

}

.primary-color-border {
    border: solid 1px var(--primary-color);
 }

@media screen and (max-width:992px) {
    .fh-margin-compensator{
        padding: 2rem 1rem;
    }

    .fh-card-title h1 {
        font-size: 1.7rem;
    }
    .fh-card-title h2 {
        font-size: 1.4rem;
    }

    .fh-card-title {
        font-size: 1.7rem;
    }
    .fh-info-container{
        padding-top: 0.5rem;
        padding-left: 0rem;
    }
}