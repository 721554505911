.wysiwyg-sb {
    margin: auto;
    white-space: nowrap;
    margin-right: 1rem;
    margin-left: 0rem;
}

.wysiwyg-sb.p-buttonset .p-button:first-of-type{
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.wysiwyg-sb.p-buttonset .p-button:last-of-type{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

@media screen and (max-width:992px) {
    .wysiwyg-sb {
        margin-right: auto;
        margin-left: auto;
    }
}