.cursus-card .p-divider.p-divider-horizontal:before{
    border-top: solid 1px var(--grey-disabled);
}

.cursus-card.p-card.p-component{
    border-radius: 15px;
}

.cursus-card .p-card-body{
    padding: 0.5rem;
}