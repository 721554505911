.slc-table {
    display: table;
    margin: auto;
}

.slc-img-container {
    width: 100%;
    height: 9rem;
    padding: 1rem 0.5rem;
    display: table-cell;
    vertical-align: middle;
}

.slc-img-container img {
    max-height: 7rem;
    max-width: 100%;
}

.slc-carousel {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.slc-carousel .p-carousel-item:not(.p-carousel-item-active) {
    width: 0;
}

.slc-carousel .p-items-hidden .p-carousel-item {
    visibility: visible;
}

.slc-carousel .p-carousel-prev.p-link {
    margin: 0.1rem;
}

.slc-carousel .p-carousel-next.p-link {
    margin: 0.1rem;
}

.slc-discover {
    visibility: visible;
    width: 100%;
    background-color: var(--terra-cotta);
    color: white;
    border-radius: 1rem;
    padding: 0.5rem 0.25rem;
    opacity: 1;
}

.slc-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0px;
    position: relative;
    background-color: white;
    margin: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.slc-item-container:hover {
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
    top: -6px;
    background-color: white;
    cursor: pointer;
}

.slc-item-container:hover:before {
    transform: scale(2.15);
}

.slc-oe-item-container {
    display: flex;
    flex-direction: column;
    top: 0px;
    position: relative;
    background-color: white;
    margin: 1rem;
    text-decoration: none;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}


.slc-oe-item-container:hover {
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
    top: -6px;
    background-color: white;
    cursor: pointer;
}

.slc-oe-item-container:hover:before {
    transform: scale(2.15);
}


.slc-container-img-max {
    height: 150px;
}

.slc-carousel .p-carousel-indicators {
    display: none;
}

.oe-titre {
    font-family: var(--font-family-mulish);
    font-weight: 700;
    font-size: 1rem;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oe-text {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 0.8rem;
    color: dimgray;
    height: 2.2rem;
}

.oe-container-text {
    width: 80%;
}

.oe-container-text-like {
    margin: 1.2rem;
    display: flex;
    justify-content: space-between;
}

.oe-container-text-like-button {
    margin-left: 1.2rem;
}

@media screen and (max-width:992px) {

    .p-carousel-items-container .p-items-hidden {
        transform: none;
    }

    .p-carousel-items-content {
        overflow: auto !important;
    }
}