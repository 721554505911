.datatable-editeurs .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-fileupload.ajoutlogo >.p-button  {
   background-color: var(--green-success);
   color:white;
}
.aep-align-check{
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 1.75rem;
}