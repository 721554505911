.Header_Auth_Login.p-button{
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    width: auto;
}

.auth-tab-container.p-tabview .p-tabview-nav{
    place-content: center;
    border: none;
}

.auth-tab-container.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.auth-fieldset-nullifier .p-fieldset{
    border: none;
    background: none;
}

.auth-fieldset-nullifier .p-fieldset .p-fieldset-content{
    padding: 0rem;
}

.auth-tab-panel.p-overlaypanel .p-overlaypanel-close {
    background: none;
}

.auth-tab-panel.p-overlaypanel:after {
    border-bottom-color: var(--link-water);
}

.auth-tab-panel.p-overlaypanel:before {
    border-bottom-color: var(--link-water);
}

.auth-tab-panel.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: none;
    color: none;
}

.auth-tab-panel .p-link:focus {
    box-shadow: none;
}