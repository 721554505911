.credentials{
    padding-bottom: 0rem;
}

.credentials span{
    color : var(--indigo-blue);
    font-size : 1.3rem;
}

.logo-for-school{
    text-align: center;
    align-self: center;
}

.logo-for-school img{
    width: auto;
    max-width: 100%;
    height: 9rem;
}

.role-display{
    padding-top:0rem;
}

.composante-name{
    font-size:1.2rem;
}

.school-name{
    color : var(--indigo-dye);
    font-size : 1.5rem;
}

.profile-header-content{
    text-align: left;
}

@media screen and (max-width:992px) {
    .profile-header-content{
        text-align: center;
    }
}