.formation-actions-speeddial.p-speeddial{
    position: relative;
}

.formation-actions-speeddial .p-speeddial-action{
    justify-content: center;
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin-right: 0.5rem;
    border-radius: 50%;
    color: white;
    padding: 0.5rem 0rem;
    height:2.357rem;
    width: 2.357rem;
}

.formation-actions-speeddial.p-speeddial-opened .p-speeddial-list{
    display: flex;
}

.formation-actions-speeddial .p-speeddial-list{
    display: none;
}

.formation-actions-speeddial .p-button{
    background: var(--terra-cotta);
    color: white;
}

.formation-actions-speeddial .p-button:not(a):not(.p):hover{
    background: var(--terra-cotta);
    color: white;
}



.admin-order-speeddial li:nth-child(1) .p-speeddial-action{
    background-color: var(--p-danger);
}
.admin-order-speeddial li:hover:nth-child(1) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-danger-hover);
}

.admin-order-speeddial li:nth-child(2) .p-speeddial-action{
    background-color: var(--p-warning);
}
.admin-order-speeddial li:hover:nth-child(2) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-warning-hover);
}

.admin-order-speeddial li:nth-child(3) .p-speeddial-action{
    background-color: var(--p-help);
}
.admin-order-speeddial li:hover:nth-child(3) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-help-hover);
}

.admin-order-speeddial li:nth-child(4) .p-speeddial-action{
    background-color: var(--p-success);
}
.admin-order-speeddial li:hover:nth-child(4) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-success-hover);
}


.default-order-speeddial li:nth-child(1) .p-speeddial-action{
    background-color: var(--p-warning);
}
.default-order-speeddial li:hover:nth-child(1) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-warning-hover);
}

.default-order-speeddial li:nth-child(2) .p-speeddial-action{
    background-color: var(--p-success);
}
.default-order-speeddial li:hover:nth-child(2) .p-speeddial-action:not(.p-disabled){
    background-color: var(--p-success-hover);
}

.dtfa-disabled-button{
    display: block;
}