.dashboard-menu-theme{
    z-index: 1;
    background-color: var(--indigo-dye);
    width: 15rem;
    color: white;
    margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
  display: flex;
    flex-direction: column;
  position: fixed;
  height: 100%;
}

.dashboard-menu-theme h1{
    text-align: center;
  margin: 0;
  padding: 0.5rem;
  padding-bottom: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative
}

.dashboard-menu-theme h1:before{
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 92%;
    left: 4%;
    bottom: 0;
}


.dashboard-menu-theme a {
    color: white;
    display: flex;
    padding: 0.5rem 1rem;
    text-decoration: none;
    position: relative;
}

.dashboard-menu-theme a:before {
    content: '';
    border-bottom: 1px solid white;
    position: absolute;
    width: 88%;
    left: 6%;
    bottom: 0;
}

.dashboard-menu-theme a.active{
    background-color: var(--opal);
    color: white;
}

.dashboard-menu-theme a:hover:not(.active) {
    background-color: var(--opal-light);
    color: black;
}

.dashboard-menu-theme a img,.dashboard-menu-theme svg{
    max-height: 1rem;
    min-width: 1rem;
    align-self: center;
}

.dashboard-menu-theme a span{
    padding-left: 0.5rem;
    font-size: small;
}

.dashboard-menu-theme a span.regular-display{
    display: block;
}

.dashboard-menu-theme a span.small-display{
    display: none;
}
@media screen and (max-width: 992px) {
    .dashboard-menu-theme{
        overflow:hidden;
        bottom : 0;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        padding : 0;
        justify-content: space-around;
    }

    .dashboard-menu-theme h1{
        display: none;
    }

    .dashboard-menu-theme a {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.2rem;
        font-size: 0.5rem;
    }

    .dashboard-menu-theme a::before {
        content: normal;
        border: 1px solid white;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    .dashboard-menu-theme a span{
        padding-left: 0rem;
    }

    .dashboard-menu-theme a img,.dashboard-menu-theme svg{
        max-height: 2rem;
        min-height: 2rem;
        min-width: 2rem;
        min-height : 2rem;
        padding-right: 0rem;
        align-self: center;
    }

    .dashboard-menu-theme a span.regular-display{
        display: none;
    }

    .dashboard-menu-theme a span.small-display{
        display: block;
    }


}

@media screen and (min-width: 993px) {
    /* Masquer les éléments lorsque la largeur de l'écran est supérieure à 992px */
    .dashboard-menu-theme-only-mobile {
        display: none;
    }
}