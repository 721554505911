.center-element-oc{
    text-align: center;
    margin-top: 20%;
}

.grey-italic-message{
    color:grey;
    font-style: italic;
}

.select-matching{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}



@media screen and (max-width:992px) {
    .select-matching .p-button{
        padding : 0.5rem
    }
}


.p-selectbutton  .p-button{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  .p-selectbutton .p-button.p-highlight{
      color : white;
  }