.footer-main-container{
    color:white;
    font-weight:300;
    font-size: 0.8rem;
    padding: 1rem 2rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
}

.footer-logo-container{
    padding:1rem 0;
}
.footer-logo-container img{
    height: 3rem;
}

.footer-over-divider-container{
    display: flex;
    flex-direction: row;
}

.footer-over-divider-container a{
    cursor:pointer;
}

.footer-over-divider-container>div:first-child{
    flex: 45%;
    flex-shrink:0;
}

.footer-over-divider-container>div:nth-child(2){
    flex: 30%;
    flex-shrink:0;
}

.footer-over-divider-container>div:nth-child(3){
    flex: 25%;
    flex-shrink:0;
}

.footer-over-divider-container>div>div{
    padding-bottom: 1rem;
}

.footer-info-container{
    display: flex;
    flex-direction: column;
}
.footer-logo-info-line{
    display: flex;
    flex-direction: row;
}

.footer-logo-info-line svg{
    width: 1.2rem;
    height: 1.2rem;
}

.footer-socials-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.footer-socials-container svg{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.footer-clickable-link{
    color: white;
    text-decoration: none;
    cursor: pointer;
}


.footer-info-container>div{
    padding-bottom: 1rem;
}

.footer-divider.p-divider-horizontal{
    border-top:1px white solid

}
.footer-divider.p-divider-horizontal::before{
    border-top:1px white solid
}

.footer-below-divider-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-document-container{
    display: flex;
    flex-direction: row;
}

.footer-creator{
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:992px) {

    .footer-info-container{
        flex-direction: column;
    }

    .footer-logo-container{
        text-align: center;
    }

    .footer-over-divider-container{
        flex-direction: column;
    }

    .footer-over-divider-container>div:first-child{
        flex: 0;
    }
    
    .footer-over-divider-container>div:nth-child(2){
        flex: 0;
    }
    
    .footer-over-divider-container>div:nth-child(3){
        flex: 0;
    }

    .footer-socials-container{
        justify-content: center;
    }

    .footer-below-divider-container{
        flex-direction: column;
    }

    .footer-below-divider-container>div:nth-child(2){
        text-align: center;
    }

    .footer-document-container{
        justify-content: space-between;
        padding-bottom: 1rem;
    }


}