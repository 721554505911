.lerav-chip-exige{
    background-color: var(--terra-cotta);
}
.lerav-chip-souhaite{
    background-color: var(--alabaster);
}

.lerav-chip-exige > div:first-child, .lerav-chip-souhaite > div:first-child{
    display: inline-flex;
}

.lerav-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lerav-subline{
    display: flex;
    align-items: center;
}

.lerav-button{
    width: min-content;
}

.lerav-exige{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lerav-exige>span{
    font-size: 0.9rem;
}