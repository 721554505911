.efc-extended-data-container{
    margin-left: 9rem;
}

.efc-extended-data-container h1{
    font-weight: 600;
    font-size: 1.5rem;
    color:var(--resolution-blue);
}

.efc-extended-data-container h2{
    font-weight: 600;
    font-size: 1.2rem;
    color:var(--resolution-blue);
}

.efc-chip{
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 10px;
}

.efc-notes-container{
    margin-right: 9rem;
}

.efc-return{
    text-decoration: underline;
    color:var(--resolution-blue);
    cursor: pointer;
}

@media screen and (max-width:992px) {
    .efc-extended-data-container{
        margin-left: 0rem;
    }

    .efc-notes-container{
        margin-right: 0rem;
    }
}