.abonnement-card{
    position: relative;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 0.5rem 2rem;
}

.partner-banner {
    position: absolute;
    top: 20px;
    right: -15px;
    background-color: red;
    color: white;
    padding: 5px 20px;
    font-size: 0.9rem;
    font-weight: bold;
    transform: rotate(35deg);
    z-index: 1;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
}

.ac-title-container {
    text-align: center;
    margin: -0.5rem -2rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 4rem;
    color: white;
    margin-bottom: 0.8rem;
    background-color: var(--resolution-blue);
    
    display: flex;
    
    flex-direction: column;
    justify-content: center;
}

.ac-title-container h1{
    font-size: 1.5rem;
    margin: 0;
}

.ac-description-general-container{
    font-size: 0.8rem;
    font-weight: 500;
}

.ac-second-title-container {
    color: var(--terra-cotta);
    font-size: 1.3rem;
    font-weight: 600;
}

.ac-thrid-title-container {
    color: var(--terra-cotta);
    font-size: 1rem;
    font-weight: 600;
}

.abonnement-card h3 {
    font-size: 1.2rem;
    margin: 0;
    color: var(--resolution-blue);
    margin-bottom: 1rem;
}

.abonnement-card p {
    color: var(--indigo-dye);
}

.abonnement-card-list{
    list-style-image: url("../../images/icons/verifiedIcon.svg");
}

.abonnement-card-link{
    font-weight: 500;
    text-decoration: underline;
    color: var(--resolution-blue);
}

.ac-abonnement-card-button {
    color: rgb(124, 167, 218);
}

.ac-abonnement-card-button .p-button-label{
    font-style: italic;
}

.ac-description-container {
    flex-wrap: nowrap;
    font-weight: 500;
    justify-content: start;
}

.ac-icon-style {
    width: 1.2rem;
    height: 1.2rem;
}

.ac-check-color {
    color: #00B050;
}

.ac-close-color {
    color: red;
}

.ac-base-container{
    color: black;
    font-size: 1.2rem;
    font-weight: 990;
    text-decoration-line: line-through;
    text-decoration-thickness: 0.1rem;
}

.ac-green-container {
    color: #00B050;
    font-size: 1.4rem;
    font-weight: 600;
}

.ac-price-button-contain{
    justify-content: space-around;
}

.ac-price-alone-contain {
    justify-content: center;
}

.ac-price-contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ac-input-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--resolution-blue);
    font-size: 1.1rem;
    font-weight: 600;
}

.ac-description-subscription{
    color: var(--terra-cotta);
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}