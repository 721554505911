.App_loader {
  text-align: center;
}

body {
  font-family: var(--font-family);
}

a {
  text-decoration: none;
}

iframe.kw-iframe-dropdown:not(.active) {
  width: 0 !important;
  height: 0 !important;
}

.main-red {
  min-height: 100vh;
  padding-top: 4rem;
}

.main-iris {
  min-height: 100vh;
  padding-top: 4rem;
  background-color: var(--whisper);
}

.main-blue {
  min-height: 100vh;
}

.main-white {
  min-height: 100vh;
  padding-top: 4rem;
}

.main-with-dashboard {
  margin-left: 15rem;
  background-color: var(--whisper);
  min-height: 100vh;
}

.alabaster-background {
  background-color: var(--alabaster);
  min-height: 100vh;
}

.whisper-background {
  background-color: var(--whisper);
  min-height: 100vh;
}

.complete-profile-header-text {
  color: white
}

.global-top-sidebar-info.p-sidebar {
  z-index: 2;
}

.global-top-sidebar-info.p-sidebar-top {
  color: white;
  top: 4rem;
  height: auto;
  text-align: center;
}

.global-top-sidebar-info.p-sidebar .p-sidebar-header {
  background-color: var(--linkedin-blue);
}

.global-top-sidebar-info.p-sidebar .p-sidebar-content {
  margin-top: -3rem;
  padding: 1rem 4rem;
  background-color: var(--linkedin-blue);
}

.global-top-sidebar-info.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  z-index: 1;
  color: var(--white);
}

main {
  padding: 2rem;
}

.app-width-limit {
  margin: 0 auto;
  max-width: 1500px;
}

h1,
p {
  margin-bottom: 1rem;
}

.global-top-sidebar-button.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  color: var(--terra-cotta);
  border-color: var(--terra-cotta);
  background-color: white;
}

.center-bloc {
  align-self: center;
  text-align: center;
}

@media screen and (max-width:992px) {
  .main-with-dashboard {
    margin-left: 0rem;
  }

  main {
    padding: 0.5rem;
    margin-bottom: 3.3rem;
  }
}