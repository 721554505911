.send-email-validation-btn{
    display: flex;
    justify-content: center;
    width : 100%;
}

.delete-langue-btn{
    text-align: center;
}

.delete-langue-btn .p-button.p-button-icon-only {
    height:2.5rem;
    width: 2.5rem;
}

.add-langue-btn{
    display: flex;
    align-items: center;
}

.info-langue-display{
    margin-bottom: 0.5rem;
}

.info-niveau-langue-display{
    margin-bottom: 0.5rem;
}

.personnal-info-file-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personnal-info-subtitle{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    color: #333333;
}

.personnal-info-file-container > div{
    padding : 0.1rem;
}

.paragraphe-aide-profil-etudiant {
    font-style: italic;
}

.pif-global-input-container{
    margin-bottom: 2rem;
}

.pif-input-container .p-inputtext{
    padding: 1rem;
}

.pif-wysiwyg-container{
    margin: 1rem 0;
}

.pif-radio-button.p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
    background-color: transparent;
}

.pif-radio-button.p-radiobutton .p-radiobutton-box.p-highlight{
    border-color: #E0E0E0;
    background: var(--iris-100);
}

@media screen and (max-width:992px) {
    .info-niveau-langue-display{
        display: none;
    }
    .responsive-langue-display{
        margin-bottom: 1rem;
    }
    .send-email-validation-btn{
        margin-top: 0rem;
    }
}