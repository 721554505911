.nnrao-results-text .p-tag .p-tag-value+span {
    font-family: var(--font-family-mulish);
    font-size: 1.5rem;
    font-weight: 400;
    background-color: var(--whisper);
}

.nnrao-results-text .p-tag {
    background-color: var(--whisper);
}

.nnrao-tri div b {
    color: var(--iris-100);
}

.nnrao-tri .p-dropdown {
    background-color: #fff;
    border: 1px solid var(--alto);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: var(--font-family-poppins);
}

.nnrao-tri .p-inputtext {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 1rem;
}