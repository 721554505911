.etudiant-thumbnail-card .p-card-body{
    padding: 0;
}

.vue-candidat-main-container .etudiant-thumbnail-card .p-card-content{
    padding: 0;
}

.etudiant-thumbnail-card .p-card-content{
    padding: 0;
}

.etudiant-thumbnail-card.p-card.p-component{
    border-radius: 1rem;
}

.etudiant-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    background-color: var(--grey-cards);
}

.etudiant-thumbnail-main-container-with-formation-radius{
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.etudiant-thumbnail-main-container-without-formation-radius{
    border-radius: 1rem;
}

.etudiant-thumbnail-main-container>div{
    padding: 1rem 2rem;
    border-top-left-radius: 1rem;
}

.etudiant-thumbnail-profile{
    display: flex;
    flex-direction: row;
    background-color: white;
    flex-grow: 1;
}

.etudiant-thumbnail-profile-image{
    background-color: white;
    margin-right: 1rem;
}

.etudiant-thumbnail-profile-image img{
    object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--eerie-black);
  height: 7rem;
  width: 7rem;
  
}

.etudiant-thumbnail-profile-image .p-avatar-circle{
    height: 7rem;
  width: 7rem;
}

.etudiant-thumbnail-profile-image .p-avatar .p-avatar-icon{
    font-size: 3.2rem;
}


.etudiant-thumbnail-info-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.etudiant-thumbnail-elipsis-radius-with-formation{
    border-radius: 2% 4% 4% 0% / 8% 50% 50% 8%;
}

.etudiant-thumbnail-elipsis-radius-without-formation{
    border-radius: 2% 4% 4% 2% / 8% 50% 50% 8%;
}


.etudiant-thumbnail-info-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.etudiant-thumbnail-recherche-active-container{
    margin-left: auto;
}
.etudiant-thumbnail-recherche-active-container ul{
    padding-left: 1rem;
}

.etudiant-thumbnail-recherche-active-container h2{
    margin:0;
    color: var(--terra-cotta);
    font-size: 1.1rem;
}

.etudiant-thumbnail-recherche-active-container .p-tag{
    margin: 0.5rem 0;
    color: white;
    width: fit-content;
}

.etudiant-thumbnail-recherche-active-title-container{
    display: flex;
    align-items: center;
}

.etudiant-thumbnail-recherche-active-title-container>div:first-child{
    flex-grow: 1;
}

.etudiant-thumbnail-button{
    background-color: var(--primary-color);
    color: white;
    width: auto;
    padding: 0.5rem 1rem;
}

.etudiant-thumbnail-center{
    text-align: center;
    width: 100%;
}

.tag-stage{
    background-color:var(--indigo-blue)
}

.tag-emploi{
    background-color:var(--indigo-dye)
}
.tag-alternance{
    background-color:var(--linkedin-blue)
}

.et-dialog-button{
    background-color: var(--terra-cotta);
    color: white;
}

.location-panel{
    background-color: black;
    color: white;
    background: black;
}

.location-panel::before{
    border-bottom-color:black ;
}

.location-panel::after{
    border-bottom-color:black ;
}

.p-button.location-button{
    padding:0 0.5rem;
    border: 1px solid black;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    border-color:var(--grey-cards);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-header .p-accordion-header-link{
    color:var(--linkedin-blue);
    background: white;
    border: none;
    border-top: 1px solid var(--grey-cards);
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: white;
}

.etudiant-thumbnail-accordion.p-accordion .p-accordion-tab .p-accordion-content{
    border:none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.etudiant-thumbnail-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.etudiant-thumbnail-title-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}

.etudiant-thumbnail-moe-in-title{
    display:none;
}

.etudiant-thumbnail-small-moe-in-title{
    display: block;
}

/* SMALL */

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image img{
    height: 5rem;
    width: 5rem;
    
  }

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image .p-avatar-circle{
    height: 5rem;
    width: 5rem;
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container{
    flex-direction: column;
    align-items: flex-start;
}
.etudiant-thumbnail-small .etudiant-thumbnail-elipsis-radius-without-formation{
    border-radius: 0;
}

.etudiant-thumbnail-small .etudiant-thumbnail-recherche-active-container{
    margin-left: 0;
    display: flex;
    flex-direction: column;
    
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container>div{
    padding: 1rem;
    width: 100%;
    min-height: 10rem;
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container>div:first-child{
    border-top-left-radius: 1rem ;
    border-top-right-radius: 1rem ;
    border-bottom-right-radius: 0;
}

.etudiant-thumbnail-toolbar
{
    padding: 0.4rem !important;
    min-height: 2rem !important;
    text-align: end
}

.etudiant-thumbnail-small.etudiant-thumbnail-main-container .etudiant-thumbnail-center{
    min-height: 0rem;
}

.etudiant-thumbnail-small .etudiant-thumbnail-profile-image{
    text-align: center;
}

.etudiant-thumbnail-small-moe-in-title{
    display:block;
}

.margin-etudiant-thumbnail{
    margin: 0.5rem;
}
.etudiant-thumbnail-check-profile{
    text-align: right;
}

.etudiant-thumbnail-moe-in-active-search{
    display: block;
}

.etudiant-thumbnail-bottom-check-profile-hidden{
    display: none;
}

.etudiant-thumbnail-bottom-check-profile-show{
    display: block;
}

.etudiant-thumbnail-bottom-check-profile-responsive{
    display: none;
}

@media screen and (max-width:992px) {
    .etudiant-thumbnail-profile-image{
        text-align: center;
        margin-right: 0;
    }
    .etudiant-thumbnail-profile-image .p-avatar-circle{
        height: 5rem;
        width: 5rem;
    }

    .etudiant-thumbnail-profile-image img{
        height: 5rem;
        width: 5rem;
    }
    .etudiant-thumbnail-main-container>div:first-child{
        padding: 1rem;
        width: 100%;
        border-top-right-radius: 1rem;
        min-height: 0rem;
    }
    .etudiant-thumbnail-main-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .etudiant-thumbnail-elipsis-radius-with-formation{
        border-radius: 0;
    }
    .etudiant-thumbnail-elipsis-radius-without-formation{
        border-radius: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    .etudiant-thumbnail-recherche-active-container{
        margin-left: 0;
    }
    .margin-etudiant-thumbnail{
        margin: 0.5rem 0rem;
    }

    .etudiant-thumbnail-check-profile{
        display: none;
    }
    .etudiant-thumbnail-bottom-check-profile-responsive{
        display: block;
    }
    .etudiant-thumbnail-moe-in-active-search{
        display: none;
    }
    .etudiant-thumbnail-moe-in-title{
        display:block;
    }

}