.le-input.p-inputgroup input {
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    border: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.le-input.p-inputgroup button{
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid var(--iris-100);
}

.le-input{
    border: 1px solid var(--surface-300);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
}
