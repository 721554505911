.cfc-container {
    background-color: transparent;
    position: relative;
    margin-top: 4rem;
    border-radius: 1rem;
    height: 20rem;
}

.cfc-mention {
    display: -webkit-box; 
    -webkit-box-orient: vertical;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.cfc-mention-limit-text{
    /* number of lines to show */    
    -webkit-line-clamp: 1;
    line-clamp: 1;
    z-index: 90;
}

.cfc-parcours{
    padding-bottom: 0.5rem;
}

.formation-logo-container img {
    max-width: 100%;
    max-height: 6.4rem;
    display: flex;
    margin: auto;
}

.formation-logo-container {
    display: table;
    margin: auto;
    position: absolute;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    top: -2.5rem;
    left: 2rem;
    width: 10.38rem;
    height: 6.8rem;
    background-color: white;
}

.formation-logo-sub-container{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    padding:0.2rem;
}

.cfc-tags-container {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: -1rem;
    left: 13rem;
}

.cfc-details-container {
    color: var(--gray);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cfc-details-container .p-inputtext {
    padding: 0.2rem 0.5rem;
}

.cfc-details-container .p-dropdown {
    border: none !important;
}

.cfc-container-text div p {
    margin-bottom: 0.5rem;
    font-family: var(--font-family-mulish);
    font-weight: 700;
}


.cfc-container .dip-tag-blue {
    margin-bottom: 0.5rem;
}

.cfc-container button {
    padding: 0.5rem 1rem;
}

.cfc-container .p-button {
    border-radius: 0.3rem;
}

.cfc-container-text {
    height: 15rem;
    display: flex;
    flex-direction: column;
}

.cfc-offer-button.p-button {
    padding: 0.5rem 1rem;
}

.cfc-icon-campus {
    width: 100%;
}

.cft-button-see-formation i {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    pointer-events: none; 
}

@media screen and (max-width:992px) {
    .cfc-offer-button.p-button {
        padding: 0.5rem 0.2rem;
    }

    .cfc-container {
        height: 22rem;
    }
}