.save-formation{
    display: inline-flex;
    margin-right: 1rem;
}

.p-dialog .p-dialog-footer button.save-button-formation {
    color:var(--terra-cotta);
}
.p-dialog .p-dialog-footer .save-button-formation > button {
    margin:0.25rem;
    padding: 0;
}
.p-menu-list.p-reset.menu-save-button-formation{
    color:var(--terra-cotta);
}




.ajout-picklist-item{
    padding-bottom: 3rem;
}

.ajout-picklist-item .p-speeddial-action{
    margin:0.5rem;
}

.ajout-picklist-item .p-speeddial-opened .p-speeddial-item{
    background-color: var(--green-success);;
    border-radius: 50%;
    margin-bottom: 0.5rem;
}
.dragdrop-style{
    display: flex;
    justify-content: space-between;
    align-items: center;
}