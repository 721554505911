.p-field-checkbox > label.ffof-no-margin-left{
    margin-left: 0;
}

.ffof-btn-cancel{
    text-align: end;
}

.ffof-btn-cancel .p-button{
    width: auto;
    color: var(--terra-cotta);
    border: 1px solid var(--terra-cotta);
}

.ffof-btn-submit .p-splitbutton{
    width: auto;
}

.ffof-btn-submit .p-splitbutton .p-splitbutton-defaultbutton{
    flex: none;
    width: auto;
}

.ffof-btn-submit .p-button{
    color: white;
    background-color: var(--terra-cotta);
    
}

@media screen and (max-width:992px) {
    .ffof-btn-cancel{
        text-align: center;
        margin-bottom: 0.5rem;
    }
    .ffof-btn-submit{
        text-align: center;
    }
}