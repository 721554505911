.im-txt-bold {
    font-weight: 700;
    color: black;
}

.container-global-domaines .field-checkbox {
    border-radius: 0.5em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    padding: 1rem 1rem;
    margin-left: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.container-global-domaines .field-checkbox .p-checkbox .p-checkbox-box.p-highlight {
    border-color: green !important;
    background: green !important;
}

.container-global-domaines .field-checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: white;
}

.container-global-domaines .field-checkbox label {
    margin-left: 1rem;
}

.btn-diplome .p-button {
    border-radius: 1em !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    padding: 0.2rem 1rem;
}

.btn-diplome {
    display: flex;
    justify-content: space-evenly;
}


@media screen and (max-width:992px) {

    .btn-diplome {
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .container-global-domaines .field-checkbox {
        padding: 1rem 0.5rem;
        margin-left: 0rem !important; 
        margin-bottom: 0.8rem !important;
    }

    .container-global-domaines .field-checkbox label {
        margin-left: 0.5rem;
    }
}