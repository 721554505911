/* Sidenav menu */
.sidenav {
    height: 100%;
    width: 85%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -86%;
    background-color: white;
    padding-top: 15px;
    transition: left 0.5s ease;
}

.sidenav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Sidenav menu links */
.sidenav a {
    padding: 12px 8px 12px 32px;
    text-decoration: none;
    font-size: 1.2em;
    color: black;
    display: block;
    transition: 0.3s;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.sidenav a:hover {
    color: var(--iris-100);
}

/* Active class */
.sidenav.active {
    left: 0;
}


.burger-icon span {
    display: block;
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

.sidenav .pi {
    font-size: 1em;
    color: black;
    padding-left: 1em;
}

.header-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px 60px 32px;
}

.sidenav img {
    padding-left: 0rem;
    height: 1.7rem;
    width: auto;
}

.bm-img-burger-menu {
    padding-left: 0rem;
    height: 1.7rem;
    width: auto;
}

.bm-ul-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.bm-ul-menu li {
    padding: 12px 8px 12px 32px;
    font-size: 1.2em;
    display: block;
    transition: 0.3s;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.bm-ul-menu li a {
    text-decoration: none;
    color: var(--resolution-blue);
}

@media screen and (max-width:560px) {
    .bm-sidebar-container {
        width: 85%;
    }
}