.eo-title{
    margin: 0;
}

.eo-filter-panel-margin{
    margin-top: 4.75rem;
    margin-right:2rem;
    width: 28%;
}

.eo-only-pc{
    display: block;
}

.eo-only-mobile{
    display: none;
}

.p-dialog-content .eo-popup-message{
    padding : 1rem 6rem 1rem 0rem;
}

@media screen and (max-width:992px) {
    .eo-only-pc{
        display: none;
    }

    .eo-only-mobile{
        display: block;
    }
}