.container-him {
    margin: 0 2rem;
}

.container-numbers{
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container-him-intro,
.container-global-number  {
    font-family: var(--font-family-poppins);
}

.container-him-intro{
    margin: 0;
}

.container-him-intro h1 {
    text-align: start;
}

.container-global-number{
    display: flex;
    justify-content: flex-start;
    flex-direction: column; 
}

.number-style-number {
    font-size: 4rem;
    font-weight: 600;
    width: 4rem;
    min-width: 4rem;
} 

.number-style-first-line {
    font-size: 1.3rem;
    font-weight: 500;
}

.number-style-others-lines {
    font-size: 1.3rem;
    font-weight: 600;
} 

