.field_margin {
    margin: 2rem;
    width: 25rem;
}

.field_margin-right {
    margin: 20rem;
}

.field-checkbox {
    margin-right: 1em;
}

.label-align-margin-right{
    align-self: center;
    margin-right: 1rem;
}

.fsp-main-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.fsp-main-title h1{
    margin-top: 0;
}

.fsp-main-title .fsp-visualize-profil.p-button:first-child{
    background-color: var(--iris-100);
    border: 1px solid var(--iris-100);
    color: var(--white);
    padding: 0.5rem;
}

.fsp-ellipsis.p-button.p-button-text{
    color: black;
}

.fsp-ellipsis.p-button.p-button-text:not(a):not(.p-disabled):active{
    background: none;
    color: black;
}

.fsp-ellipsis.p-button.p-button-text:not(a):not(.p-disabled):hover{
    background: none;
    color: black;
}

.fsp-main h1{
    color:var(--indigo-dye);
    font-size: 1.5rem;
    margin-top: 0;
}

.fsp-loading{
    text-align:center;
    margin-top: 10rem;
}

.fsp-disabled{
    pointer-events: none;
    opacity: 0.5;
}

.fcp-file-container-style{
    display: flex;
    justify-content: flex-start;
}

.fsp-tabview.p-tabview .p-tabview-panels{
    padding: 1rem 0;
}

.fsp-tabview .p-tabview-nav{
    justify-content: space-between;
}

.fsp-tabview.p-tabview .p-tabview-nav li{
    background-color: var(--last-default);
    flex: 20%;
}

.fsp-tabview .p-tabview-title{
    text-align: center;
}

.fsp-tabview.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    justify-content: center;
    margin: auto;
}

.fsp-tabview.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border-bottom: 4px solid;
}

.fsp-tabview.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus{
    box-shadow: none;
}

.fcp-card-container{
    padding: 1rem 4rem;
    border-radius: 10px;
}

.fcp-right-container{
    width: 75%
}

@media screen and (max-width:992px) {
    .fsp-main-title{
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .fcp-file-container-style{
        justify-content: center;
    }

    .fsp-tabview.p-tabview .p-tabview-nav li{
        flex:auto;
    }

    .fsp-tabview .p-tabview-nav{
        width: auto;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
     }

     .fsp-tabview .p-tabview-title{
        text-align: center;
        white-space: nowrap;
    }

    .fsp-main-title > div:nth-child(2){
        text-align: right;
    }

    .fcp-card-container{
        padding: 1rem;
    }
    
    .fcp-right-container{
        width: 100%
    }
}