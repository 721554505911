.rob-dialog{
    width:85vw;
    height: 90vh;
}

.rob-dialog.p-dialog .p-dialog-header{
    padding: 0rem;
}

.rob-dialog.p-dialog .p-dialog-header-icons{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.rob-dialog.p-dialog .p-dialog-footer{
    padding: 0rem 1.5rem;
}

.rob-header{
    font-size: 2rem;
}

.rob-main-container{
    height: 100%;
}

.rob-progress-bar{
    height:0.5rem
}

.rob-link{
    margin: 0.5rem;
}

.rob-link a{
    text-decoration: underline;
    cursor: pointer;
    color:var(--linkedin-blue);
}

.rob-btn-previous.p-button{
    color: var(--terra-cotta);
    border:solid 1px var(--terra-cotta);
}

.rob-font-size{
    font-size: 1.5rem;
}

.rob-contrat-container>div{
    height:33.33333%;
}

.rob-contrat-font-size{
    font-size: 1.5rem;
}

.rob-contrat-font-size.sp-checkbox-label{
    font-size: 1.5rem;
}


.rob-footer-buttons-container{
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 1rem;
}

.rob-footer-buttons-container>div{
    margin: 0.5rem 0;
}

.rob-footer-buttons-container img{
    height:2rem
}


.rob-intro-section-img{
    margin: 1rem;
    width: 14rem;
}

.rob-outro-img{
    width:-webkit-fill-available;
}

.rob-outro-bravo{
    font-size: 6rem;
}

.rob-outro-fs{
    font-size: 1.2rem;
}

.rob-outro-help-container{
    font-size: 1.2rem;
    padding: 1rem;

}

.rob-outro-enrich-container{
    margin-bottom: 3rem;
}

.rob-outro-end-button-container{
    display: none;
}

.rob-community-container{
    font-size: 1.4rem;
}

.rob-community-container b{
    font-size: 1.4rem;
}

.rob-community-img-container{
    align-self: center;
}

.rob-community-img-container img{
    align-self: center;
    height: 2rem;
}

.rob-photo-container img{
    height: 8rem;
}

@media screen and (max-width:992px) {
    .rob-header{
        font-size: 1.5rem;
    }

    .rob-intro-section-img{
        margin: 1rem;
        width: 8rem;
    }

    .rob-font-size{
        font-size: 1rem;
    }

    .rob-contrat-font-size{
        font-size: 1.2rem;
    }
    
    .rob-contrat-font-size.sp-checkbox-label{
        font-size: 1.2rem;
    }

    .rob-dialog{
        width:100vw;
    }

    .rob-outro-fs{
        font-size: 1rem;
    }

    .rob-outro-help-container{
        font-size: 1rem;
    }

    .rob-outro-end-button-container{
        display: block;
        padding: 1rem;
        text-align: right;
    }

    .rob-outro-bravo{
        font-size: 3rem;
    }

    .rob-outro-enrich-container{
        margin-bottom: 0rem;
    }


    .rob-community-img-container{
        align-self: auto;
    }

    .rob-footer-buttons-container{
        flex-direction: column-reverse;
    }

    .rob-outro-img{
        display: none;
    }
}