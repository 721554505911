.tab-main-title-filter {
   font-size: 1.6rem;
   font-weight: 700;
   line-height: 1.8rem;
   text-indent: 0.1rem;
   background: var(--white);
   padding: 0.3rem 0rem;
}

.msf-tab-title-filter {
   display: flex;
   font-weight: 700;
   font-size: 1rem;
}

.msf-accordion {
   box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
   border-radius: 0.6rem;
   background: var(--white);
   padding: 1.5rem;
}

.msf-accordion .p-accordion-content {
   padding: 0rem;
}

.msf-accordion .p-accordion-content .p-accordion-content {
   padding: 1rem;
}

.msf-accordion .msf-erase-filters {
   color: var(--white);
   background: var(--iris-100);
   padding: 0.4rem 0.6rem;
   border-radius: 0.4rem;
   margin: 1rem 0rem;
}

.msf-otheroptions {
   padding: 1rem 0.5rem;
}

.msf-otheroptions-line {
   display: flex;
   justify-content: space-between;
   padding: 1rem 0.5rem;
   border-bottom: 1px solid #ADADAD;
}

.msf-otheroptions-borderless-line-various-filter {
   display: flex;
   padding: 0.5rem;
   align-items: center;
}

.msf-otheroptions-borderless-line-various-filter .p-checkbox-box.p-highlight {
   background: #696969;
   border: 1px solid #545454;
}

.msf-otheroptions-borderless-line-various-filter .p-checkbox .p-checkbox-box .p-checkbox-icon {
   color: transparent;
}

.msf-checkbox-various-filter {
   margin-right: 0.5rem;
}

.msf-checkbox-various-filter.p-checkbox .p-checkbox-box.p-highlight {
   border-color: green;
   background: green;
}

.msf-checkbox-various-filter.p-checkbox .p-checkbox-box.p-highlight:hover {
   border-color: green;
   background: green;
}


.msf-checkbox-various-filter.p-checkbox .p-checkbox-box .p-checkbox-icon {
   color: white;
}

.msf-input-switch.p-inputswitch {
   height: 1rem;
   width: 2.3rem;
}

.msf-input-switch.p-inputswitch .p-inputswitch-slider::before {
   width: 0.9rem;
   height: 0.9rem;
   margin-top: -0.45rem;
   left: 0.1rem
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
   background: var(--terra-cotta);
}

.msf-input-switch.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
   background: var(--terra-cotta);
}

.msf-accordion .p-accordion-header.p-highlight {
   border-radius: 0.5rem;
   background-color: var(--opal-light);

}

.msf-accordion .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
   border-color: transparent;
}

.msf-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   background-color: #ffffff;
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   border-color: #dee2e6;
   border-bottom: 0.2em solid var(--indigo-dye);
   background-color: var(--white);
}

.msf-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   border-top: 1px solid #dee2e6;
   border-left: 1px solid #dee2e6;
   border-right: 1px solid #dee2e6;
   /* border-color: #dee2e6; */
   border-bottom: 0.2em solid var(--linkedin-blue);
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion-tab .p-accordion-content {
   border-bottom-right-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
   border-top-right-radius: 0.5rem;
   border-top-left-radius: 0.5rem;
   background-color: var(--opal-light);
}

.msf-accordion .p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   background-color: var(--white);
}

.msf-sub-accordion.p-accordion.p-accordion-tab-active .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   border-bottom: transparent;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
   /* border-bottom: var(--indigo-dye) 1px solid; */
   border-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content {
   border-bottom: 1px solid #ADADAD;
   border-bottom-left-radius: 0rem;
   border-bottom-right-radius: 0rem;
}

.msf-sub-accordion.p-accordion .p-accordion-header .p-accordion-header-link {
   padding: 1rem 0.5rem;
}

.msf-sub-accordion .p-accordion-header-text {
   width: -webkit-fill-available;
}

.msf-sub-accordion .p-tag {
   background: var(--indigo-dye);
   color: white;
   border-radius: 1rem;
   font-weight: 500;
}

.msf-selectbutton.p-selectbutton .p-button.p-highlight {
   background: var(--iris-100);
}

.msf-selectbutton.p-buttonset .p-button:first-of-type {
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
   margin-right: 0.5rem;
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-content .msf-selectbutton {
   display: block;
}

.msf-selectbutton.p-buttonset .p-button {
   padding: 0.2rem 0.8rem;
}

.msf-selectbutton .p-button.p-highlight:hover {
   color: white;
   border-color: #ced4da;
}

.msf-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
   background: #e9ecef;
}

.msf-selectbutton.p-buttonset .p-button:last-of-type {
   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
   margin-bottom: 0.1rem;
   padding: 0.5rem 1rem;
}

.msf-selectbutton.p-buttonset.msf-level-diplom .p-button:last-of-type {
   padding: 0.5rem 1.4rem;
}

/* diplomeMultiSelect */
.msf-diplome-multiselect .p-multiselect {
   display: flex;
   padding: 0.5rem;
   border: 1px solid var(--surface-300);
   box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
   border-radius: 0.5rem;
}

.msf-diplome-multiselect .p-multiselect-label-container {
   padding: 0.2rem;
}

.msf-diplome-multiselect-company .p-multiselect.p-multiselect-chip .p-multiselect-token{
   background: var(--iris-100);
   color: white;
}

.dms-filter-item .dmp-button-style {
   border: 1px solid var(--midnight-express);
   color: var(--midnight-express);
   border-radius: 0.6rem;
   padding: 0.2rem 0.8rem;
   margin-right: 0.5rem;
   margin-bottom: 0.5rem;
}

/* /diplomeMultiSelect */

.msf-tab-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.msf-tab-subheader {
   display: flex;
}

.msf-tooltip {
   display: flex;
   align-self: center;
}

.msf-slider {
   margin: 0rem 2rem;
}

@media screen and (max-width:992px) {

   .msf-sub-accordion.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
      background: white;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ADADAD;
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
   }
}