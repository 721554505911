.fpo-main{
   box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 1.5rem;
}

.fpo-main h1{
    color: var(--resolution-blue);
    font-weight: 700;
    margin: 0 0 1.5rem 0;
}

.fpo-main>div{
   margin-bottom: 1rem;
}

.fpo-main label{
    color: var(--resolution-blue);
    font-weight: 700;
}

.fpo-main .p-input-icon-right{
   width: 100%;
}

.fpo-main input{
   border: 1px solid var(--resolution-blue);
   border-radius: 5px;
   width: 100%;
}

.fpo-main input::placeholder{
   font-style: italic;
}

.fpo-main .p-dropdown,
.fpo-main .p-multiselect{
   border: 1px solid var(--resolution-blue);
   border-radius: 5px;
   width: 100%;
}

.fpo-main .p-placeholder{
   font-style: italic;
}

.fpo-main .p-multiselect.p-multiselect-chip .p-multiselect-token{
   background: var(--resolution-blue);
   color: white;
}

.fpo-selectbutton.p-selectbutton .p-button{
    border: 1px solid var(--resolution-blue);
    color: var(--resolution-blue);
    border-radius: 5px;
    margin-bottom:0.1rem;
    font-weight: 400;
 }

.fpo-selectbutton.p-selectbutton .p-button.p-highlight{
    background: var(--resolution-blue);
    color: white;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:first-of-type{
    margin-right: 0.5rem;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:not(:first-of-type):not(:last-of-type){
    margin-right: 0.5rem;
    border-radius: 5px;
 }
 
 .fpo-selectbutton.p-buttonset .p-button{
    padding: 0.2rem 0.8rem;
    font-weight: 400;
 }
 
 .fpo-selectbutton .p-button.p-highlight:hover{
    color: white;
    border-color: #ced4da;
 }
 
 .fpo-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
    background: #e9ecef;
 }
 
 .fpo-selectbutton.p-buttonset .p-button:last-of-type{
 }
