.fr-form .p-inputtext:enabled:focus {
    box-shadow: none;
}

.p-overlaypanel.hal-etudiant {
    background: var(--link-water);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); 
    border-radius: 10px;
}

.field-margin{
    margin: 1rem 0rem;
}

.frs-button.p-button.frs-etudiant{
    background-color: var(--primary-color);
    width: auto;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
}

.frs-button .p-splitbutton-defaultbutton{
    background-color: var(--primary-color);
    width: auto;
}

.frs-button .p-button.p-button-icon-only{
    background-color: var(--primary-color);
}

.frs-card{
    margin-top: -1em;
}

.frs-submit{
    display: flex;
    justify-content: center;
}

.general-conditions{
    color: black;
    text-decoration-line: underline;
}