.fnb-container{
    z-index: 1;
}

.fnb-invisible{
    visibility: hidden;
}

.fnb-navigation.p-button{
    color: var(--iris-100);
    border:solid 1px var(--iris-100);
    border-radius: 2.3rem;
}

.fnb-navigation-left.p-button{
    margin-right: 2rem;
}

.fnb-navigation-right.p-button{
    margin-left: 2rem;
}

.fnb-save.p-button{
    background-color: var(--iris-100);
}

.fnb-pc.p-button{
    display: inline-flex;
    border-radius: 2.3rem;
}

.fnb-mobile.p-button{
    display: none;
}

@media screen and (max-width:992px) {
    .fnb-navigation.p-button .p-button-label{
        display: none;
    }

    .fnb-pc.p-button{
        display: none;
    }

    .fnb-navigation.p-button{
        color: var(--iris-100);
        border:solid 1px var(--iris-100);
        border-radius: 50%;
        padding: 1rem;
    }
    
    .fnb-mobile.p-button{
        display: inline-flex;
    }
    .fnb-mobile.p-button.p-button-icon-only.p-button-rounded{
        height: 3.5rem;
        width: 3.5rem;
    }

}