.ler-button:hover{
    background-color: var(--terra-cotta);
}
.ler-button:focus{
    background-color: var(--terra-cotta);
}
.ler-chip{
    background-color: var(--alabaster);
}

.ler-autcomplete.p-autocomplete input{
    display: flex;
    padding: 1rem;
    padding-right: 2rem;
    margin-bottom: 2rem;
    border: 1px solid var(--surface-300);
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
}

.ler-autcomplete-panel{
    max-width:-webkit-fill-available;
}

.ler-autcomplete-panel li{
    max-width: -webkit-fill-available;
}

.ler-item{
    max-width:-webkit-fill-available;
    white-space: pre-wrap;
}

.ler-delete-all.p-button{
    border-radius: 2rem;
    color: var(--terra-cotta);
    padding: 0.2rem 1rem;
    border: 1px solid var(--terra-cotta);
    margin-top: 1rem;
}

.ler-filter-item{
    display: flex;
    align-items: center;
}

.ler-filter-item-value{
    padding: 0.2rem;
}

.ler-autocomplete-container{
    margin-bottom: 1rem;
}

.ler-autocomplete-icon{
    margin: 0rem;
    z-index: 1;
    position: relative;
    margin-top: -4.3rem;
    margin-left: 88%;
}
