.see-more-button{
    text-align: center;
}

.see-more-button .p-button.p-button-outlined{
    background-color: white;
    justify-content: center;
}

.link-to-rechercheAffinee{
    color: var(--terra-cotta);
    text-decoration: underline;
}