.ct-oe-item-container {
    top: 0px;
    position: relative;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.ct-oe-item-container:hover{
    top: -6px;
    cursor: pointer;
}

.ct-contrats{
    height : 6rem;
    overflow-y: hidden;
}

.ct-oe-item-container:hover .ct-box-container{
    box-shadow: 0px 4px 8px rgba(52, 52, 52, 0.8);
}

.ct-oe-item-container:hover:before .ct-box-container{
    transform: scale(2.15);
}

.ct-titre {
    font-family: var(--font-family-mulish);
    font-weight: 700;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}


.ct-box-container {
    padding: 4rem 1rem 1rem 1rem;
    margin: -4rem 0.5rem 1rem 0.5rem;
    height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: white;
}

.ct-profile-image {
    display: flex;
    margin: 0 1.5rem;
    justify-content: space-between;
}

.ct-profile-image img {
    object-fit: cover;
    border: 3px solid #E6E6E6;
    border-radius: 7px;
    height: 7rem;
    width: 7rem;

}

.ct-profile-image .p-avatar {
    height: 7rem;
    width: 7rem;
    border-radius: 7px;
    border: 3px solid #E6E6E6;
}

.ct-profile-image .p-avatar .p-avatar-icon {
    font-size: 3.2rem;
}

.ct-text {
    font-family: var(--font-family-poppins);
    font-weight: 400;
    font-size: 0.8rem;
    color: dimgray;
}

.ct-mobility {
    height: 5.5rem;
}

.ct-like-container{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 1rem;
}