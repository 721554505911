.eoet-main-card {
    color: var(--resolution-blue);
    padding: 1.5rem;
}

.eoet-border-card{
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
}

.eoet-top-line{
    display: flex;
    flex-direction: row;
}

.eoet-candidat-box-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: var(--pastel-blue);
    padding: 1rem 1.5rem 0rem 1.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
}

.eoet-candidat-box-container>div {
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.eoet-candidat-box-container>div>div:first-child {
    font-size: 2rem;
    color: var(--terra-cotta);
    font-weight: 700;
}

.eoet-candidat-box-container>div>div:last-child {
    color: var(--resolution-blue);
    font-weight: 500;
}

.eoet-tag {
    background-color: var(--resolution-blue);
    color: white;
    border-radius: 1rem;
    padding: 0.15rem 0.85rem;
}

.eoet-pointer {
    cursor: pointer;
}

.eoet-description-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size:1rem;
    line-height: 1.5rem;
    height: 7.5rem;
}

.eoet-confirm-dialog{
    text-align: center;
}

@media screen and (max-width:992px) {
    .eoet-top-line{
        flex-direction: column-reverse;
    }

    .eoet-candidat-box-container{
        flex-wrap: wrap;
    }

    .eoet-candidat-box-container>div {
        flex:50%
    }
}