.card-for-ecole-header .p-card-body {
    padding: 0;
}

.card-for-ecole-header .p-card-body .p-card-content {
    padding: 0;
}

.vof-card .p-card-body {
    padding: 0.5rem 1rem;
}

.vof-card .p-card-body .p-card-content {
    padding: 0;
}

.vof-ecole-header {
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
}
.vof-ecole-left-container{
    display: flex;
}

.ecole-info {
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
}


.ecole-global {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: -2rem;
}

.ecole-content {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 1rem;
}

.vof-ecole-title {
    margin: 0
}

.vof-campus-line {
    display: flex;
}

.vof-ecole-socials-container{
    display: flex;
}

.vof-io-logo{
    cursor: pointer;
    font: 1.1rem;
}

@media screen and (max-width:992px) {
    .ecole-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .vof-ecole-header {
        padding: 1rem;
        flex-direction: column;
    }
    .vof-ecole-left-container{
        flex-direction: column;
    }

    .vof-course-container{
        text-align: center;
    }

    .ecole-info {
        padding: 0 1rem;
    }

    .ecole-global {
        margin: -0.5rem;
    }

    .ecole-school {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .ecole-title {
        font-size: 2rem;
    }
}