.wysiwyg-mobile-renderer{
    display: none;
}

.wysiwyg-pc-renderer{
    display: block;
}
@media screen and (max-width:992px) {
    .wysiwyg-mobile-renderer{
        display: block;
    }
    
    .wysiwyg-pc-renderer{
        display: none;
    }
}