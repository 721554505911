.langue-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.langue-thumbnail-main-container>div{
    flex: 1 50%;
    padding: 0.5rem;
}

.langue-thumbnail-card.p-card.p-component{
    border-radius: 15px;
}

@media screen and (max-width:992px){
    .langue-thumbnail-main-container{
        flex-direction: column;
    }
    .langue-thumbnail-main-container>div{
        flex: 1 100%;
    }
}