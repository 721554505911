.mpsf-header{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    padding: 0rem 1rem;
}

.mpsf-header-main-title{
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
}

.mpsf-header .mpsf-erase-filters{
    color: var(--white);
    background: var(--iris-100);
    padding: 0.4rem 0.6rem;
    border-radius: 0.4rem;
    margin-top: 1rem;
}

.mpsf-dialog{
    width: 100vw
}

.mpsf-dialog.p-dialog .p-dialog-content{
    padding: 0rem;
}

.mpsf-dialog.p-dialog .p-dialog-header{
    padding: 0.5rem;
    border-bottom: var(--indigo-dye) 2px solid;
}

.mpsf-button .p-button-label{
        font-size: 0.8rem;
}

.refined-search-float-button .p-button-label{
    color: var(--indigo-dye);
}

.mspf-button-label .p-tag{
    border-radius: 1rem;
    background-color: var(--indigo-dye);
    color:white;
}