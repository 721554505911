.sp-checkbox-label{
    font-size: 1.1rem;
}
.alternance-container{
    margin-top: 0.5rem;
}

.active-search-tree-select.p-treeselect.p-treeselect-chip .p-treeselect-token{
    background: var(--terra-cotta);
    color: white;
    border-radius: 3px;
}

.active-search-multi-select.p-multiselect.p-multiselect-chip .p-multiselect-token{
    background: var(--terra-cotta);
    color: white;
}

.active-search-multi-select-panel .p-multiselect-item{
    white-space: normal;
}

.recherche-active-disabled-label{
    color:var(--grey-disabled)
}

.recherche-active-soon-container{
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.as-div-container{
    padding: 0 2rem;
}

.cpms-filter-button-position{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cpms-filter-button-container{
    padding: 0.2rem;
}

.cpms-button-style.p-button{
    border: 1px solid var(--midnight-express);
    color: var(--midnight-express);
    border-radius: 0.6rem;
    padding: 0.2rem 0.8rem;
    width: auto;
    margin: 0;
}

.as-title-grid-height{
    display: grid;
    grid-template-columns: max-content;
}

@media screen and (max-width:992px){
    .alternance-container{
        margin-top: 0rem;
    }

    .as-div-container{
        padding: 0 3rem;
    }

    .as-title-grid-height{
        display: flex;
        justify-content: space-around;
        text-align: center;
    }
}
