.catchphrase-text-title {
    font-size: 4vw;
    color: black;
    font-weight: 400;
}

.banner-home-student {
    background-image: url("../../../images/accueil/bannerHomeStudents.png");
}

.catchphrase-text-subtitle {
    font-size: 1.5rem;
    color: black;
    font-weight: 300;
}

.catchphrase-bloc-student {
    display: flex;
    place-content: center;
    position: relative;
    width: 57%;
    height: 100%;
}

.catchphrase-text-title h1 {
    margin: 0;
    font-weight: 600;
    font-size: 2.5rem;
    display: inline;
}

.catchphrase-bloc-student-home {
    display: flex;
    flex-direction: column;
    place-content: center;
    position: relative;
    width: 57%;
    height: 100%;
}

.container-buttons-home-student {
    display: flex;
}

.container-buttons-home-student .p-button {
    border: 1px solid;
    padding: 0.8rem 1.8rem;
    font-size: 1.2rem;
    letter-spacing: 4px;
    margin-right: 2rem;
}

.container-buttons-home-student .p-button:first-child {
    border: none;
}

@media screen and (max-width:992px) {
    .banner-home-student {
        background-image: url("../../../images/accueil/bannerHomeStudentMobile.png");
    }

    .container-buttons-home-student {
        margin:0;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .container-buttons-home-student div,
    .container-buttons-home-student a {
        width: 100%;
    }

    .container-buttons-home-student .p-button {
        font-size: 0.7rem;
        padding: 0.9rem 1rem;
        margin-top: 1rem;
        margin-right: 0 !important;
        width: 100%;
        min-width: 19rem;
    }

    .catchphrase-bloc-student-home {
        width: 90%;
        align-items: center;
    }

    .catchphrase-text-title {
        font-size: 2.5rem;
    }

    .catchphrase-text-subtitle {
        font-size: 1.5rem;
    }

    .catchphrase-text-title h1 {
        margin-top: 1rem;
    }
}