.loader-height-for-thumbnail-list{
    height: 34rem;
}

.search-candidat-infomessage{
    position : absolute;
    pointer-events: none;
    margin-top: 12rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.search-candidat-fake-display{
    filter : blur(0.5rem);
    pointer-events: none;
}

a.link-search-candidat-fake-display{
    color: #000;
}

a.link-search-candidat-fake-display:hover{
    color: #000;
}