.ec-margin{
    margin-right: 5rem;
}

.ec-help{
    width: 18rem;
    font-size: large;
}

.ec-container{
    background: #FFFFFF;
    box-shadow: 1px 4px 17px rgba(0, 35, 124, 0.15);
    border-radius: 10px;
    padding: 0.5rem 2rem;
    color:var(--resolution-blue);
    width: 18rem;
}

.ec-container h1{
    color:var(--terra-cotta);
    font-size: 20px;
}

.ec-container h2{
    font-size: 16px;
}

@media screen and (max-width:992px){
    .ec-container{
        width: auto;
    }

    .ec-margin{
        margin-right: 0rem;
    }
}