.oept-card .p-card-body{
    padding: 0;
}

.oept-card .p-card-content{
    padding: 0;
}

.oept-card.p-card.p-component{
    border-radius: 15px;
}

.oept-main-container{
    display: flex;
    flex-direction: row;
    
    border-radius: 15px;
}

.oept-white-bg{
    background-color: white;
}

.oept-grey-bg{
    
    background-color: var(--grey-cards);
}

.oept-main-container>div{
    padding: 1rem 2rem;
    border-top-left-radius: 15px;
}

.oept-profile{
    display: flex;
    flex-direction: row;
    background-color: white;
    flex-grow: 1;
    border-radius: 2% 4% 4% 2% / 8% 50% 50% 8%;
}

.oept-profile-image{
    background-color: white;
    margin-right: 1rem;
}

.oept-profile-image img{
    object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--eerie-black);
  height: 7rem;
  width: 7rem;
  
}

.oept-profile-image .p-avatar-circle{
    height: 7rem;
  width: 7rem;
}

.oept-profile-image .p-avatar .p-avatar-icon{
    font-size: 3.2rem;
}

.oept-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.oept-title-container>h1{
    color :var(--linkedin-blue);
    font-size: 1.2rem;
    margin: 0;
}


.oept-subtitle {
    font-style: italic;
    color: var(--linkedin-blue);
    font-weight: 500;
}

.oept-contract-container .p-tag {
    margin: 0.5rem 0;
    color: white;
    width: fit-content;
}

.oept-italic {
    font-style: italic;
}

.oept-like-container {
    text-align: end;
}

.oept-logo{
    margin-right:1.5rem;
}

.oept-logo img {
    max-height: 7rem;
    width: 7rem;
}

.oept-like-in-title{
    text-align: right;
    display:none;
}


.oept-right-container{
    margin-left: auto;
}

.oept-postuler-container{
    margin-top: auto;
    display:flex;
}

@media screen and (max-width:992px) {

    .oept-main-container>div:first-child{
        padding: 1rem;
        width: 100%;
        border-top-right-radius: 15px;
        min-height: 0rem;
    }

    .oept-main-container>div{
        padding: 1rem;
    }
    .oept-main-container{
        flex-direction: column;
        align-items: flex-start;
    }

    .oept-info-container{
        width: 100%;
    }
    .oept-like-container{
        display: none;
    }

    .oept-right-container{
        margin-left: 0;
        width: 100%;
    }

    .oept-profile{
        flex-direction: column;
        border-radius: 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .oept-logo{
        margin-right:0rem;
        text-align: center;
    }

    .oept-like-in-title{
        display:block;
    }

    .oept-postuler-container{
        margin-top: 0.5rem;
    }
}