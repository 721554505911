.listelibelle-thumbnail-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.listelibelle-thumbnail-main-container>div{
    /*flex: 1 50%;*/
    padding: 0.5rem;
    
}

.listelibelle-thumbnail-card.p-card.p-component{
    border-radius: 15px;
}

.listelibelle-thumbnail-seemore{
   text-align: center;
}

.listelibelle-thumbnail-lineheight{
    line-height: 25px;
}

.listelibelle-thumbnail-subtitle{
    margin-top:0
}

.listelibelle-thumbnail-button-padding{
    padding: 0.5rem 2rem;
}

@media screen and (max-width:992px){
    .listelibelle-thumbnail-main-container{
        flex-direction: column;
    }
    .listelibelle-thumbnail-main-container>div{
        flex: 1 100%;
    }
}