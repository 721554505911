.bhnc-button.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined{
    color:black;
    border-color: var(--terra-cotta);
    background-color: white;
    width: auto;
}

.catchphrase-first h1{
margin: 0;
font-weight: 500;
font-size: inherit;
display: inline;
}

.catchphrase-text h1{
    margin: 0;
    font-weight: 500;
    font-size: inherit;
    display: inline;
    }

