.formation-list-item-fs-main-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}

.formation-list-item-fs-formation-container{
    display: flex;
    flex-direction: row;
}

.formation-list-item-fs-formation-container>div{
    padding: 0.5rem;
}

.formation-list-item-fs-maformationin-container>div:first-child{
    display: flex;
    width: 8rem;
    height: auto;
    align-items: center;
    justify-content: center;
}

.formation-list-item-fs-formation-container>div:first-child img{
    display: flex;
    width: 7rem;
    height: auto;
    max-height: 4.6rem;
}

.formation-list-item-fs-composante{
    font-weight: 500;
}


.formation-list-item-fs-obtention{
    font-style: italic;
}

.formation-list-item-fs-buttons{
    justify-content: flex-end;
    flex:none;
}

@media screen and (max-width:992px) {
    .formation-list-item-fs-main-container{
        flex-direction: column;
    }
    .formation-list-item-fs-formation-container{
        flex-direction: column;
    }
    .formation-list-item-fs-formation-container>div:first-child{
        align-self: center;
        width: auto;
        height: 5.6rem;
    }
    .formation-list-item-fs-formation-container>div:first-child img{
        height: 4.6rem;
        width: auto;
    }
    .formation-list-item-fs-buttons{
        text-align: right;
    }
}