.chatbar-main .p-dataview-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.chatbar-main .p-paginator-bottom {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}


.chatbar-main .p-dataview-content {
    min-height: 600px;
}

.chatbar-main .p-dataview-emptymessage {
    text-align: center;
}

.message-bar-m {
    min-height: 4rem;
}

.message-bar-warn {
    margin-top: 10px;
    text-align: center;
    color: red;
}

.thread-list-item {
    cursor: pointer;
    margin: 0;
}

.thread-list-item:hover {
    background-color: var(--opal-light);
}

.thread-list-item:active {
    background-color: var(--opal-50);
}

.thread-list-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.thread-list-picture {
    padding: 15px;
}

.thread-list-info {
    padding-top: 20px;
    min-height: 5rem;
   
}

.thread-info-progressbar{
    width: 8rem;
    height: 6px;
}

.thread-list-info .thread-interlocutor-name {

    color: var(--indigo-dye);
    font-weight: bold;
}

.thread-list-info .thread-interlocutor-name-comp {

    text-transform: uppercase;
    font-weight: normal;

}

.thread-list-arrow {
    display: flex;
    margin: auto 0;
}