.header-background-picture-container {
    display: flex;
    flex-direction: column;
}

.header-background-picture {
    width: 100%;
    max-height: 20rem;
    object-fit: cover;
}

.header-background-picture-border {
    background-color: var(--whisper);
    margin-top: -3.5rem;
    content: '';
    border-radius: 50% 50% 0% 0% / 60% 60% 0% 0%;
    padding: 2.5rem;
}

@media screen and (max-width:992px) {
    .header-background-picture-border {
        margin-top: -2rem;
        border-radius: 50% 50% 0% 0% / 20% 20% 0% 0%;
        padding: 2rem;
    }
}