
.datatable-demandeFormation_etudiants .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dt-df-e-custom-tooltip .p-tooltip-text {
    max-width: 50rem;
    white-space: pre-wrap;
    word-wrap: break-word;
}