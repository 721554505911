.container-home-student-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.center-home {
    text-align: center;
    margin: auto;
    width: 100%;
}

.banner-container {
    margin-left: 2.5rem;
    background-position: 180% 33%;
    background-size: 90% auto;
    background-repeat: no-repeat;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.banner-etudiant {
    background-image: url("../../images/accueil/etudiant/accueilEtudiant.webp");
}

.banner-entreprise {
    background-image: url("../../images/accueil/entreprise/accueilEntreprise.webp");
}

.banner-ecole {
    background-image: url("../../images/accueil/ecole/accueilEcole.webp");
}

.catchphrase-first {
    font-size: 2.2vw;
    color: var(--indigo-dye);
    font-weight: 500;
}

.catchphrase-text {
    font-size: 3vw;
    color: var(--indigo-dye);
    font-weight: 500;
}

.catchphrase-bloc {
    display: flex;
    place-content: center;
    position: relative;
    width: 50%;
    height: 100%;
}

.catchprase-text {
    place-content: center;
    width: 80%;
    position: absolute;
    top: 8px;
    left: 16px;
}

.catchphrase-second {
    font-size: 2rem;
    color: var(--indigo-dye);
}

.catchphrase-component {
    font-size: 1.7rem;
    color: var(--indigo-dye);
}

.catchphrase-blank {
    margin-top: 6rem;
}

.catchphrase-modules {
    margin: 0rem 6rem 2rem 6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
}

.catchphrase-modules>div {
    flex-basis: 33.333333%
}

.catchphrase-logo {
    margin: 0 0 0 0;
}

.catchphrase-logo>img {
    max-height: 8rem;
    width: auto;
}

.catchphrase-phrase {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--indigo-dye);
}

.catchphrase-button {
    padding: 0.2rem;
    text-transform: uppercase;
    overflow: hidden;
}

.banner-home {
    height: 16rem;
    width: 100%;
}

.banner-image {
    background-image: url('../../images/banner_home.webp');
    padding-bottom: 50%;
    /*height: 100vh;*/
    background-size: 100%;
    margin-bottom: -10%;
}

.banner-trapezium {
    height: 16rem;
    width: 100%;
}

.div-inline {
    overflow: auto
}

.h-style {
    margin-left: 10%;
}

.header-global {
    display: inline-flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: 1rem;
}

.header-margin-compensator {
    margin-top: -2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}

.header-content-negative-top-margin {
    margin-top: -5.5rem;
}

.btn-inspire-me{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
}

@media screen and (max-width:992px) {
    .banner-container {
        background-size: 130%;
        background-position: 100% 45%;
    }

    .header-global {
        margin-top: 0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    .header-margin-compensator {
        margin-top: -0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    .header-background-picture-border {
        background-color: white;
        margin-top: -2rem;
        content: '';
        border-radius: 50% 50% 0% 0% / 20% 20% 0% 0%;
        padding: 2rem;
    }

    .header-content-negative-top-margin {
        margin-top: -6rem;
        padding: 0 0.5rem;
    }

    .header-search-section-style {
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 3rem 0;
    }

    .header-formation-section-style {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .catchphrase-modules {
        margin: 0rem 1rem 2rem 1rem;
        flex-direction: column;
    }

    .catchphrase-modules>div {
        flex-basis: 100%;
    }

    .catchphrase-modules>div:nth-child(1) {
        order: 1;
    }

    .catchphrase-modules>div:nth-child(2) {
        order: 5;
    }

    .catchphrase-modules>div:nth-child(3) {
        order: 9;
    }

    .catchphrase-modules>div:nth-child(4) {
        order: 2;
    }

    .catchphrase-modules>div:nth-child(5) {
        order: 6;
    }

    .catchphrase-modules>div:nth-child(6) {
        order: 10;
    }

    .catchphrase-modules>div:nth-child(7) {
        order: 3;
    }

    .catchphrase-modules>div:nth-child(8) {
        order: 7;
    }

    .catchphrase-modules>div:nth-child(9) {
        order: 11;
    }

    .catchphrase-modules>div:nth-child(10) {
        order: 4;
    }

    .catchphrase-modules>div:nth-child(11) {
        order: 8;
    }

    .catchphrase-modules>div:nth-child(12) {
        order: 12;
    }

    .catchphrase-component {
        margin-top: -1rem;
    }

    .catchphrase-phrase {
        padding: 0.5rem;
    }

    .catchphrase-first {
        font-size: 1.5rem;
    }

    .catchphrase-text {
        font-size: 2rem;
    }

    .catchphrase-second {
        font-size: 1rem;
    }

    .banner-container {
        background-position: 0;
        background-size: 100%;
        background-repeat: no-repeat;
        height: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .btn-inspire-me{
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: white;
        justify-content: center;
        box-shadow: 0px -9px 15px -3px rgba(0,0,0,0.1);
        align-items: center;
        height: 4rem;
    }

    .btn-inspire-me button{
        justify-content: center;
        font-size: 1.1rem;
    }
}

@media screen and (max-width:560px) {
    .banner-container {
        background-size: 170%;
        background-position: 100% 45%;
    }
}