.float-accueil-button-confidentialite {
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0rem;
    padding: 0.7rem;
}

.float-accueil-button-confidentialite .p-button{
    background-color: var(--terra-cotta);
    color: white;
}

.confidentialite-container{
    margin: 0 3rem;
}
