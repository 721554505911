.landing-page {
  flex: 1;
  font-family: "Inter", sans-serif;
}

.lpe-title {
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-size: 3rem;
}

.lpe-section {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section-wrapper {
  width: 100%;
  padding: 5rem 1rem;
  position: relative;
  /* overflow: hidden; */
}

.background-image {
  position: absolute;
  inset: 0;
  background-image: url("https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/iStock-1085606706_2-drPNxmocLJZCvnmLg8dkPzPQvA7uEF.jpg");
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.background-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9), /* Couche blanche semi-transparente */
      rgba(255, 255, 255, 0)
    ),
    rgba(0, 0, 0, 0.3); /* Couche sombre atténuée */
  z-index: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  margin: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

@media (max-width: 992px) {
  .section-wrapper {
    padding-top: 3rem;
  }
  .content-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lpe-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 640px) {
  .section-wrapper {
    padding-top: 2rem;
  }
  .content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lpe-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lpe-title {
    font-size: 1.5rem;
  }
}

/* Titre principal */
.main-title {
  font-size: 4.5rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-top: 0%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    to right,
    #00237c 25%,  /* Bleu foncé légèrement plus étendu */
    #6c63d0 50%,  /* Violet adouci, au centre */
    #5a67d8 75%   /* Violet clair commence plus tôt */
  );
  text-align: left;
  padding-left: 0;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem;
  }
}

/* Description */
.description {
  color: #f0f0f0;
  font-size: 1.125rem;
}

/* Texte en gras (strong) avec styles spécifiques */
.highlight-text {
  max-width: 650px; /* max-w-[650px] */
  color: #4b5563; /* text-gray-600 */
  font-size: 1.125rem; /* text-lg */
  margin-top: 1.5rem; /* mt-6 */
}

@media (min-width: 768px) { /* md:text-xl */
  .highlight-text { 
    font-size: 1.25rem; /* text-xl */
  }
}

body.dark .highlight-text {
  color: #9ca3af; /* dark:text-gray-400 */
}

/* Boutons */
.button-group {
  margin: 1rem 0;
}

.cta-button {
  background-color: #ed6a5a;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
}

.cta-button:hover {
  background-color: #d15a4b;
}

/* Texte d'aide */
.helper-text {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
}

/* Grille d'images */
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 colonnes par défaut */
  gap: 1rem;
  margin-top: 2rem;
}

@media (min-width: 640px) { /* sm:grid-cols-4 */
  .image-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 colonnes pour écrans larges */
  }
}

/* Conteneur des images */
.image-wrapper {
  position: relative;
  height: 200px; /* h-[200px] */
  border-radius: 8px; /* rounded-lg */
  /* overflow: hidden; */
}

/* Images par défaut et en hover */
.default-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.hover-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Effet de survol */
.image-wrapper:hover .default-image {
  opacity: 0;
}

.image-wrapper:hover .hover-image {
  opacity: 1;
}

/* Card Section */
.info-card {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #00237c;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 15px rgba(0, 35, 124, 0.3); 
  /* opacity: 0.8; */
}

.info-title {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: black;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-icon {
  margin-right: 0.5rem;
  color: #00237c;
  font-size: 1.25rem;
}

.outline-button {
  margin-top: 1rem;
  background-color: transparent;
  color: #00237c;
  border: 1px solid #00237c;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1rem;
}

.outline-button:hover {
  background-color: #00237c;
  color: #fff;
}

.lpe-custom-dialog-header {
  text-align: center;
  color: var(--resolution-blue);
  font-size: 20px;
  font-weight: bold;
}







/* Section 02 */

#comment-ca-marche {
  border: 2px dashed red;
  background-color: rgba(255, 0, 0, 0.1); /* Facilement visible */
}

/* Section principale */
.how-it-works-section {
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
}

/* Conteneur */
.container {
  max-width: 1280px;
  margin: auto;
  padding: 0 1rem; /* px-4 */
}

.section-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

.section-description {
  text-align: center;
  font-size: 1.125rem; /* text-lg */
  color: #4b5563; /* text-gray-600 */
  margin-bottom: 2rem; /* mb-8 */
}

body.dark .section-description {
  color: #9ca3af; /* dark:text-gray-400 */
}

.steps-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 640px) {
  .steps-grid {
    grid-template-columns: repeat(2, 1fr); /* sm:grid-cols-2 */
    gap: 2.5rem; /* sm:gap-10 */
  }
}

@media (min-width: 1024px) {
  .steps-grid {
    grid-template-columns: repeat(3, 1fr); /* lg:grid-cols-3 */
  }
}

/* Step Card */
.step-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 21rem;
  margin: auto;
  min-height: 300px;
  justify-content: space-between;
}

.step-circle {
  background-color: #00237c;
  color: #fff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

body.dark .step-circle {
  background-color: #00237c;
}

.step-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  min-height: 2.5rem;
  line-height: 1.2;
}

.step-description {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  text-align: center;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

body.dark .step-description {
  color: #9ca3af; /* dark:text-gray-400 */
}

/* CTA Button */
.cta-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem; /* mt-12 */
}

.cta-button {
  background-color: #ed6a5a;
  color: white;
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem; /* py-3 px-6 */
}

.cta-button:hover {
  background-color: #d15a4b;
}








/* troisème section */

/* Section principale */
.pricing-section {
  width: 100%;
  padding: 4rem 1rem;
  background: linear-gradient(to bottom right, #e3f2fd, #f3e5f5);
  font-family: "Inter", sans-serif;
}

/* Conteneur global */
.pricing-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
}

/* Texte et bouton */
.pricing-content {
  flex: 1;
}

.pricing-title {
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

.pricing-description {
  color: #6b7280;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.pricing-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* justifier le texte à gauche */
  text-align: left;
  margin-top: 1rem;
}

.pricing-feature {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.pricing-feature p {
  margin-top: 0;
}

.pricing-icon {
  width: 32px;
  height: 32px;
}

.pricing-note {
  color: #6b7280;
  font-size: 1rem;
  margin-top: 1rem;
}

.pricing-button-wrapper {
  margin-top: 2rem;
}

.pricing-button {
  background-color: #ed6a5a;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  border: none;
  border-radius: 4px;
}

.hubspot-button {
  margin-top: 1.5rem;
  display: flex;
  justify-self: center;
}

.pricing-button:hover {
  background-color: #d15a4b;
}

/* Image */
.pricing-image-wrapper {
  flex: 1;
  text-align: center;
}

.pricing-image {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column; /* Colonne pour mobile/tablette */
    align-items: center;
    text-align: center;
  }

  .pricing-content {
    order: 1; /* Le texte reste en premier */
  }

  .pricing-image-wrapper {
    order: 2; /* L'image passe en dessous */
  }
}





/* Quatrième section */

/* Section principale */
.testimonials-section {
  width: 100%;
  padding-bottom: 1rem;
  background-color: #ffffff;
  color: #333333;
  font-family: "Inter", sans-serif;
}

/* Conteneur principal */
.testimonials-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

/* Titre */
.testimonials-title {
  font-weight: bold;
  margin-bottom: 2rem;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #00237c, #4a90e2);
}

/* Grille */
.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 640px) {
  .testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .testimonials-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Cartes */
.testimonial-card {
  background-color: #e6e9f0;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Image */
.testimonial-image-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Texte */
.testimonial-name {
  font-size: 1.125rem;
  font-weight: bold;
}

.testimonial-role {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1rem;
}

.testimonial-quote {
  font-size: 1rem;
  font-style: italic;
  color: #333333;
}

/* Bouton */
.testimonials-button-wrapper {
  margin-top: 2rem;
}

.testimonials-button {
  background-color: #ed6a5a;
  color: white;
  font-size: 1.125rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
}

.testimonials-button:hover {
  background-color: #d15a4b;
}