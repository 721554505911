.dip-tag{
    font-family: var(--font-family-poppins);
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 2rem;
    display: inline-block;
    text-align: center;
}

.dip-tag-p-sm,
.dip-tag.dip-tag-p-sm{
    padding: 0.2rem 0.5rem;
}

.dip-tag-p-md,
.dip-tag.dip-tag-p-md{
    padding: 0.5rem 1rem;
}

.dip-tag-p-lg,
.dip-tag.dip-tag-p-lg{
    padding: 0.5rem 2rem;
}

.dip-tag-letter-spacing
.dip-tag,.dip-tag-letter-spacing{
    letter-spacing: 0.1rem;
}

.dip-tag-fs-sm{
    font-size: 0.65rem;
}

.dip-tag-orange {
    color: var(--iris-100);
    border: 1px solid var(--iris-60);
    background-color: var(--iris-60);
}

.dip-tag-yellow {
    color: var(--eerie-black);
    border: 1px solid var(--rum-swizzle);
    background-color: var(--rum-swizzle);
}

.dip-tag-blue {
    color: var(--royal-blue);
    border: 1px solid var(--perano);
    background-color: var(--perano);
}

.dip-tag-pearl {
    color: var(--jaffa);
    border: 1px solid var(--double-pearl-lusta);
    background-color: var(--double-pearl-lusta);
}

.dip-tag-pink {
    color: var(--eerie-black);
    border: 1px solid var(--carousel-pink);
    background-color: var(--carousel-pink);
}