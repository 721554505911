

.nhab-mobile-container {
    display: none;
}

.dip-icon-size>.pi {
    font-size: 1.5em;
}

@media screen and (max-width:992px) {

    .nhab-mobile-container {
        display: block;
    }
}