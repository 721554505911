.header_logo {
    padding-left: 1rem;
    height: 2.8rem;
    width: auto;
}

.header_logo a {
    padding: 0.1rem;
}

.auth-style {
    padding-right: 1rem;
    display: flex;
}

.header-main-style-iris {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: var(--iris-100);
    z-index: 100;
}

.header-main-style-red {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: var(--terra-cotta);
    z-index: 100;
}

.header-main-style-blue {
    width: 100%;
    height: 4rem;
    background-color: var(--resolution-blue);
    z-index: 100;
}

.header-main-style-white {
    position: fixed;
    width: 100%;
    height: 4rem;
    background-color: white;
    z-index: 100;
    box-shadow: 0px 4px 15px rgba(0, 35, 124, 0.15);
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    border: none
}

.header-main-container {
    display: flex;
    flex-wrap: wrap;
    height: 4rem;
    align-items: center;
    padding: 0.5rem 0rem;
    justify-content: space-between;
}

.h-navbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    width: 100%;
}

.active-route {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: var(--eerie-black);
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.navbar__links {
    display: flex;
}

.navbar__link {
    padding: 0 0.3rem;
    font-size: 16px;
    color: inherit;
    padding: 1em;
}


.burger-menu {
    display: none;
}

.h-container-logo-link-compte {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.h-container-link-compte {
    display: flex;
    align-items: center;
}

.h-link-compte-connect-white {
    width: inherit;
}

.app-width-limit .auth-style{
    padding-right: 0;
}

@media screen and (max-width: 992px) {
    .navbar__links {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        padding: 2rem;
        background-color: var(--terra-cotta);
        visibility: hidden;
    }

    .header_logo {
        padding-left: 0rem;
        height: 1.2rem;
        width: auto;
    }

    .auth-style {
        padding-right: 0.5rem;
    }

    .auth-style .dip-btn-white-bg {
        padding: 0.5rem 0.5rem;
        font-size: 0.7rem;
    }

    .show-navbar .navbar__links {
        visibility: visible;
    }

    .navbar__link {
        display: block;
        padding: 1rem;
        font-size: 5vw;
    }

    .navbar__item::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background-color: var(--eerie-black);
    }

    .active-route {
        border: none;
    }

    .navbar__item:last-child::after {
        display: none;
    }

    .navbar__logo {
        display: block;
        width: 100%;
        padding-top: 0.5rem;
    }

    .show-navbar .navbar__logo {
        display: none;
    }

    .navbar__auth {
        display: none;
    }

    .show-navbar .navbar__auth {
        display: block;
    }

    .h-container-logo-link-compte {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        flex-wrap: nowrap;
        padding-left: 1em;
    }

    .h-link-compte-connect-white {
        width: initial;
    }
}