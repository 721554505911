.candidat-search-button-seemore.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined
{
    color:black;
    border-color: var(--primary-color);
}

.candidat-search-main-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.candidat-search-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidat-search-results-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}



.candidat-search-filters-container{
    padding:0.5rem;
    flex-basis: 25%;
}
.candidat-search-filters-center{
    align-self: center;
}

.candidat-search-right-container{
    flex-basis: 75%;
}

.candidat-search-item{
    flex-basis:25%;
    width: 25%;
}



.candidat-search-float-button{
    display: none;
}

@media screen and (max-width:992px){
    .candidat-search-filters-container{
        display: none;
    }

    .candidat-search-item{
        flex-basis:100%;
        width: 100%;
    }
    .candidat-search-float-button{
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0rem;
        padding: 0.7rem;
    }

    .candidat-search-float-button .p-button{
        border: 2px solid var(--indigo-dye);
        border-radius: 2rem;
    }

}


